import { setTokens, setLevels, findTokenByType } from "./tokens.js";
import gsap from "gsap";
import StateManager from "./appStates.js";
import { web3modal } from "./connectWallet.js";
import { readContract, writeContract, fetchBalance, prepareWriteContract, signTypedData, waitForTransaction, watchReadContract } from "@wagmi/core";
import { formatUnits } from "viem";
import { getAccount } from "@wagmi/core";
import IERC20 from "./contracts/IERC20.json";
import IERC20Permit from "./contracts/IERC20Permit.json";
import GPMAux from "./contracts/GPMAux.json";
import GPMines from "./contracts/GPMines.json";
import { secp256k1 } from "@noble/curves/secp256k1";
import { GPM, GPMA } from "./contracts/ContractAddresses.js";

import { v4 as uuidv4 } from "uuid";
import WertWidget from "@wert-io/widget-initializer";

function debounce(callback, wait, context = this) {
  let timeout = null;
  let callbackArgs = null;

  const later = () => callback.apply(context, callbackArgs);

  return function () {
    callbackArgs = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
/* function getReferralId() {
  if (window.location.hash === "") {
    return 0n;
  } else {
    var referralId = BigInt(window.location.hash.substring(1));
    return referralId;
  }
} */

var count = 1;
var isLoading = false;
var isConfirmingReferrer = false;
var isConfirmingIndependent = false;
var independentButton = document.querySelector(".independentButton");
// var referrerButton = document.querySelector(".referrerButton");
// var referrerCountElement = document.getElementById("referrerCount");
// var referrerOriginalText = referrerCountElement.innerHTML;
var independentCountElement = document.getElementById("count");
var independentOriginalText = independentCountElement.innerHTML;
var incrementButton = document.querySelector(".incrementButton");
var decrementButton = document.querySelector(".decrementButton");
var independentloader = document.querySelector(".independentButton .loader");
// var referrerloader = document.querySelector(".referrerButton .loader");
var tlIndependentDots = gsap.timeline({ repeat: -1, paused: true });
tlIndependentDots.to(".independentButton .loader .dot", { opacity: 1, yoyo: true, stagger: 0.5, repeat: 1 }, 0.5);

// var tlReferrerDots = gsap.timeline({ repeat: -1, paused: true });
// tlReferrerDots.to(".referrerButton .loader .dot", { opacity: 1, yoyo: true, stagger: 0.5, repeat: 1 }, 0.5);
document.querySelector(".mintOptionsoverlayFull").addEventListener("click", function (event) {
  mintOptionsContTl.reverse();
  document.querySelector("#gpm_output").innerHTML = "";
  setMintBtnClickable(false);
});
/* referrerButton.addEventListener("click", async function (event) {
  event.stopPropagation();
  if (isConfirmingIndependent) {
    isConfirmingIndependent = false;
    independentCountElement.innerHTML = independentOriginalText;
  }
  if (isConfirmingReferrer) {
    isConfirmingReferrer = false;
    referrerCountElement.innerHTML = referrerOriginalText;
    var currentState = await StateManager.getAppState();

    if (currentState === "walletNotConnected") {
      await web3modal.openModal();
    } else if (!isLoading) {
      isLoading = true;
      referrerCountElement.style.display = "none";
      referrerloader.style.display = "block";
      tlReferrerDots.play();

      try {
        id = "ref";
        await mintOptionsContainer(1, "ref");
        referrerCountElement.innerHTML = `Mint for 10 USD`;
      } catch (error) {
        console.log(error);
      }

      isLoading = false;
      tlReferrerDots.pause();
      referrerloader.style.display = "none";
      referrerCountElement.style.display = "block";
    }
  } else {
    isConfirmingReferrer = true;
    referrerCountElement.innerHTML = "Confirm?";
  }
}); */

independentButton.addEventListener("click", async function (event) {
  event.stopPropagation();
  if (isConfirmingReferrer) {
    isConfirmingReferrer = false;
    // referrerCountElement.innerHTML = referrerOriginalText;
  }
  if (isConfirmingIndependent) {
    isConfirmingIndependent = false;
    independentCountElement.innerHTML = independentOriginalText;

    var currentState = await StateManager.getAppState();

    if (currentState === "walletNotConnected") {
      await web3modal.openModal();
    } else if (!isLoading) {
      isLoading = true;
      independentCountElement.style.display = "none";
      independentloader.style.display = "block";
      tlIndependentDots.play();

      try {
        // id = "ind";
        id = "ref";
        await mintOptionsContainer(count, "ref");
        count = 1;
        // independentCountElement.innerHTML = `Mint for ${60 * count} USD`;
        independentCountElement.innerHTML = `Mint for ${10 * count} USD`;
        independentOriginalText = independentCountElement.innerHTML;
      } catch (error) {
        console.log(error);
      }

      isLoading = false;
      tlIndependentDots.pause();
      independentloader.style.display = "none";
      independentCountElement.style.display = "block";
    }
  } else {
    isConfirmingIndependent = true;
    independentCountElement.innerHTML = "Confirm?";
  }
});

// Listen for click events on the document
document.addEventListener("click", function () {
  if (isConfirmingReferrer || isConfirmingIndependent) {
    isConfirmingReferrer = false;
    isConfirmingIndependent = false;
    // referrerCountElement.innerHTML = referrerOriginalText;
    independentCountElement.innerHTML = independentOriginalText;
  }
});

incrementButton.addEventListener("click", function () {
  if (!isLoading) {
    count++;
    independentCountElement.innerHTML = `Mint ${count} for ${10 * count} USD`;
    independentOriginalText = independentCountElement.innerHTML;
  }
});

decrementButton.addEventListener("click", function () {
  if (!isLoading && count > 1) {
    count--;
    independentCountElement.innerHTML = `Mint ${count} for ${10 * count} USD`;
    independentOriginalText = independentCountElement.innerHTML;
  }
});

let currency = "MATIC";
let id;
let mintBtnClickable = false;
let mintOptionsHeadline = document.querySelector(".mintOptionsHeadline");
let mintOptionsUSDT = document.querySelector(".mintOptionsUSDT");
let mintOptionsUSDC = document.querySelector(".mintOptionsUSDC");
let mintOptionsUSDCn = document.querySelector(".mintOptionsUSDCn");
let mintOptionsMATIC = document.querySelector(".mintOptionsMATIC");
let mintOptionsCARD = document.querySelector(".mintOptionsCARD");
let mintBtnFinalBtn = document.querySelector(".mintBtnFinal");
let mintBtnFinal = document.querySelector(".mintBtnFinalText");
let mintBtnFinalSpinner = document.querySelector(".mintBtnFinalSpinner");
let mintBtnFinalSpinnerTl = gsap.timeline({ paused: true });
mintBtnFinalSpinnerTl
  .to(mintBtnFinal, { duration: 0.25, ease: "power2.out", opacity: 0 })
  .to(mintBtnFinalSpinner, { duration: 0.25, ease: "power2.out", opacity: 1 }, "<");

const errorDiv = document.querySelector(".errorDiv .mine-small-regular");
const successDiv = document.querySelector(".successDiv .mine-small-regular");

let mintOptionsContTl = gsap.timeline({ paused: true });
mintOptionsContTl
  .to(".minOptions-container", { duration: 0.25, ease: "power2.out", y: 0 })
  .to(".mintOptionsoverlayFull", { duration: 0.25, ease: "power2.out", autoAlpha: 1 }, "<");
function setMintBtnClickable(state) {
  mintBtnClickable = state;

  if (mintBtnClickable) {
    mintBtnFinalBtn.classList.add("mintBtnFinalActive");
  } else {
    mintBtnFinalBtn.classList.remove("mintBtnFinalActive");
  }
}
let countForMintOptions;
const mintOptionsContainer = async (count, id) => {
  mintBtnFinalSpinnerTl.play();
  mintOptionsContTl.play();
  setMintBtnClickable(false);

  if (id === "ref") {
    mintOptionsHeadline.textContent = `Mint ${count} Gold Miner NFT`;
    // gpmComputeQuoteCheck(1);
    // countForMintOptions = 1;
    gpmComputeQuoteCheck(count);
    countForMintOptions = count;
  } else if (id === "ind") {
    mintOptionsHeadline.textContent = `Mint ${count} Instant Access Gold Miner NFTs`;
    gpmComputeQuoteCheck(count);
    countForMintOptions = count;
  }
  console.log("mintCount :", countForMintOptions);
};

const mintOptionsBtns = [mintOptionsUSDT, mintOptionsUSDC, mintOptionsMATIC, mintOptionsCARD, mintOptionsUSDCn];
mintOptionsBtns.forEach((div) => {
  div.addEventListener("click", function () {
    document.querySelector("#gpm_output").innerHTML = "";
    setMintBtnClickable(false);
    mintBtnFinalSpinnerTl.play();
    mintOptionsBtns.forEach((d) => d.classList.remove("mintOptionsBtnActive"));

    this.classList.add("mintOptionsBtnActive");

    if (this === mintOptionsUSDT) {
      currency = "USDT";
    } else if (this === mintOptionsUSDC) {
      currency = "USDC";
    } else if (this === mintOptionsMATIC) {
      currency = "MATIC";
    } else if (this === mintOptionsCARD) {
      currency = "USD";
    } else if (this === mintOptionsUSDCn) {
      currency = "USDCn";
    }

    console.log("currency:", currency);
    gpmComputeQuoteCheck(countForMintOptions);
  });
});

const swapParams = {
  USDCn: {
    currencyAddress: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    poolFee: "100",
    method: "PERMIT",
    decimals: 6,
    domain: {
      name: "USD Coin",
      version: "2",
      chainId: 137,
      verifyingContract: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    },
    domainDef: [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ]
  },
  "USDC": {
    currencyAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    poolFee: "100",
    method: "PERMIT",
    decimals: 6,
    domain: {
        name: "USD Coin (PoS)",
        version: "1",
        salt: "0x0000000000000000000000000000000000000000000000000000000000000089",
        verifyingContract: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    },
    domainDef: [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "verifyingContract", type: "address" },
      { name: "salt", type: "bytes32" },
    ]
  },
  USDT: {
    currencyAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    poolFee: "100",
    method: "APPROVE",
    decimals: 6,
  },
  MATIC: {
    currencyAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    poolFee: "500",
    method: "NATIVE",
    decimals: 18,
    realAddress: "0x0000000000000000000000000000000000000000",
  },
  USD: {
    currencyAddress: "0",
    poolFee: "0",
    method: "CARD",
    decimals: 0,
    realAddress: "0",
  },
};

const gpmQuote = async (type, count) => {
  let { currencyAddress, poolFee } = swapParams[currency];

  const res = await readContract({
    ...GPMAux,
    address: GPMA,
    functionName: "getQuote",
    args: [currencyAddress, poolFee],
    chainId: 137,
  });
  console.log(res);
  const multipliedRes = res * BigInt(count);
  console.log("multipliedRes :", multipliedRes);

  return multipliedRes;
};

const gpmComputeQuoteCheck = async (count) => {
  setMintBtnClickable(false);
  document.querySelector("#gpm_output").innerHTML = "";
  mintBtnFinalSpinnerTl.play();
  const type = id === "ind" ? 1n : 0n;

  let account = getAccount().address;
  /* if (id === "ref") {
    let hasReferralNft = await readContract({
      ...GPMines,
      address: GPM,
      functionName: "hasReferralNft",
      args: [account],
      chainId: 137,
    });
    console.log("hasReferralNft :", hasReferralNft);

    if (hasReferralNft) {
      document.querySelector("#gpm_output").innerHTML = `Wallet already contains an unqualified Gold Miner NFT. <br/>
      Send your referral link to 3 friends to qualify your Gold Miner before you can purchase another $10 Gold Miner NFT or buy as many Instant Access Gold Miners for $60.`;
      mintBtnFinalSpinnerTl.reverse();
      setMintBtnClickable(false);
      return;
    }
  } */
  let quote;
  let amountInMax;
  let formatedQuote;
  let balance;
  let { method, currencyAddress, decimals, realAddress, poolFee, domain, domainDef } = swapParams[currency];

  if (method === "CARD") {
    quote = count * 10;
  } else {
    quote = await gpmQuote(type, count);
  }
  let referral = getReferralId();

  if (referral.toLowerCase() === account.toLowerCase()) referral = "0x0000000000000000000000000000000000000000";
  
  console.log("referralID :", referral);

  let tokenAddress = realAddress ?? currencyAddress;

  if (method === "CARD") {
    mintBtnFinal.textContent = `Buy for ${quote} USD`;
  } else {
    amountInMax = quote;
    formatedQuote = Math.ceil(formatUnits(amountInMax, currency === "MATIC" ? 18 : 6) * 1e6) / 1e6;
    mintBtnFinal.textContent = `Buy for ${formatedQuote} ${currency?.startsWith('USDC') ? 'USDC' : currency}`;
    setMintBtnClickable(false);
    // check balance
    balance = await fetchBalance({
      address: account,
      token: method === "NATIVE" ? undefined : currencyAddress,
    });

    if (amountInMax > balance.value) {
      document.querySelector("#gpm_output").innerHTML = "<br/>Not enough in balance";
      mintBtnFinalSpinnerTl.reverse();
      setMintBtnClickable(false);

      return;
    } else {
      document.querySelector("#gpm_output").innerHTML = "";
    }

    if (method === "APPROVE") {
      // check allowance
      const allowance = await readContract({
        ...IERC20,
        address: currencyAddress,
        functionName: "allowance",
        args: [account, GPMA], // wallet and spender,
        chainId: 137,
      });

      if (amountInMax > allowance) {
        let countClosure = count;
        const unwatch = watchReadContract({
          ...IERC20,
          address: currencyAddress,
          functionName: "allowance",
          args: [account, GPMA],
          chainId: 137,
          listenToBlock: true,
        }, async (newData) => {
          if (newData !== allowance) {
            unwatch();
            await gpmComputeQuoteCheck(countClosure);
          }
        })

        mintBtnFinalSpinnerTl.reverse();
        mintBtnFinal.textContent = `Waiting Approval`;
        document.querySelector("#gpm_output").innerHTML = `
      Not enough in allowance. Must approve ${formatedQuote} USDT first
            <div
              id="approveBtn">
              Approve
            </div>`;
        setMintBtnClickable(false);
        document.querySelector("#approveBtn").onclick = async () => {
          const config = await prepareWriteContract({
            ...IERC20,
            address: currencyAddress,
            functionName: "approve",
            args: [GPMA, amountInMax],
          });
          try {
            const { hash } = await writeContract(config);
            console.log(hash);
          } catch (error) {
            console.error("Error writing contract:", error);

            errorDiv.textContent = `Rejected approval!`;
            errorDivTimeline.restart();
            return;
          }
        };
        return;
      } else {
        document.querySelector("#gpm_output").innerHTML = "";
      }
    }
  }
  mintBtnFinalSpinnerTl.reverse();
  setMintBtnClickable(true);

  mintBtnFinalBtn.onclick = async () => {
    if (mintBtnClickable) {
      console.log("mintBtnFinal clicked");
      setMintBtnClickable(false);
      mintBtnFinalSpinnerTl.play();
      let deadline = Math.floor(new Date().getTime() / 1000 + 15 * 60);
      let config;
      try {
        if (method === "CARD") {
          // let account = getAccount().address;
          // let referrer = account;
          let quantity = count;
          // const endpoint = "https://192.168.1.3:8080"; // otherwise, https://api-gpm.goldpesa.com
          const endpoint = "https://api-gpm.goldpesa.com";
          let click_id = uuidv4();
          var res = await fetch(`${endpoint}/wert/checkout?address=${account}&referral=${referral}&qty=${quantity}&clickId=${click_id}`);
          var checkout_options = await res.json();

          var options = {
            partner_id: "01HET30MMYN3NHY0ETVV6MDDVD",
            click_id,
            origin: "https://widget.wert.io",
            listeners: {
              loaded: () => console.log("loaded"),
            },
            extra: {
              item_info: {
                author: "GoldPesa Mines",
                name: "GoldPesa Mines NFT",
                seller: "GoldPesa",
              },
            },
            ...checkout_options,
          };

          const wertWidget = new WertWidget(options);

          wertWidget.mount();

          setTimeout(() => {
            mintBtnFinalSpinnerTl.reverse();
            setMintBtnClickable(true);
            mintOptionsContTl.reverse();
            successDiv.textContent = "Wait for the transaction pop up to complete the purchase.";
            successDivTimeline.restart();
          }, 5000);
        } else if (method === "NATIVE") {
          config = await prepareWriteContract({
            ...GPMAux,
            address: GPMA,
            functionName: "buyNFT",
            // args: [type, amountInMax, tokenAddress, poolFee, deadline, account, referral, count],
            args: [amountInMax, tokenAddress, poolFee, deadline, account, referral, count],
            value: amountInMax,
          }).catch((error) => {
            console.error("Error preparing contract:", error);
            setMintBtnClickable(true);
            mintBtnFinalSpinnerTl.reverse();
            errorDiv.textContent = `Minting failed!`;
            errorDivTimeline.restart();
            //return;
          });
        } else if (method === "APPROVE") {
          config = await prepareWriteContract({
            ...GPMAux,
            address: GPMA,
            functionName: "buyNFT",
            args: [amountInMax, tokenAddress, poolFee, deadline, account, referral, count],
          }).catch((error) => {
            console.error("Error preparing contract:", error);
            setMintBtnClickable(true);
            mintBtnFinalSpinnerTl.reverse();
            errorDiv.textContent = `Minting failed!`;
            errorDivTimeline.restart();
          });
        } else if (method === "PERMIT") {
          const nonce = await readContract({
            ...IERC20Permit,
            address: tokenAddress,
            functionName: "nonces",
            args: [account],
            chainId: 137,
          });

          const Permit = [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "deadline", type: "uint256" },
          ];
          const message = {
            owner: account,
            spender: GPMA,
            value: amountInMax,
            nonce: nonce.toString(16),
            deadline,
          };

          const signature = await signTypedData({
            types: {
              EIP712Domain: domainDef,
              Permit,
            },
            domain,
            primaryType: "Permit",
            message,
          });

          const { r, s } = secp256k1.Signature.fromCompact(signature.slice(2, 130));
          const v = BigInt(`0x${signature.slice(130)}`);

          config = await prepareWriteContract({
            ...GPMAux,
            address: GPMA,
            functionName: "buyNFTSig",
            args: [
              // type,
              amountInMax,
              tokenAddress,
              poolFee,
              deadline,
              account,
              referral,
              count,
              v,
              `0x${r.toString(16)}`,
              `0x${s.toString(16)}`,
            ],
          }).catch((error) => {
            console.error("Error preparing contract:", error);
            setMintBtnClickable(true);
            mintBtnFinalSpinnerTl.reverse();
            errorDiv.textContent = `Minting failed!`;
            errorDivTimeline.restart();
          });
        }
      } catch (error) {
        console.error("Error preparing contract:", error);
        setMintBtnClickable(true);
        mintBtnFinalSpinnerTl.reverse();
        errorDiv.textContent = `Minting failed!`;
        errorDivTimeline.restart();
        return;
      }

      if (config !== undefined) {
        let hash;
        try {
          const result = await writeContract(config);
          hash = result.hash;
        } catch (error) {
          console.error("Error writing contract:", error);
          mintBtnFinalSpinnerTl.reverse();
          setMintBtnClickable(true);
          errorDiv.textContent = `Minting failed!`;
          errorDivTimeline.restart();
        }
        if (hash) {
          console.log(hash);

          setMintBtnClickable(false);
          mintBtnFinalSpinnerTl.play();
          const data = await waitForTransaction({
            hash,
          });
          if (!data) {
            console.log("data :", data);
            console.log("Waiting for transaction...");
            setMintBtnClickable(false);
            mintBtnFinalSpinnerTl.play();
          } else if (data.status === "success") {
            console.log("data :", data);
            console.log("Transaction succeeded!");

            await setTokens();
            await StateManager.notifyStateChange();
            mintOptionsContTl.reverse();
            if (type === 1n) {
              if (count > 1) {
                successDiv.textContent = `${count} tokens successfully minted! You are set! Sit back, relax and check back later to see how your Gold Miners advances down the Mine while earning along the way!"`;
              } else {
                successDiv.textContent = `${count} token successfully minted! You are set! Sit back, relax and check back later to see how your Gold Miners advances down the Mine while earning along the way!"`;
              }
            } else {
              successDiv.textContent = `Gold Miner NFT successfully minted! Share your token link with 3 friends to enter the mine.`;
            }
            successDivTimeline.restart();
            await setLevels();
          } else {
            console.log("data :", data);
            console.log("Transaction failed");
            mintBtnFinalSpinnerTl.reverse();
            setMintBtnClickable(true);
            errorDiv.textContent = `Minting failed!`;
            errorDivTimeline.restart();
          }
        }
      }
    }
  };
};

const debouncedComputeQuoteCheck = debounce(gpmComputeQuoteCheck, 500);

const successDivTimeline = gsap.timeline({ paused: true });
successDivTimeline.to(".successDiv", { autoAlpha: 1, duration: 0.5 }).to(".successDiv", { autoAlpha: 0, duration: 0.5 }, ">5");

const errorDivTimeline = gsap.timeline({ paused: true });
errorDivTimeline.to(".errorDiv", { autoAlpha: 1, duration: 0.5 }).to(".errorDiv", { autoAlpha: 0, duration: 0.5 }, ">5");

console.log("called purchaseToken.js");

// get the value from the "ref" parameter in the URL
function getRefValue() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("ref");
}

//validate a crypto wallet address
function isValidCryptoAddress(address) {
  // implement more validation logic
  // check if the address length is at least 42 characters
  return address.length >= 42;
}

//save the validated address to local storage
function saveAddressToLocalStorage(address) {
  localStorage.setItem("walletAddress", address);
}

//on page load get the value from the "ref" parameter in the URL
function onPageLoad() {
  const refValue = getRefValue();
  if (refValue) {
    if (isValidCryptoAddress(refValue)) {
      saveAddressToLocalStorage(refValue);
      console.log("Crypto address saved to local storage:", refValue);
    } else {
      console.log("Invalid crypto address:", refValue);
    }
  } else {
    console.log("No 'ref' parameter found in the URL.");
  }
}

// Call the onPageLoad function when the page loads
onPageLoad();

function getReferralId() {
  // Attempt to retrieve the wallet address from local storage
  const address = localStorage.getItem("walletAddress");

  // If the address is found in local storage, return it
  if (address) {
    return address;
  }

  // If the address is not found, return a default value (e.g., '0n')
  return "0x0000000000000000000000000000000000000000";
}

const walletAddress = getReferralId();
console.log("Wallet Address from local storage:", walletAddress);
