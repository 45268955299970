import moment from "moment";
import { readContract, watchReadContract } from "@wagmi/core";

import IdGLPStaking from "./contracts/IdGLPStaking.json";
import { getCurrentUser } from "./tokens";
import { GPM, GPMA, IdGLP } from "./contracts/ContractAddresses.js";
import { parseUnits, formatEther, formatUnits } from "viem";
let APY;
async function fetchApyFromApi() {
  try {
    const res = await fetch("https://api-gpm.goldpesa.com/staking");
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await res.json();
    APY = data.apy;
  } catch (err) {
    console.error("Failed to fetch APY:", err);
    APY = 0;
  }
}
fetchApyFromApi();

let stakedTokens = [];
export class StakedToken {
  /**
   * Constructs a StakedToken instance
   *
   * @param {number} id - Unique NFT ID for each USDC Stake
   * @param {number} lpAmount - Initial USDC Amount of each Stake
   * @param {number} contractTime - Midnight UTC time the day after a staking request has been initated (Unix timestamp in seconds)
   * @param {number} maturationDate - Staking maturity date (Unix timestamp in seconds)
   */
  constructor(id, lpAmount, contractTime, maturationDate) {
    this.id = id;
    this.lpAmount = lpAmount;
    this.contractTime = contractTime;
    this.maturationDate = maturationDate;
    this.stakedPeriod = this.calculateStakedPeriod();
    this.cumulativeEarnings = this.calculateCumulativeEarnings();
  }

  calculateStakedPeriod() {
    // console.log(`Debug: contractTime = ${this.contractTime}, maturationDate = ${this.maturationDate}`);

    let duration = moment.unix(this.maturationDate).diff(moment.unix(this.contractTime), "days");
    // console.log(`Debug: duration = ${duration} days`);

    // console.log(`Duration for ID ${this.id}: ${duration} days`);
    if (duration >= 355 && duration <= 366) {
      return "360-Day";
    } else if (duration >= 85 && duration <= 95) {
      return "90-Day";
    } else if (duration >= 25 && duration <= 35) {
      return "30-Day";
    } else if (duration >= 175 && duration <= 185) {
      return "180-Day";
    } else {
      return "Unknown";
    }
  }

  calculateCumulativeEarnings() {
    const todayTimestamp = Math.floor(new Date().getTime() / 1000);
    const dayInSeconds = 60 * 60 * 24;
    let daysStaked = (todayTimestamp - this.contractTime) / dayInSeconds;
    console.log("daysStaked :", daysStaked);
    if (daysStaked < 0) {
      daysStaked = 0;
    }
    console.log("daysStaked :", daysStaked);
    // return (daysStaked * APY * this.lpAmount) / 365;
    console.log("(daysStaked * APY * this.lpAmount) / 360; :", (daysStaked * APY * this.lpAmount) / 360);
    return (daysStaked * APY * this.lpAmount) / 360;
  }
}

export const getStakedTokens = async () => {
  return [...stakedTokens];
};
export async function setStakedTokens() {
  const today = moment();

  const parseStakedTokensMetadata = (data) => {
    if (!data.startsWith("0x")) return null;
    let stakedTokens = [];
    data = data.slice(2);
    const UINT256_BYTES = 64;

    if (data.length % (UINT256_BYTES * 4) !== 0) {
      // console.error("Unexpected data length");
      return null;
    }

    let noTokens = data.length / (UINT256_BYTES * 4);
    // console.log("Number of tokens:", noTokens);

    for (var i = 0; i < noTokens; i++) {
      // console.log("Data before slice: ", data);

      let cur = data.slice(0, UINT256_BYTES * 4);
      // console.log("Current slice: ", cur);

      let token = {
        id: Number(BigInt(`0x${cur.slice(0, UINT256_BYTES)}`)),
        lpAmount: formatUnits(parseInt(cur.slice(UINT256_BYTES, UINT256_BYTES * 2), 16), 6),
        contractTime: parseInt(cur.slice(UINT256_BYTES * 2, UINT256_BYTES * 3), 16),
        maturationDate: parseInt(cur.slice(UINT256_BYTES * 3, UINT256_BYTES * 4), 16),
      };

      if (isNaN(token.id) || isNaN(token.contractTime) || isNaN(token.maturationDate)) {
        console.error("Failed to parse token data");
        return null;
      }

      stakedTokens.push(token);
      data = data.slice(UINT256_BYTES * 4);
    }
    return stakedTokens;
  };

  let account = getCurrentUser();
  const config = {
    ...IdGLPStaking,
    address: IdGLP,
    functionName: "tokensMetadata",
    args: [account],
    listenToBlock: true,
    chainId: 137,
  };
  let packedTokens;
  const dglpGetAllStakedTokens = async () => {
    try {
    packedTokens = await readContract(config);
    // console.log("packedTokens :", packedTokens);
    return parseStakedTokensMetadata(packedTokens);
    }catch(ex) {
      return [];
    }
  };
  const parsedStakedTokens = await dglpGetAllStakedTokens();
  // console.log("parsedStakedTokens :", parsedStakedTokens);

  let newTokens = [];

  for (let data of parsedStakedTokens) {
    const token = new StakedToken(data.id, data.lpAmount, data.contractTime, data.maturationDate);
    newTokens.push(token);
  }

  stakedTokens = [...newTokens];
  // console.log("stakedTokens :", stakedTokens);
  await renderStakedTokens();
}

async function renderStakedTokens() {
  const stakedTokens = await getStakedTokens();
  if (!stakedTokens || stakedTokens.length === 0) {
    return;
  }
  calculateAndDisplayEarningsInfo(stakedTokens);

  let html = "";

  for (const stakedToken of stakedTokens) {
    let tokenTotalValue = Number(stakedToken.lpAmount) + Number(stakedToken.cumulativeEarnings);
    html += `
        <div class="stakedToken">
            <div class="stakeSelection-TokenInfo">
                <div class="stakedInfo">
                    <div class="levelNoInfo">
                        <div class="tokenIndecator"></div>
                        <div class="stakedTokenId">#${stakedToken.id}</div>
                        <div class="stakedPeriod">${stakedToken.stakedPeriod}</div>
                    </div>
                    <div class="stakedAprText">End Date</div>
                    <div class="StakedAprValue">${moment.unix(stakedToken.maturationDate).format("YYYY-MM-DD")}</div>
                </div>
            </div>
            <div class="stakedCumulativeInterest">
                <div class="levelPrizeAmount">$${tokenTotalValue.toFixed(2)}</div>
                <div class="stakedCumulativeInterestText">Est. Cumulative Earnings</div>
                <div class="stakedCumulativeInterestValue">$${stakedToken.cumulativeEarnings.toFixed(3)}</div>
            </div>
        </div>`;
  }

  document.querySelector(".earningsStakedTokens").innerHTML = html;
}

function calculateAndDisplayEarningsInfo(stakedTokens) {
  const totalLpAmount = stakedTokens.reduce((acc, token) => acc + Number(token.lpAmount), 0);
  const totalCumulativeEarnings = stakedTokens.reduce((acc, token) => acc + Number(token.cumulativeEarnings), 0);
  const totalCurrentValue = totalLpAmount + totalCumulativeEarnings;

  // Calculate last day pnl
  // const dailyInterestRate = APY / 365;
  const dailyInterestRate = APY / 360;
  const lastDayPnL = totalLpAmount * dailyInterestRate;

  // Calculate last 30 day pnl
  const last30DayPnL = totalLpAmount * dailyInterestRate * 30;

  document.querySelector(".earningsTotalValueGPOText").textContent = `$${totalCurrentValue.toFixed(2)}`;
  document.querySelector(".earningsLastDayGPO").textContent = `$${lastDayPnL.toFixed(3)}`;
  document.querySelector(".earnings30DayGPO").textContent = `$${last30DayPnL.toFixed(3)}`;
  const APYPercentage = APY * 100;
  document.querySelector(".APYValueText").textContent = `${APYPercentage.toFixed(2)}%`;
}
