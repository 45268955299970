import { gsap } from "gsap";
import StateManager from "./appStates.js";
import { disconnectTimeline } from "../script.js";
import { setContainerState } from "./menu.js";
import { setCurrentUser, initRender, setTokens, setLevels } from "./tokens.js";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { configureChains, createConfig, getAccount, watchAccount, disconnect } from "@wagmi/core";
import { polygon } from "@wagmi/core/chains";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";
import USDTlogo from "../../static/logo/USDTlogo.png";

//#region web3 connect
const projectId = "b264ef5b4005192253cb17358da788f3";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://polygon-rpc.com/`,
      }),
    }),
  ]
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
  webSocketPublicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
export const web3modal = new Web3Modal(
  {
    projectId,
    defaultChain: polygon,
    themeMode: "dark",
    enableNetworkView: false,
    tokenContracts: {
      137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    },
    tokenImages: {
      USDT: USDTlogo,
    },
    themeVariables: {
      "--w3m-accent-color": "#ffcc0f",
      "--w3m-background-color": "#ffcc0f",
      "--w3m-z-index": "1001",
      "--w3m-accent-fill-color": "#3d3d3d",
    },
  },
  ethereumClient
);
//#endregion

export function subscribeModal(openCallback, closeCallback) {
  return web3modal.subscribeModal((newState) => {
    if (newState.open) {
      openCallback();
    } else {
      closeCallback();
    }
  });
}

let areAssetsLoaded = false;
window.addEventListener("assetsLoaded", () => {
  console.log("Assets loaded: Fire ConnectWallet");
  areAssetsLoaded = true;
});
const unwatch = watchAccount(async (account) => {
  console.log(account);
  const handleStateChangeWhenAssetsLoaded = async () => {
    if (areAssetsLoaded) {
      await StateManager.notifyStateChange();
    } else {
      const handleAssetsLoaded = async () => {
        await StateManager.notifyStateChange();
        window.removeEventListener("assetsLoaded", handleAssetsLoaded);
      };
      window.addEventListener("assetsLoaded", handleAssetsLoaded);
    }
  };

  if (account.isConnected) {
    StateManager.setWalletConnected(true);
    setCurrentUser(account.address);
    formatreferralAdress(account.address);
    await setTokens();
    await handleStateChangeWhenAssetsLoaded();
    gsap.delayedCall(0.1, () => connectedAsAddressTl.play());
    await setLevels();
    gsap.delayedCall(0.1, () => setContainerState("BOTTOM", 0.5));
  }

  if (account.isDisconnected) {
    StateManager.setWalletConnected(false);
    setCurrentUser(null);
    await handleStateChangeWhenAssetsLoaded();
    await initRender();
  }
});

document.querySelector(".disconnectLabel").addEventListener("click", async () => {
  disconnectTimeline.play();
  gsap.delayedCall(1, async () => {
    await disconnect();
    location.reload();
  });
});

const connectedAsAddressTl = gsap.timeline({
  paused: true,
});
connectedAsAddressTl
  .to(".connectedAddressNotiCont", { ease: "power2.out", y: "140px", duration: 1.2 })
  .to(".connectedAddressNotiCont", { ease: "power2.out", y: "-140px", duration: 1.2 }, ">2");

document.querySelector(".connectedAddressNotiCont").addEventListener("click", () => {
  gsap.to(".connectedAddressNotiCont", { ease: "power2.out", y: "-140px", duration: 1.2 });
});
function shortAddress(address) {
  return address.slice(0, 4) + "..." + address.slice(-4);
}

const referralWalletAddrerss = document.querySelector(".referralWalletAddrerss");
let fullReferralAddress;
function formatreferralAdress(address) {
  const shortWalletAddress = shortAddress(address);
  // referralWalletAddrerss.innerHTML = `${window.location.origin}/?ref=${shortWalletAddress}`;
  fullReferralAddress = `${window.location.origin}/?ref=${address}`;
  console.log("referralWalletAddrerss.innerHTML :", referralWalletAddrerss.innerHTML);
  console.log("fullReferralAddress :", fullReferralAddress);
}

const copyLinkTimeline = gsap.timeline({ paused: true });
copyLinkTimeline
  .to(".copyWalletAddress", { display: "none", duration: 0 })
  .to(".copiedWalletAddress", { display: "flex", duration: 0 }, ">0.01")
  .to(".copiedWalletAddress", { display: "none", duration: 0 }, ">2")
  .to(".copyWalletAddress", { display: "flex", duration: 0 }, ">0.01");

const headerWalletAdress = document.querySelector(".headerWalletAdress");
headerWalletAdress.addEventListener("click", () => {
  navigator.clipboard.writeText(fullReferralAddress);
  copyLinkTimeline.restart();
});
