import moment from "moment";
import { gsap } from "gsap";
import { getCurrentUser, getTokens } from "./tokens.js";
import { getStakedTokens } from "./stakedTokens.js";
async function totalWinngs() {
  const tokens = await getTokens();
  const totalCredits = tokens.reduce((total, token) => total + Number(token.cumulativeWinnings), 0);

  const stakedTokens = await getStakedTokens();
  const totalLpAmount = stakedTokens.reduce((acc, token) => acc + token.lpAmount, 0);
  const totalCumulativeEarnings = stakedTokens.reduce((acc, token) => acc + token.cumulativeEarnings, 0);
  const totalCurrentValue = totalLpAmount + totalCumulativeEarnings;

  const totalWinnings = numberWithCommas((totalCredits + totalCurrentValue).toFixed(2));
  return totalWinnings;
}
function numberWithCommas(x) {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const notificationsParams = {
  MINTED: {
    messageTitle: "Token Successfully Minted",
    messageBody: "Your #${id} token successfully minted.",
  },
  NEW_REFERREE: {
    messageTitle: "User ${metadata.referrer} Joined Your Team!",
    messageBody: "${3 - metadata.currentReferralCount} Referrals left to unlock your #${id} NFT.",
  },
  ASCENSION: {
    messageTitle: "You Won! #${id} just Leveled Up!",
    messageBody: "congratulations! Your token has progressed to level ${metadata.level}.",
  },
  CASHOUT: {
    /////////////
    messageTitle: "You Cashed Out #${id} token!",
    messageBody: "Successfull transaction, you cashed out your token.",
  },
  STAKE: {
    messageTitle: "You Staked Your #${id} token",
    messageBody: "You just staked your token at level ${metadata.level}.",
  },
  TOTAL_WINNINGS: {
    messageTitle: "Welcome Back, Today's Winnings are $${metadata.winnings}",
    //messageBody: "Your total winnings today are ${metadata.winnings}, enjoy the game!",
    messageBody: "Enjoy the game!",
  },
};
const notificationsContainer = document.querySelector(".notificationscardsContent");
const seeMoreButton = document.querySelector(".notiloadMore");
const noTokensDiv = document.createElement("div");
noTokensDiv.classList.add("noStakedTokens");

const baseURL = "https://api-gpm.goldpesa.com";
const lastNRecords = 50;
let sortedEvents = [];
let currentIndex = 0;
const notificationsSkeletonTl = gsap.timeline({
  paused: true,
});
notificationsSkeletonTl.to(".notiSkeletonCont", { autoAlpha: 1, duration: 0.5 });

export async function fetchWalletHistory(address) {
  notificationsSkeletonTl.play();
  noTokensDiv.textContent = "";

  try {
    const walletAddress = getCurrentUser();
    const response = await fetch(`${baseURL}/wallet_history?wallet=${walletAddress}&lastNRecords=${lastNRecords}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    console.log("notifications data :", data);
    if (!data.events || data.events.length === 0) {
      console.log("No notifications events data received");
      noTokensDiv.textContent = "You have no notifications.";
      notificationsContainer.appendChild(noTokensDiv);
      notificationsSkeletonTl.reverse();
      return;
    }
    sortedEvents = data.events.sort((a, b) => moment(b.date).diff(moment(a.date)));

    const currentDate = moment();
    const eventTime = currentDate.clone().hour(15).minute(23).second(0);

    if (currentDate.isAfter(eventTime)) {
      const totalWinningsValue = await totalWinngs();
      const totalWinningsEvent = {
        type: "TOTAL_WINNINGS",
        date: eventTime.toISOString(),
        metadata: { winnings: totalWinningsValue },
      };
      sortedEvents.unshift(totalWinningsEvent);
      sortedEvents.sort((a, b) => moment(b.date).diff(moment(a.date)));
    }
    console.log("sortedEvents :", sortedEvents);
    resetWalletHistory();
    loadNotifications(currentIndex, 5);
    gsap.delayedCall(1, () => notificationsSkeletonTl.reverse());
  } catch (error) {
    console.error("Error fetching wallet history:", error);
  }
}
function checkAndFetch() {
  const currentDate = moment();
  const eventTime = currentDate.clone().hour(15).minute(23).second(0);

  if (currentDate.isAfter(eventTime)) {
    const notificationsIconCount = document.querySelectorAll(".notificationsIconCount");
    notificationsIconCount.forEach((el) => {
      el.style.display = "block";
    });
  } else {
    const timeUntilEvent = eventTime.diff(currentDate);
    setTimeout(checkAndFetch, timeUntilEvent);
  }
}
checkAndFetch();

export function resetWalletHistory() {
  currentIndex = 0;
  notificationsContainer.innerHTML = "";
  noTokensDiv.textContent = "";
  seeMoreButton.style.display = "none";
  const notificationsIconCount = document.querySelectorAll(".notificationsIconCount");
  notificationsIconCount.forEach((el) => {
    el.style.display = "none";
  });
}

function replacePlaceholders(template, data) {
  return template.replace(/\$\{(\d+ - [\w\.]+|[\w\.]+ - \d+|[\w\.]+ \+ \d+|\d+ \+ [\w\.]+|[\w\.]+)\}/g, (_, key) => {
    if (key.includes("-")) {
      const [left, right] = key.split(" - ");
      if (isNaN(left)) {
        return resolvePath(data, left) - Number(right);
      } else {
        return Number(left) - resolvePath(data, right);
      }
    } else if (key.includes("+")) {
      const [left, right] = key.split(" + ");
      if (isNaN(left)) {
        return resolvePath(data, left) + Number(right);
      } else {
        return Number(left) + resolvePath(data, right);
      }
    } else {
      return resolvePath(data, key);
    }
  });
}

function resolvePath(obj, path) {
  const parts = path.split(".");
  let value = obj;

  for (const part of parts) {
    value = value[part];
  }

  return value || "";
}
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "now",
    ss: "now",
    m: "1 minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});
function relativeTime(date) {
  const timeFromNow = moment(date).fromNow();

  if (timeFromNow === "now ago") {
    return "now";
  }

  return timeFromNow;
}

function updateRelativeTime(element, date) {
  setInterval(() => {
    element.textContent = relativeTime(date);
  }, 30000);
}
function createNotificationDiv(eventData) {
  const params = notificationsParams[eventData.type];
  if (!params) return null;

  let bodyMessage = params.messageBody;
  let bodyTitle = params.messageTitle;
  if (eventData.type === "NEW_REFERREE") {
    const remainingReferrals = 3 - eventData.metadata.currentReferralCount;

    if (remainingReferrals === 0) {
      bodyMessage = "Your team is complete, your #${id} token has entered the game!";
    }
  }
  if (eventData.type === "ASCENSION") {
    const ascensionLevel = eventData.metadata.level;

    if (ascensionLevel === 0) {
      bodyTitle = "Game on!";
      bodyMessage = "Your #${id} token has entered the game.";
    }
  }

  const title = replacePlaceholders(bodyTitle, { ...eventData, metadata: eventData.metadata || {} });
  const body = replacePlaceholders(bodyMessage, { ...eventData, metadata: eventData.metadata || {} });

  const notificationDiv = document.createElement("div");
  notificationDiv.className = "notificationsCards";

  const headerDiv = document.createElement("div");
  headerDiv.className = "notificationsCardHeader";

  const headlineDiv = document.createElement("div");
  headlineDiv.className = "notificationsCardHeadline notificationTotalWinningCard";
  headlineDiv.textContent = title;

  const timeDiv = document.createElement("div");
  timeDiv.className = "notificationsCardTime";
  timeDiv.textContent = relativeTime(eventData.date);
  updateRelativeTime(timeDiv, eventData.date);

  const bodyDiv = document.createElement("div");
  bodyDiv.className = "notificationsText notificationTotalWinningCard";
  bodyDiv.textContent = body;

  headerDiv.appendChild(headlineDiv);
  headerDiv.appendChild(timeDiv);
  notificationDiv.appendChild(headerDiv);
  notificationDiv.appendChild(bodyDiv);

  return notificationDiv;
}

function loadNotifications(startIndex, count) {
  const toRender = sortedEvents.slice(startIndex, startIndex + count);

  toRender.forEach((event) => {
    const notification = createNotificationDiv(event);
    if (notification) {
      notificationsContainer.appendChild(notification);
    }
  });

  if (sortedEvents.length > startIndex + count) {
    seeMoreButton.style.display = "block";
    seeMoreButton.onclick = () => {
      currentIndex += count;
      loadNotifications(currentIndex, count);
    };
  } else {
    seeMoreButton.style.display = "none";
  }
}
