import "./style.css";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import * as dat from "dat.gui";
import Stats from "stats.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ColorManagement, HalfFloatType } from "three";

import {
  EffectComposer,
  RenderPass,
  EffectPass,
  NormalPass,
  GodRaysEffect,
  SelectiveBloomEffect,
  BlendFunction,
  BrightnessContrastEffect,
  SSAOEffect,
  SMAAEffect,
  SMAAPreset,
} from "postprocessing";
import { OverrideMaterialManager } from "postprocessing";
import StateManager from "./functions/appStates.js";
import { subscribeModal } from "./functions/connectWallet.js";
import { findTokenByType } from "./functions/tokens.js";
import { toggleHammerEvents } from "./functions/menu.js";
import { Draggable } from "gsap/Draggable";
import "./functions/purchaseToken.js";

gsap.registerPlugin(Draggable);

Draggable.create(".mainProgressContainerNotConnected", {
  type: "y",
  trigger: ".mainProgressContainerNotConnected .credit",
  bounds: { minY: 0, maxY: 200 },
  zIndexBoost: false,
});

//#region base stuff
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
OverrideMaterialManager.workaroundEnabled = true;

/**
 * Stats
 */
const stats = new Stats();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// debug
const gui = new dat.GUI();
gui.close();
gui.hide();
/**
 * Lights
 */
const spotlight = new THREE.SpotLight(0xffffff);
spotlight.intensity = 2.7;
spotlight.angle = 0.16; //16
spotlight.decay = 0;
spotlight.penumbra = 1;
spotlight.castShadow = true;
//spotlight.shadow
spotlight.position.set(-0.2, 3.8, 8.1);
scene.add(spotlight);

// manager
const manager = new THREE.LoadingManager();
const secondmanager = new THREE.LoadingManager();

ColorManagement.enabled = true;

// Texture loader
const textureLoader = new THREE.TextureLoader(manager);
const matcapTexture = textureLoader.load("/textures/matcaps/18.png");
const materialMatCap = new THREE.MeshMatcapMaterial();
materialMatCap.matcap = matcapTexture;
matcapTexture.colorSpace = THREE.SRGBColorSpace;

const matcapTexture2 = textureLoader.load("/textures/matcaps/8.png");
const materialMatCap2 = new THREE.MeshMatcapMaterial();
materialMatCap2.matcap = matcapTexture2;
matcapTexture2.colorSpace = THREE.SRGBColorSpace;

const matcapTexture3 = textureLoader.load("/textures/matcaps/20.png");
const materialMatCap3 = new THREE.MeshMatcapMaterial();
materialMatCap3.matcap = matcapTexture3;
matcapTexture3.colorSpace = THREE.SRGBColorSpace;

const matcapTexture4 = textureLoader.load("/textures/matcaps/9.png");
const materialMatCap4 = new THREE.MeshMatcapMaterial();
materialMatCap4.matcap = matcapTexture4;
matcapTexture4.colorSpace = THREE.SRGBColorSpace;

const matcapTextureWin = textureLoader.load("/textures/matcaps/20.png"); //2 4 9 10 11 18 19 20
const materialMatCapWin = new THREE.MeshMatcapMaterial();
materialMatCapWin.matcap = matcapTextureWin;
matcapTextureWin.colorSpace = THREE.SRGBColorSpace;
materialMatCapWin.transparent = true;
materialMatCapWin.opacity = 0;
materialMatCapWin.depthWrite = false;

const cubeTextureLoader = new THREE.CubeTextureLoader();
const newEnvMap = cubeTextureLoader.load([
  "/textures/environmentMaps/4/px.png",
  "/textures/environmentMaps/4/nx.png",
  "/textures/environmentMaps/4/py.png",
  "/textures/environmentMaps/4/ny.png",
  "/textures/environmentMaps/4/pz.png",
  "/textures/environmentMaps/4/nz.png",
]);
newEnvMap.colorSpace = THREE.SRGBColorSpace;
newEnvMap.flipY = false;
newEnvMap;
scene.environment = newEnvMap;

//#endregion

//#region // declarations - colors - second manager and loader loading two textures
const scrollcontainer = ".scrollcontainer";

//group gallery
const group = new THREE.Group();
scene.add(group);
let goldLocksMaterial;
let goldLighterMaterial;
let goldSmoothMaterial;
let goldLocksMaterialColor = new THREE.Color("#D09C5E");
let goldLighterMaterialColor = new THREE.Color("#F2C761");
let goldSmoothMaterialColor = new THREE.Color("#D09C5E");
let goldLocksMaterialDarkColor = new THREE.Color("#382A19");
let goldLighterMaterialDarkColor = new THREE.Color("#534421");
let goldSmoothMaterialDarkColor = new THREE.Color("#44331E");
let goldLocksMaterialLightColor = new THREE.Color("#D09C5E");
let goldLighterMaterialLightColor = new THREE.Color("#F2C761");
let goldSmoothMaterialLightColor = new THREE.Color("#D09C5E");
let airlock;
let airlock1;
let airlock2;
let airlock3;
let airlock4;
let airlock5;
let airlock6;
let floorBigCircle;
let interiorCircle;
let interiorCeilingBig;
let interiorCeilingSmall;

let winSlice;
let MouseBoll;
let mixer;
let mixer2;
let fallingLandingMixer;
let cyborgMain;
let fallingLoopAction;
let action;
let idlePlayersAction;
let idlePlayerAction;
let fallingLoopAction2;
let cyporg1;
let cyporg2;
let cyporg3;
let cyporg4;
let notConnectedTrigger;
let instanceMesh;
let coinMeshh;
// Define timelines for repeated animations
let handleWalletNotConnectedTimeline;
let handleOtherStatesTimeline;
let inGameStateTimeline;
let noTokensOnlyRefStateTimeline;
//#endregion

//#region load mine
let gltfLoader = new GLTFLoader(manager);
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderConfig({ type: "js" });
dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");

gltfLoader.setDRACOLoader(dracoLoader);
gltfLoader.load("/models/mineFinalMergedDraco/mineFinalMerged.gltf", (gltf) => {
  gltf.scene.position.set(0, 2.28, 0);
  gltf.scene.scale.set(1, 1, 1);

  const mine = gltf.scene.children[0];
  gltf.scene.children[0].traverse((child) => {
    if (child.isMesh) {
      child.receiveShadow = true;
    }
  });

  const exterior = mine.children.find((child) => child.name === "exterior");
  airlock = exterior.children.find((child) => child.name === "airlock");
  airlock1 = airlock.children[0].children[0];
  airlock2 = airlock.children[1].children[0];
  airlock3 = airlock.children[2].children[0];
  airlock4 = airlock.children[3].children[0];
  airlock5 = airlock.children[4].children[0];
  airlock6 = airlock.children[5].children[0];
  coinMeshh = mine.children.find((child) => child.name === "coin");
  coinMeshh.layers.enable(11);

  MouseBoll = exterior.children.find((child) => child.name === "MouseBoll");
  MouseBoll.position.set(0.041216690093278885, 5.139537334442139, 2.7);
  MouseBoll.scale.set(1.2, 1.2, 1.2);

  const exteriorCircles = exterior.children.find((child) => child.name === "exterior_circles");
  const bigExteriorCircles = exteriorCircles.children[0];
  const smallExteriorCircles = exteriorCircles.children[1];
  bigExteriorCircles.rotation.y = 0;
  smallExteriorCircles.rotation.y = 0;

  gsap.to(bigExteriorCircles.rotation, { y: 180, duration: 1100, ease: "none", repeat: -1 });
  gsap.to(smallExteriorCircles.rotation, { y: -180, duration: 1100, ease: "none", repeat: -1 });

  floorBigCircle = gltf.scene.children.find((child) => child.name === "floorBigCircle");

  interiorCircle = floorBigCircle.children.find((child) => child.name === "InteriorCircle");
  winSlice = interiorCircle.children.find((child) => child.name === "winSlice");
  winSlice.layers.enable(11);

  const interiorCeilingColor = mine.children.find((child) => child.name === "whiteGlowObj");
  interiorCeilingColor.layers.enable(11);

  interiorCeilingSmall = mine.children.find((child) => child.name === "interiorCeilingSmall");
  interiorCeilingBig = mine.children.find((child) => child.name === "interiorCeilingBig");
  const darkSmudge = mine.children.find((child) => child.name === "darkSmudgeObj");
  darkSmudge.material.map.colorSpace = THREE.NoColorSpace;

  //materials
  goldLocksMaterial = bigExteriorCircles.material;
  goldLighterMaterial = interiorCeilingSmall.material;
  goldSmoothMaterial = interiorCeilingBig.material;

  goldLocksMaterial.color = goldLocksMaterialColor;
  goldLighterMaterial.color = goldLighterMaterialColor;
  goldSmoothMaterial.color = goldSmoothMaterialColor;

  coinMeshh.material = goldSmoothMaterial;
  let coinMaterial = coinMeshh.material;
  instanceMesh = new THREE.InstancedMesh(coinMeshh.geometry, coinMaterial, 50);
  for (let i = 0; i < 50; i++) {
    const matrix = new THREE.Matrix4();
    matrix.setPosition(new THREE.Vector3(0, 0, 0)); // initial position
    instanceMesh.setMatrixAt(i, matrix);
  }
  instanceMesh.scale.set(0, 0, 0);
  instanceMesh.position.set(-0.003, 0.055, 0.031);

  const instanceMeshfold = gui.addFolder("instanceMesh loc rot");

  instanceMeshfold.add(instanceMesh.position, "x").min(-3).max(20).step(0.001);
  instanceMeshfold.add(instanceMesh.position, "y").min(-3).max(20).step(0.001);
  instanceMeshfold.add(instanceMesh.position, "z").min(-3).max(20).step(0.001);
  instanceMeshfold.add(instanceMesh.rotation, "x").min(-3).max(20).step(0.001);
  instanceMeshfold.add(instanceMesh.rotation, "y").min(-3).max(20).step(0.001);
  instanceMeshfold.add(instanceMesh.rotation, "z").min(-3).max(20).step(0.001);
  instanceMeshfold.close();

  gsap.set(floorBigCircle.rotation, { y: 0 });
  gsap.set(interiorCircle.rotation, { y: 0 }, "<");

  group.add(gltf.scene);
  mine.add(instanceMesh);
});
//#endregion

//#region ///////MAIN scene loaded, load other models
gsap.set(".connectedTokenHeader", { autoAlpha: 0, display: "none" });
gsap.set(".headerWalletAdress", { autoAlpha: 0, display: "none" });

//gsap.set(".addressAvataerCont", { display: "block" });
gsap.set(".tokensContainer", { autoAlpha: 0 });
gsap.set(".connectedHeader", { backgroundColor: "#ffffff00", backdropFilter: "blur(0px)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0)" });
manager.onLoad = function () {
  let gltfLoader2 = new GLTFLoader(secondmanager);
  gltfLoader2.setDRACOLoader(dracoLoader);

  gltfLoader2.load("/models/CyborgDraco/Cyborg.gltf", (hero) => {
    cyborgMain = hero.scene;
    hero.scene.scale.set(8, 8, 8);
    hero.scene.position.set(-0.124, 0.51, 5.28);
    const cyborg = hero.scene.children[0];
    cyborg.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
      }
    });

    floorBigCircle.add(hero.scene);
    const cyborgFolder = gui.addFolder("cyborg loc rot");

    cyborgFolder.add(cyborgMain.position, "x").min(-6).max(25).step(0.001);
    cyborgFolder.add(cyborgMain.position, "y").min(-6).max(25).step(0.001);
    cyborgFolder.add(cyborgMain.position, "z").min(-6).max(25).step(0.001);
    cyborgFolder.add(cyborgMain.rotation, "x").min(-6).max(25).step(0.001);
    cyborgFolder.add(cyborgMain.rotation, "y").min(-12).max(25).step(0.001);
    cyborgFolder.add(cyborgMain.rotation, "z").min(-7).max(25).step(0.001);
    cyborgFolder.close();

    mixer = new THREE.AnimationMixer(hero.scene);
    const clips = hero.animations;
    const idleOffensiveclip = THREE.AnimationClip.findByName(clips, "idleOffensive"); ////
    const cyporgIdle2Clip = THREE.AnimationClip.findByName(clips, "idle2"); ///
    const cyporgIdleClip = THREE.AnimationClip.findByName(clips, "idleRifle"); ///
    const fallingLoopClip = THREE.AnimationClip.findByName(clips, "fallingLoop"); ///

    action = mixer.clipAction(idleOffensiveclip);
    idlePlayerAction = mixer.clipAction(cyporgIdleClip);
    idlePlayerAction.loop = THREE.LoopPingPong;
    idlePlayerAction.play();
    idlePlayerAction.weight = 0;

    fallingLoopAction = mixer.clipAction(fallingLoopClip);
    action.loop = THREE.LoopPingPong;
    action.play();
    fallingLoopAction.loop = THREE.LoopPingPong;
    fallingLoopAction.play();
    fallingLoopAction.weight = 0;

    fallingLandingMixer = new THREE.AnimationMixer(hero.scene);

    const cyborgMesh = cyborg.getObjectByName("body");
    cyborgMesh.layers.enable(11);
    instanceMesh.material = cyborgMesh.material;
    cyporg1 = clone(hero.scene);
    cyporg1.position.set(-0.97, 0.1, 1);
    cyporg1.rotation.set(0, 2.55, 0);
    floorBigCircle.add(cyporg1);
    const cyporgAnimationGroup = new THREE.AnimationObjectGroup();
    cyporgAnimationGroup.add(cyporg1);
    const cyborgMesh1 = cyporg1.getObjectByName("body");
    cyborgMesh1.material = materialMatCap;

    mixer2 = new THREE.AnimationMixer(cyporgAnimationGroup);
    idlePlayersAction = mixer2.clipAction(cyporgIdle2Clip);
    idlePlayersAction.loop = THREE.LoopPingPong;
    idlePlayersAction.play();
    fallingLoopAction2 = mixer2.clipAction(fallingLoopClip);
    fallingLoopAction2.loop = THREE.LoopPingPong;
    fallingLoopAction2.play();
    fallingLoopAction2.weight = 0;

    cyporg2 = clone(hero.scene);
    cyporg2.position.set(0.35, 0.1, -1.2);
    cyporg2.rotation.set(0, -0.503, 0);

    floorBigCircle.add(cyporg2);
    cyporgAnimationGroup.add(cyporg2);
    const cyborgMesh2 = cyporg2.getObjectByName("body");
    cyborgMesh2.material = materialMatCap2;

    cyporg3 = clone(hero.scene);
    cyporg3.position.set(1.3, 0.1, -0.32);
    cyporg3.rotation.set(0, -1.3, 0);

    floorBigCircle.add(cyporg3);
    cyporgAnimationGroup.add(cyporg3);
    const cyborgMesh3 = cyporg3.getObjectByName("body");
    cyborgMesh3.material = materialMatCap3;

    cyporg4 = clone(hero.scene);
    cyporg4.position.set(-1.09, 0.1, -0.65);
    cyporg4.rotation.set(0, 1.014, 0);

    floorBigCircle.add(cyporg4);
    cyporgAnimationGroup.add(cyporg4);
    const cyborgMesh4 = cyporg4.getObjectByName("body");
    cyborgMesh4.material = materialMatCap4;
  });

  //#endregion

  //main animation timeline
  const scrollcontainerelm = document.querySelector(".scrollcontainer");
  let targetElement = document.querySelector(".credit .creditText .creditAmount");
  let proxy = { textContent: +targetElement.textContent };
  ScrollTrigger.config({ ignoreMobileResize: true });
  secondmanager.onLoad = function () {
    let tl = gsap.timeline({
      scrollTrigger: {
        scrub: 1,
        id: "notConnected",
        trigger: scrollcontainer,
        start: 30,
        end: scrollcontainerelm.offsetHeight - 1000,
        onUpdate: function (self) {
          const currentProgress = self.progress;
          let closestIndex = 0;
          let minDiff = Math.abs(currentProgress - progressMarkers[0]);
          for (let i = 1; i < progressMarkers.length; i++) {
            let diff = Math.abs(currentProgress - progressMarkers[i]);
            if (diff < minDiff) {
              closestIndex = i;
              minDiff = diff;
            }
          }
          currentPositionIndex = closestIndex;
        },
      },
      defaults: {
        ease: "none",
      },
    });
    //#region timeline
    tl.set(camera.position, { x: 0, y: 7.65, z: 6.105 }, "<")
      .set(camera.rotation, { x: -0.25, y: 0, z: 0 }, "<")
      .set(camera, { fov: 85 }, "<")
      .set(cyborgMain.position, { x: -0.124, y: 0.51, z: 5.28 }, "<")
      .set(cyborgMain.rotation, { x: 0, y: 0, z: 0 }, "<")
      .set(coinMeshh, { visible: true }, "<")
      .set(
        [airlock1.position, airlock2.position, airlock3.position, airlock4.position, airlock5.position, airlock6.position],
        { x: 0 },
        "<"
      )
      .set(
        goldLocksMaterialColor,
        { r: goldLocksMaterialLightColor.r, g: goldLocksMaterialLightColor.g, b: goldLocksMaterialLightColor.b },
        "<"
      )
      .set(
        goldLighterMaterialColor,
        { r: goldLighterMaterialLightColor.r, g: goldLighterMaterialLightColor.g, b: goldLighterMaterialLightColor.b },
        "<"
      )
      .set(
        goldSmoothMaterialColor,
        { r: goldSmoothMaterialLightColor.r, g: goldSmoothMaterialLightColor.g, b: goldSmoothMaterialLightColor.b },
        "<"
      )
      .set(".connectedHeader", { backgroundColor: "#ffffff00", backdropFilter: "blur(0px)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0)" }, "<")

      .set(".hero", { autoAlpha: 1 }, "<")
      .set(".counterAndBtnCont", { autoAlpha: 1 }, "<")
      .set(".mainProgressContainerConnected", { autoAlpha: 0, y: "100%", duration: 0.2 }, "<")
      .set(".connectLeft", { autoAlpha: 0 }, "<")
      .set(".mintBtn", { autoAlpha: 0 }, "<")

      //start timeline
      .addLabel("start")
      .to(camera.position, { x: 0, y: 7.7, z: 7.8, duration: 1 })
      .to(airlock1.position, { x: 0.6, duration: 1 }, "<")
      .to(airlock2.position, { x: 0.6, duration: 1 }, "<")
      .to(airlock3.position, { x: 0.6, duration: 1 }, "<")
      .to(airlock4.position, { x: 0.6, duration: 1 }, "<")
      .to(airlock5.position, { x: 0.6, duration: 1 }, "<")
      .to(airlock6.position, { x: 0.6, duration: 1 }, "<")
      .to(".hero", { autoAlpha: 0, duration: 1 }, "<")
      .to(".counterAndBtnCont", { autoAlpha: 0, duration: 1 }, "<")

      .to(camera.position, { x: 5.1, y: 9.5, z: 4.85, duration: 2 }, ">")
      .to(camera.rotation, { x: 0, y: 1.27, z: 0, duration: 2 }, "<")
      .addLabel("step2")
      .to(".screen1", { autoAlpha: 1, duration: 0.5 }, ">-0.5")
      .to(".scrollDownIcon", { y: 0, duration: 0.5 }, "<")
      .to(".scrollUpIcon", { autoAlpha: 1, duration: 0.5 }, "<")
      .to(".screen1", { autoAlpha: 0, duration: 0.5 }, ">1")

      .to(camera.position, { x: 5.1, y: 7, z: 4.85, duration: 2 }, ">-0.5")

      .to(camera.position, { x: 0, y: 3, z: 11, duration: 2 }, ">")
      .to(camera.rotation, { x: 0, y: 0, z: 0, duration: 2 }, "<")
      .to(".screen2", { autoAlpha: 1, duration: 0.5 }, ">")
      .to(".screen2", { autoAlpha: 0, duration: 0.5 }, ">1.5")
      .to(camera.position, { x: 0.03, y: 2.8, z: 7.1, duration: 2 }, ">-0.3")

      .to(camera.rotation, { x: 0.3, y: 0, z: 0, duration: 2 }, "<")
      .to(".StickyOverlay", { xPercent: 0, duration: 1 }, "<1")
      .addLabel("step3")
      .to(".screen3", { autoAlpha: 1, duration: 0.5 }, "<")
      .to(".mintBtn", { autoAlpha: 1, duration: 0.5 }, "<")
      // .to(".counter-container", { autoAlpha: 1, duration: 0.5 }, "<")
      .to(".screen3", { autoAlpha: 0, duration: 0.5 }, ">2")
      .to(".mintBtn", { autoAlpha: 0, duration: 0.5 }, "<")
      // .to(".counter-container", { autoAlpha: 0, duration: 0.5 }, "<")
      .to(".mintBtn", { bottom: "10vh", duration: 0.5 }, "<")

      .to(".step1 .ExploreNav-diamond .outerDiamond", { opacity: 0, duration: 1 }, ">-2")
      .to(".step1 .ExploreNav-diamond .highlightDiamond", { opacity: 1, duration: 1 }, "<")
      .to(".step1 .ExploreNav-diamond .highlightDiamondOuter", { opacity: 0.5, duration: 1 }, "<")
      .to(".step1 .ExploreNav-diamond .innerDiamond", { opacity: 0, duration: 0.5 }, "<")
      .to(".step1 .ExploreNav-diamond span", { opacity: 1, duration: 0.5 }, ">")
      .to(".step1 .ExploreNav-lineProgress", { scaleY: 1, duration: 2 }, ">")

      .to(camera.position, { x: 0, y: 2.8, z: 4.2, duration: 1 }, "<")
      .set(cyborgMain.position, { x: 0.62, y: 0.1, z: 1.1 }, ">-0.2")
      .set(cyborgMain.rotation, { x: 0, y: -3, z: 0 }, "<")

      .to(camera.position, { x: 0, y: 3.3, z: 3.7, duration: 1 }, ">0.2")
      .to(camera.rotation, { x: -0.3, y: 0, z: 0, duration: 2 }, "<-1")
      .to(camera, { fov: 100, duration: 2 }, "<")
      .to(brightnessContrastEffect.uniforms.get("contrast"), { value: 0.2, duration: 2 }, "<")
      .to(
        goldLocksMaterialColor,
        { r: goldLocksMaterialDarkColor.r, g: goldLocksMaterialDarkColor.g, b: goldLocksMaterialDarkColor.b, duration: 2 },
        "<"
      )
      .to(
        goldLighterMaterialColor,
        { r: goldLighterMaterialDarkColor.r, g: goldLighterMaterialDarkColor.g, b: goldLighterMaterialDarkColor.b, duration: 2 },
        "<"
      )
      .to(
        goldSmoothMaterialColor,
        { r: goldSmoothMaterialDarkColor.r, g: goldSmoothMaterialDarkColor.g, b: goldSmoothMaterialDarkColor.b, duration: 2 },
        "<"
      )
      .to(".mainProgressContainerNotConnected", { y: "30%", autoAlpha: 1, duration: 1 }, "<1")
      .to(".screen4", { autoAlpha: 1, duration: 0.5 }, "<0.5")
      .to(".screen4", { autoAlpha: 0, duration: 0.5 }, ">1.5")
      .addLabel("step4")

      //start game
      .to(floorBigCircle.rotation, { y: 1.570795 * 7, ease: "power3.out", duration: 2 }, ">-1")
      .to(interiorCircle.rotation, { y: 1.570795 * 32, ease: "power3.out", duration: 2 }, "<")
      .to(".levelProgressBar1", { width: "100%", background: "#34eb58", duration: 4 }, "<-0.5")
      .to(".mainProgressBarActive", { height: "8.5%", background: "#34eb58", duration: 4 }, "<")
      .to(".step2 .ExploreNav-diamond .outerDiamond", { opacity: 0, duration: 1 }, "<")
      .to(".step2 .ExploreNav-diamond .highlightDiamond", { opacity: 1, duration: 1 }, "<")
      .to(".step2 .ExploreNav-diamond .highlightDiamondOuter", { opacity: 0.5, duration: 1 }, "<")
      .to(".step2 .ExploreNav-diamond .innerDiamond", { opacity: 0, duration: 0.5 }, "<")
      .to(".step2 .ExploreNav-diamond span", { opacity: 1, duration: 0.5 }, ">")
      .to(".step2 .ExploreNav-lineProgress", { scaleY: 1, duration: 3 }, ">")
      .to(interiorCeilingBig.rotation, { z: 1.570795 * 20, ease: "power3.out", duration: 6 }, "<0.5")

      .to(interiorCeilingSmall.rotation, { y: 1.570795 * 75, ease: "power3.out", duration: 6 }, "<")

      .to(godRays.godRaysMaterial.uniforms.decay, { value: 1, duration: 2 }, "<1")

      .to([fallingLoopAction, fallingLoopAction2], { weight: 1, duration: 1 }, "<")

      .to([action, idlePlayersAction], { weight: 0, duration: 1 }, "<")
      .addLabel("step5", ">")
      .to([cyporg1.position, cyporg2.position, cyporg3.position, cyporg4.position], { x: 0, y: 2.9, z: 0, duration: 1 }, ">")
      .to(cyborgMain.position, { y: 1.07, z: 0.02, duration: 0.6 }, "<")
      .to(cyborgMain.position, { x: 2.8, duration: 1 }, "<")
      .to(cyborgMain.scale, { x: 1, y: 1, z: 1, duration: 1 }, "<")
      .to(cyborgMain.rotation, { x: 0.2, y: 2.5 /* 2.5 */, z: -0.5, duration: 1 }, "<")

      .to(camera, { fov: 90, duration: 1 }, "<")
      .to(materialMatCapWin, { opacity: 1, duration: 0.5 }, "<0.8")
      .to(godRays.godRaysMaterial.uniforms.decay, { value: 0.5, duration: 1 }, "<")
      .to(brightnessContrastEffect.uniforms.get("contrast"), { value: 0.05, duration: 1 }, "<")
      .addLabel("step6")
      .to(".levelCompleted1", { opacity: 1, duration: 0.5 }, "<")
      .to(
        proxy,
        {
          textContent: 10,
          snap: { textContent: 1 },
          duration: 0.5,
          onUpdate: function () {
            targetElement.textContent = Math.round(proxy.textContent);
          },
        },
        "<"
      )
      .to(".levels", { scrollTo: { y: ".level2", offsetY: 20 }, duration: 0.2 }, ">")
      .to(".screen4-2", { autoAlpha: 1, duration: 0.5 }, "<-0.5")
      .to(".screen4-2", { autoAlpha: 0, duration: 0.5 }, ">1.5")

      /// level 2
      .to(camera, { fov: 100, duration: 1 }, ">-1")
      .to(materialMatCapWin, { opacity: 0, duration: 0.5 }, "<")
      .to(godRays.godRaysMaterial.uniforms.decay, { value: 0.9, duration: 1 }, "<")
      .to(brightnessContrastEffect.uniforms.get("contrast"), { value: 0.2, duration: 1 }, "<")
      .to(
        goldLighterMaterialColor,
        { r: goldLighterMaterialLightColor.r, g: goldLighterMaterialLightColor.g, b: goldLighterMaterialLightColor.b, duration: 0.3 },
        "<"
      )
      .to(
        goldSmoothMaterialColor,
        { r: goldSmoothMaterialLightColor.r, g: goldSmoothMaterialLightColor.g, b: goldSmoothMaterialDarkColor.b, duration: 0.3 },
        "<"
      )
      .to(cyporg1.position, { x: -0.97, y: 0.1, z: 1, duration: 1 }, "<0.2")
      .to(cyporg2.position, { x: 0.35, y: 0.1, z: -1.2, duration: 1 }, "<")
      .to(cyporg3.position, { x: 1.3, y: 0.1, z: -0.32, duration: 1 }, "<")
      .to(cyborgMain.position, { x: 0.62, duration: 0.6 }, "<")
      .to(cyborgMain.position, { y: 0.1, z: 1.1, duration: 1 }, "<")
      .to(cyborgMain.scale, { x: 8, y: 8, z: 8, duration: 1 }, "<")
      .to(cyborgMain.rotation, { x: 0, y: -3, z: 0, duration: 1 }, "<")
      .to([fallingLoopAction, fallingLoopAction2], { weight: 0, duration: 1 }, ">")
      .to([action, idlePlayersAction], { weight: 1, duration: 1 }, "<")

      .to(floorBigCircle.rotation, { y: 1.570795 * 16, ease: "power3.out", duration: 2 }, ">0.5")
      .to(interiorCircle.rotation, { y: 1.570795 * 64, ease: "power3.out", duration: 2 }, "<")
      .to(".levelProgressBar2", { width: "100%", background: "#34eb58", duration: 4 }, "<")
      .to(".mainProgressBarActive", { height: "17%", background: "#34eb58", duration: 4 }, "<")
      .to(".step3 .ExploreNav-diamond .outerDiamond", { opacity: 0, duration: 1 }, "<-3.5")
      .to(".step3 .ExploreNav-diamond .highlightDiamond", { opacity: 1, duration: 1 }, "<")
      .to(".step3 .ExploreNav-diamond .highlightDiamondOuter", { opacity: 0.5, duration: 1 }, "<")
      .to(".step3 .ExploreNav-diamond .innerDiamond", { opacity: 0, duration: 0.5 }, "<")
      .to(".step3 .ExploreNav-diamond span", { opacity: 1, duration: 0.5 }, ">")
      .to(".step3 .ExploreNav-lineProgress", { scaleY: 1, duration: 6 }, ">")
      .to(interiorCeilingBig.rotation, { z: 1.570795 * 40, ease: "power3.out", duration: 6 }, "<3.5")
      .to(interiorCeilingSmall.rotation, { y: 1.570795 * 150, ease: "power3.out", duration: 6 }, "<")
      .to(godRays.godRaysMaterial.uniforms.decay, { value: 1, duration: 2 }, "<1")
      .to([fallingLoopAction, fallingLoopAction2], { weight: 1, duration: 1 }, "<")
      .to([action, idlePlayersAction], { weight: 0, duration: 1 }, "<")

      .to([cyporg1.position, cyporg2.position, cyporg3.position], { x: 0, y: 2.9, z: 0, duration: 1 }, ">")
      .to(cyborgMain.position, { x: -0.01, duration: 0.6 }, "<")
      .to(cyborgMain.position, { y: 1.07, z: 2.8, duration: 1 }, "<")

      .to(cyborgMain.scale, { x: 1, y: 1, z: 1, duration: 1 }, "<")
      .to(cyborgMain.rotation, { x: -0.2, y: 0.6, z: 0, duration: 1 }, "<")

      .to(camera, { fov: 90, duration: 1 }, "<")
      .to(materialMatCapWin, { opacity: 1, duration: 0.5 }, "<0.5")
      .to(".screen5", { autoAlpha: 1, duration: 0.5 }, "<")
      .to(".screen5", { autoAlpha: 0, duration: 0.5 }, ">1.5")
      .to(godRays.godRaysMaterial.uniforms.decay, { value: 0.5, duration: 1 }, "<-2")
      .to(brightnessContrastEffect.uniforms.get("contrast"), { value: 0.05, duration: 1 }, "<")
      .to(".levelCompleted2", { opacity: 1, duration: 0.5 }, "<")
      .to(
        proxy,
        {
          textContent: 30,
          snap: { textContent: 1 },
          duration: 0.5,
          onUpdate: function () {
            targetElement.textContent = Math.round(proxy.textContent);
          },
        },
        "<"
      )
      .to(".levels", { scrollTo: { y: ".level3", offsetY: 20 }, duration: 0.2 }, ">")

      /// level rest
      .to(camera, { fov: 100, duration: 1 }, ">")
      .to(materialMatCapWin, { opacity: 0, duration: 0.5 }, "<")
      .to(godRays.godRaysMaterial.uniforms.decay, { value: 0.9, duration: 1 }, "<")
      .to(brightnessContrastEffect.uniforms.get("contrast"), { value: 0.2, duration: 1 }, "<")
      .to(
        goldLocksMaterialColor,
        { r: goldLocksMaterialLightColor.r, g: goldLocksMaterialLightColor.g, b: goldLocksMaterialLightColor.b, duration: 2 },
        "<"
      )
      .to(cyporg1.position, { x: -0.97, y: 0.1, z: 1, duration: 1 }, "<0.2")
      .to(cyporg2.position, { x: 0.35, y: 0.1, z: -1.2, duration: 1 }, "<")
      .to(cyporg3.position, { x: 1.3, y: 0.1, z: -0.32, duration: 1 }, "<")
      .to(cyborgMain.position, { x: 0.62, duration: 1 }, "<")
      .to(cyborgMain.position, { y: 0.1, z: 1.1, duration: 0.9 }, "<")
      .to(cyborgMain.scale, { x: 8, y: 8, z: 8, duration: 1 }, "<")
      .to(cyborgMain.rotation, { x: 0, y: -3, z: 0, duration: 1 }, "<")
      .to([fallingLoopAction, fallingLoopAction2], { weight: 0, duration: 1 }, ">")
      .to([action, idlePlayersAction], { weight: 1, duration: 1 }, "<")

      .to(floorBigCircle.rotation, { y: 1.570795 * 25, ease: "power3.out", duration: 2 }, ">0.5")
      .to(interiorCircle.rotation, { y: 1.570795 * 96, ease: "power3.out", duration: 2 }, "<")
      .to(".levelProgressBar3", { width: "100%", background: "#34eb58", duration: 4 }, "<")
      .to(".mainProgressBarActive", { height: "25.5%", background: "#34eb58", duration: 4 }, "<")
      .to(".step4 .ExploreNav-diamond .outerDiamond", { opacity: 0, duration: 1 }, "<-3.5")
      .to(".step4 .ExploreNav-diamond .highlightDiamond", { opacity: 1, duration: 1 }, "<")
      .to(".step4 .ExploreNav-diamond .highlightDiamondOuter", { opacity: 0.5, duration: 1 }, "<")
      .to(".step4 .ExploreNav-diamond .innerDiamond", { opacity: 0, duration: 0.5 }, "<")
      .to(".step4 .ExploreNav-diamond span", { opacity: 1, duration: 0.5 }, ">")
      .to(".step4 .ExploreNav-lineProgress", { scaleY: 1, duration: 12 }, ">")
      .to(interiorCeilingBig.rotation, { z: 1.570795 * 60, ease: "power3.out", duration: 6 }, "<3.5")
      .to(interiorCeilingSmall.rotation, { y: 1.570795 * 225, ease: "power3.out", duration: 6 }, "<")
      .to(godRays.godRaysMaterial.uniforms.decay, { value: 0.95, duration: 2 }, "<1")
      .to([fallingLoopAction, fallingLoopAction2], { weight: 1, duration: 1 }, "<")
      .to([action, idlePlayersAction], { weight: 0, duration: 1 }, "<")

      .to(cyporg2.position, { x: 0, y: 2.9, z: 0, duration: 1 }, ">")

      .to(".levelCompleted3", { opacity: 1, duration: 0.5 }, ">")
      .to(
        proxy,
        {
          textContent: 70,
          snap: { textContent: 1 },
          duration: 0.5,
          onUpdate: function () {
            targetElement.textContent = Math.round(proxy.textContent);
          },
        },
        "<"
      )

      .to(".mainProgressContainerNotConnected", { y: 0, duration: 0.5 }, ">")

      .to(floorBigCircle.rotation, { y: 1.570795 * 25 * 2 + 2, ease: "power3.out", duration: 2 }, "<-0.5")
      .to(interiorCircle.rotation, { y: 1.570795 * 96 * 2, ease: "power3.out", duration: 2 }, "<")
      .to(interiorCeilingBig.rotation, { z: 1.570795 * 60 * 2, ease: "power3.out", duration: 6 }, ">")
      .to(interiorCeilingSmall.rotation, { y: 1.570795 * 225 * 2, ease: "power3.out", duration: 6 }, "<")

      .to(floorBigCircle.rotation, { y: 1.570795 * 25 * 6 + 4, ease: "power3.out", duration: 6 }, "<")
      .to(interiorCircle.rotation, { y: 1.570795 * 96 * 6, ease: "power3.out", duration: 6 }, "<")

      .to(cyporg1.position, { x: 0, y: 2.9, z: 0, duration: 2 }, "<")
      .to(cyporg3.position, { x: 0, y: 2.9, z: 0, duration: 2 }, ">2")

      .to(".levelProgressBar4", { width: "100%", background: "#34eb58", duration: 0.5 }, "<-5.5")

      .to(".levelCompleted4", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar5", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted5", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar6", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted6", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar7", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted7", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar8", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted8", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar9", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted9", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar10", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted10", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar11", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted11", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar12", { width: "100%", background: "#34eb58", duration: 0.5 }, ">")
      .to(".levelCompleted12", { opacity: 1, duration: 0.5 }, "<")
      .to(".levelProgressBar13", { width: "100%", background: "#34eb58", duration: 2 }, ">")
      .to(".levelCompleted13", { opacity: 1, duration: 1 }, "<1")

      .to(".mainProgressBarActive", { height: "85%", background: "#34eb58", duration: 8 }, "<-6.5")
      .to(
        proxy,
        {
          textContent: 81920,
          snap: { textContent: 1 },
          ease: "power1.in",
          duration: 7,
          onUpdate: function () {
            targetElement.textContent = numberWithCommas(Math.round(proxy.textContent));
          },
        },
        "<1.5"
      )
      .to(".levels", { scrollTo: { y: ".level13", offsetY: 50 }, duration: 4.5 }, "<-0.5")
      .to(".screen6", { autoAlpha: 1, duration: 0.5 }, ">")
      .to(".screen6", { autoAlpha: 0, duration: 0.5 }, ">1.8")
      .to(".step5 .ExploreNav-diamond .outerDiamond", { opacity: 0, duration: 1 }, "<-2.5")
      .to(".step5 .ExploreNav-diamond .highlightDiamond", { opacity: 1, duration: 1 }, "<")
      .to(".step5 .ExploreNav-diamond .highlightDiamondOuter", { opacity: 0.5, duration: 1 }, "<")
      .to(".step5 .ExploreNav-diamond .innerDiamond", { opacity: 0, duration: 0.5 }, "<")
      .to(".step5 .ExploreNav-diamond span", { opacity: 1, duration: 0.5 }, ">")

      .to(instanceMesh.scale, { x: 0.05, y: 0.05, z: 0.05, duration: 0.5 }, "<-0.5")
      .to(instanceTween, { progress: 1, ease: "power3.in", duration: 6 }, "<")

      .to(camera.position, { x: 0.039, y: 2.998, duration: 0.1 }, ">-3")
      .to(camera.position, { z: 17.479, duration: 2 }, "<")
      .to(camera.position, { x: -1.264, y: 3.671, duration: 2 }, "<0.2")
      .to(camera.rotation, { x: 0.345, y: 0, z: 0, duration: 2 }, "<")
      .to(camera, { fov: 85, duration: 2 }, "<")
      .to(".StickyOverlay", { xPercent: -50, duration: 2 }, "<")
      .to(".mainProgressContainerNotConnected", { y: "100%", autoAlpha: 0, duration: 2 }, "<")

      .set(floorBigCircle.rotation, { y: 0 }, "<0.3")
      .set(cyborgMain.position, { x: -1.32, y: 1.296, z: 16.255 }, "<-0.1")
      .set(cyborgMain.rotation, { x: 0, y: 0.752, z: 0 }, "<")
      .set([fallingLoopAction, action], { weight: 0 }, "<")
      .set(idlePlayerAction, { weight: 1 }, "<")
      .to(brightnessContrastEffect.uniforms.get("contrast"), { value: 0.05, duration: 1 }, "<")
      .to(".screen7", { autoAlpha: 1, duration: 0.5 }, "<1.5")
      .to(".scrollDownIcon", { autoAlpha: 0, duration: 0.5 }, "<")
      .to(".policy", { autoAlpha: 1, duration: 0.5 }, "<");

    //#endregion

    notConnectedTrigger = ScrollTrigger.getById("notConnected");
    notConnectedTrigger.disable();
    //Asset loaded
    const event = new Event("assetsLoaded");
    console.log("Dispatching assetsLoaded event");

    //#region State Management Timelines
    handleWalletNotConnectedTimeline = gsap.timeline({ onStart: enableScrollTrigger, paused: true });
    handleWalletNotConnectedTimeline
      //.set("body", { overflowY: "scroll", delay: 0.3 })
      .set("body", { overflowY: "scroll" })
      .set(".connectedTokenHeader", { autoAlpha: 0, display: "none" }, "<")
      .set(".headerWalletAdress", { autoAlpha: 0, display: "none" }, "<")
      .set(".addressAvataerCont", { display: "block" }, "<")
      .set(".tokensContainer", { autoAlpha: 0 }, "<")
      .set(".connectedHeader", { backgroundColor: "#ffffff00", backdropFilter: "blur(0px)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0)" }, "<")
      //.set(".hero", { autoAlpha: 1 }, "<")
      //.to(window, { scrollTo: 0, duration: 0 }, ">0.1")
      .to(".hero", { autoAlpha: 1, duration: 0.6 }, "<")
      .to(".counterAndBtnCont", { autoAlpha: 1, duration: 0.6 }, "<")
      .to(camera.position, { x: 0, y: 7.65, z: 6.105, duration: 0.2 }, "<")
      .to(camera.rotation, { x: -0.25, y: 0, z: 0, duration: 0.2 }, "<")
      .to(camera, { fov: 85, duration: 0.2 }, "<")
      .set(".mainProgressContainerConnected", { autoAlpha: 0, y: "100%", duration: 0.2 }, "<")
      .to(".tokensContainer", { x: "110%", duration: 0.2 }, "<")
      .to(".connectLeft", { autoAlpha: 0, duration: 0.2 }, "<")
      .set(".mintBtn", { autoAlpha: 0, duration: 0.2 }, "<");
    //.to(".hero", { autoAlpha: 1, duration: 0.2 }, ">");

    handleOtherStatesTimeline = gsap.timeline({ onStart: disbleScrollTrigger, paused: true });
    handleOtherStatesTimeline
      .set("body", { overflowY: "hidden" }, ">0.2")
      .set(".connectedTokenHeader", { display: "flex" }, "<")
      .set(".headerWalletAdress", { display: "flex" }, "<")
      .set(".addressAvataerCont", { display: "none" }, "<")
      .to(".connectLeft", { autoAlpha: 1, duration: 0.2 }, ">")
      // .to(".tokensContainer", { x: 0, duration: 0.2 }, "<")
      // .set(".tokensContainer", { autoAlpha: 1 }, "<")
      .set(
        ".connectedHeader",
        { backgroundColor: "#ffffff1a", backdropFilter: "blur(5.2px)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)" },
        "<"
      )
      .set(".connectedTokenHeader", { display: "flex" }, "<")
      .set(".headerWalletAdress", { display: "flex" }, "<")
      .set(".addressAvataerCont", { display: "none" }, "<")
      .set(".hero", { autoAlpha: 0 }, "<")
      .set(".counterAndBtnCont", { autoAlpha: 0 }, "<")
      .to(".connectedTokenHeader", { autoAlpha: 1, duration: 0.2 }, "<")
      .to(".headerWalletAdress", { autoAlpha: 1, duration: 0.2 }, "<");
    //.to(".hero", { autoAlpha: 0, duration: 0.2 }, "<");
    //.to(".mintBtn", {autoAlpha: 1, duration: 0.2}, "<");

    noTokensOnlyRefStateTimeline = gsap.timeline({ paused: true, delay: 0.6 });
    noTokensOnlyRefStateTimeline
      .set(cyborgMain.position, { x: -0.124, y: 0.51, z: 5.28 }, "<")
      .set(cyborgMain.rotation, { x: 0, y: 0, z: 0 }, "<")
      // .to(".mainProgressContainerConnected", { y: "100%", duration: 1 }, "<")
      // .set(".mainProgressContainerConnected", { autoAlpha: 1 }, "<")
      .to(camera.position, { x: 0.03, y: 2.8, z: 7.1, duration: 1 })
      .to(camera.rotation, { x: 0.3, y: 0, z: 0, duration: 1 }, "<")
      .to(camera, { fov: 85, duration: 1 }, "<")

      .to(
        goldLocksMaterialColor,
        { r: goldLocksMaterialLightColor.r, g: goldLocksMaterialLightColor.g, b: goldLocksMaterialLightColor.b, duration: 1 },
        "<"
      )
      .to(
        goldLighterMaterialColor,
        { r: goldLighterMaterialLightColor.r, g: goldLighterMaterialLightColor.g, b: goldLighterMaterialLightColor.b, duration: 1 },
        "<"
      )
      .to(
        goldSmoothMaterialColor,
        { r: goldSmoothMaterialLightColor.r, g: goldSmoothMaterialLightColor.g, b: goldSmoothMaterialLightColor.b, duration: 1 },
        "<"
      )
      .to(".tokensContainer", { x: 0, duration: 0.2 }, "<")
      .set(".tokensContainer", { autoAlpha: 1 }, "<")
      .to(".mainProgressContainerConnected", { y: "61%", autoAlpha: 1, duration: 1 }, ">0.7")
      // .to(".mintBtn", { bottom: "15vh", autoAlpha: 1, duration: 0.5, display: "block" }, "<");
      .to(".mintBtn", { bottom: "30vh", autoAlpha: 1, duration: 0.7 }, "<0.3"); //TODO remove after launch

    inGameStateTimeline = gsap.timeline({ paused: true, delay: 0.6 });
    inGameStateTimeline
      .set(cyborgMain.position, { x: 0, y: 0.165, z: 0 }, "<")
      .set(cyborgMain.rotation, { x: 0, y: 0, z: 0 }, "<")
      .set(coinMeshh, { visible: false }, "<")
      .set(".mainProgressContainerConnected", { autoAlpha: 1 }, "<")
      .to(camera.position, { x: 0.03, y: 2.8, z: 7.1, duration: 1 })
      .to(camera.rotation, { x: 0.3, y: 0, z: 0, duration: 1 }, "<")
      .to(camera, { fov: 85, duration: 1 }, "<")

      .to(camera.position, { x: 0.13, y: 2.53, z: 1.4, duration: 1 })
      .to(camera.rotation, { x: 0.031, y: 0.1, z: 0, duration: 1 }, "<")
      .to(camera, { fov: 60, duration: 1 }, "<")
      .to(
        goldLocksMaterialColor,
        { r: goldLocksMaterialDarkColor.r, g: goldLocksMaterialDarkColor.g, b: goldLocksMaterialDarkColor.b, duration: 1 },
        "<"
      )
      .to(
        goldLighterMaterialColor,
        { r: goldLighterMaterialDarkColor.r, g: goldLighterMaterialDarkColor.g, b: goldLighterMaterialDarkColor.b, duration: 1 },
        "<"
      )
      .to(
        goldSmoothMaterialColor,
        { r: goldSmoothMaterialDarkColor.r, g: goldSmoothMaterialDarkColor.g, b: goldSmoothMaterialDarkColor.b, duration: 1 },
        "<"
      )
      .to(".tokensContainer", { x: 0, duration: 1 }, "<")
      .set(".tokensContainer", { autoAlpha: 1 }, "<")
      .to(".mainProgressContainerConnected", { y: "61%", duration: 1 }, "<")
      // .to(".mintBtn", { bottom: "30vh", autoAlpha: 1, display: "flex", duration: 0.7 }, "<0.3");
      .to(".mintBtn", { bottom: "30vh", autoAlpha: 1, duration: 0.7 }, "<0.3"); //TODO remove after launch

    //#endregion
    window.dispatchEvent(event);
  };
};

//#region base rest

/**
 * Sizes
 */
let sizes;
let pixelLimit;

function resizeCanvasToDisplaySize() {
  let isMobile = window.matchMedia("(max-device-width: 476px) and (orientation: portrait) and (hover: none)").matches;
  let isNoneHover = window.matchMedia("(hover: none)").matches;

  //update sizes
  if (isMobile) {
    sizes = {
      width: window.innerWidth,
      height: window.screen.height,
    };
    pixelLimit = 1.5;
  } else if (isNoneHover) {
    sizes = {
      width: window.innerWidth,
      height: document.body.scrollHeight,
    };
    pixelLimit = 1.5;
  } else {
    sizes = {
      width: window.innerWidth,
      height: document.body.scrollHeight,
    };
    pixelLimit = 1;
  }
  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  // Update camera
  camera.aspect = sizes.width / sizes.height;

  camera.updateProjectionMatrix();
  // Update effect composer
  composer.setSize(sizes.width, sizes.height);
}
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  powerPreference: "high-performance",
  antialias: false,
  //alpha: true,
  stencil: false,
  depth: false,
});

renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;
renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.useLegacyLights = false;
renderer.autoClear = false;
renderer.toneMapping = THREE.LinearToneMapping;
renderer.toneMappingExposure = 2.5;

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(85, 1, 0.04, 25);
camera.rotation.order = "YXZ";
camera.position.set(0, 7.65, 6.105);
camera.rotation.set(-0.25, 0, 0);
scene.add(camera);
const cameraFolder = gui.addFolder("camera");
cameraFolder.add(camera, "fov").min(50).max(110).step(0.001);
cameraFolder.add(camera, "far").min(1).max(20).step(0.001);
cameraFolder.add(camera, "near").min(0.01).max(0.1).step(0.001);
cameraFolder.add(camera.position, "x").min(-20).max(30).step(0.001);
cameraFolder.add(camera.position, "y").min(-20).max(30).step(0.001);
cameraFolder.add(camera.position, "z").min(-20).max(50).step(0.001);
cameraFolder.add(camera.rotation, "x").min(-20).max(30).step(0.001);
cameraFolder.add(camera.rotation, "y").min(-20).max(30).step(0.001);
cameraFolder.add(camera.rotation, "z").min(-20).max(30).step(0.001);
cameraFolder.close();

renderer.compile(scene, camera);

// godray bat logo
let circleGeo = new THREE.PlaneGeometry(1, 1);
let circleMat = new THREE.MeshBasicMaterial({ color: 0xffccaa });
let circle = new THREE.Mesh(circleGeo, circleMat);
circle.position.set(0, 4.867, 1);
circle.rotation.set(1.559, 0, 0);
circle.scale.set(2, 2);
scene.add(circle);

//plane win
let circleWinMat = new THREE.MeshBasicMaterial({ color: 0xffccaa });
circleWinMat.transparent = true;
circleWinMat.opacity = 0;
circleWinMat.depthWrite = false;
let circlewin = new THREE.Mesh(circleGeo, materialMatCapWin);
circlewin.position.set(0.021, 3, 3.595);
circlewin.scale.set(2, 2);
scene.add(circlewin);
gui.add(circlewin.position, "x").min(-3).max(10).step(0.001).name("x plane win");
gui.add(circlewin.position, "y").min(-3).max(10).step(0.001).name("y plane win");
gui.add(circlewin.position, "z").min(-3).max(10).step(0.001).name("z plane win");

// godray
const godRays = new GodRaysEffect(camera, circle, {
  blurriness: 1,
  density: 1,
  decay: 0.9,
  weight: 1,
  exposure: 0.2,
  samples: 35,
  clampMax: 1,
});

// blooom
const bloomTable = new SelectiveBloomEffect(scene, camera, {
  luminanceThreshold: 0.1,
  intensity: 0.5,
});

const normalPass = new NormalPass(scene, camera);

const smaaEffect = new SMAAEffect(SMAAPreset.ULTRA);

const ssaoEffect = new SSAOEffect(camera, normalPass.texture, {
  blendFunction: BlendFunction.SKIP,
  distanceScaling: true,
  depthAwareUpsampling: false,
  //normalDepthBuffer,
  samples: 9,
  rings: 7,
  distanceThreshold: 0.1737, // Render up to a distance of ~20 world units
  distanceFalloff: 0.0025, // with an additional ~2.5 units of falloff.
  rangeThreshold: 0.003, // Occlusion proximity of ~0.3 world units
  rangeFalloff: 0.0001, // with ~0.1 units of falloff.
  luminanceInfluence: 0.7,
  minRadiusScale: 0.33,
  radius: 0.1,
  intensity: 1.33, //1.33,
  bias: 0.015,
  fade: 0.01,
  color: null,
  resolutionScale: 0.5,
});

const brightnessContrastEffect = new BrightnessContrastEffect({
  brightness: 0.02,
  contrast: 0.05,
  blendFunction: BlendFunction.SRC,
});

let renderPasss = new RenderPass(scene, camera);
let effectPass = new EffectPass(camera, smaaEffect, bloomTable, godRays, brightnessContrastEffect);

const composer = new EffectComposer(renderer, { frameBufferType: HalfFloatType });
composer.addPass(renderPasss);

composer.addPass(effectPass);
//#endregion

//#region //debug
const blendMode = ssaoEffect.blendMode;
const uniforms = ssaoEffect.ssaoMaterial.uniforms;
const params = {
  distance: {
    threshold: uniforms.distanceCutoff.value.x,
    falloff: uniforms.distanceCutoff.value.y - uniforms.distanceCutoff.value.x,
  },
  proximity: {
    threshold: uniforms.proximityCutoff.value.x,
    falloff: uniforms.proximityCutoff.value.y - uniforms.proximityCutoff.value.x,
  },
  upsampling: {
    enabled: ssaoEffect.defines.has("DEPTH_AWARE_UPSAMPLING"),
    threshold: Number(ssaoEffect.defines.get("THRESHOLD")),
  },
  distanceScaling: {
    enabled: ssaoEffect.distanceScaling,
    "min scale": uniforms.minRadiusScale.value,
  },
  "lum influence": ssaoEffect.uniforms.get("luminanceInfluence").value,
  intensity: uniforms.intensity.value,
  bias: uniforms.bias.value,
  fade: uniforms.fade.value,

  resolution: ssaoEffect.resolution.scale,
  color: 0x000000,
  opacity: blendMode.opacity.value,
  "blend mode": blendMode.blendFunction,

  brightnessContrast: {
    brightness: brightnessContrastEffect.uniforms.get("brightness").value,
    contrast: brightnessContrastEffect.uniforms.get("contrast").value,
    opacity: brightnessContrastEffect.blendMode.opacity.value,
    "blend mode": brightnessContrastEffect.blendMode.blendFunction,
  },
  resolution: godRays.height,
  blurriness: godRays.blurPass.kernelSize + 1,
  density: godRays.godRaysMaterial.uniforms.density.value,
  decay: godRays.godRaysMaterial.uniforms.decay.value,
  weight: godRays.godRaysMaterial.uniforms.weight.value,
  exposure: godRays.godRaysMaterial.uniforms.exposure.value,
  clampMax: godRays.godRaysMaterial.uniforms.clampMax.value,
  samples: godRays.samples,
  color: circle.material.color.getHex(),
  opacity: blendMode.opacity.value,
  "blend mode": blendMode.blendFunction,
};

const godrayssettings = gui.addFolder("godRays Settings");
godrayssettings.add(params, "density", 0.0, 1.0, 0.01).onChange((value) => {
  godRays.godRaysMaterial.uniforms.density.value = value;
});

godrayssettings.add(params, "decay", 0.0, 1.0, 0.01).onChange((value) => {
  godRays.godRaysMaterial.uniforms.decay.value = value;
});

godrayssettings.add(params, "weight", 0.0, 1.0, 0.01).onChange((value) => {
  godRays.godRaysMaterial.uniforms.weight.value = value;
});

godrayssettings.add(params, "exposure", 0.0, 1.0, 0.01).onChange((value) => {
  godRays.godRaysMaterial.uniforms.exposure.value = value;
});

godrayssettings.add(params, "clampMax", 0.0, 1.0, 0.01).onChange((value) => {
  godRays.godRaysMaterial.uniforms.clampMax.value = value;
});

godrayssettings.add(godRays, "samples", 15, 200, 1);

godrayssettings.addColor(params, "color").onChange((value) => {
  circle.material.color.setHex(value);
});

const brightness = gui.addFolder("Brightness & Contrast");

brightness.add(params.brightnessContrast, "brightness", -1.0, 1.0, 0.001).onChange((value) => {
  brightnessContrastEffect.uniforms.get("brightness").value = value;
});

brightness.add(params.brightnessContrast, "contrast", -1.0, 1.0, 0.001).onChange((value) => {
  brightnessContrastEffect.uniforms.get("contrast").value = value;
});

brightness.add(params.brightnessContrast, "opacity", 0.0, 1.0, 0.01).onChange((value) => {
  brightnessContrastEffect.blendMode.opacity.value = value;
});

brightness.add(params.brightnessContrast, "blend mode", BlendFunction).onChange((value) => {
  brightnessContrastEffect.blendMode.setBlendFunction(Number(value));
});
const ssaoFolder = gui.addFolder("ssao");
ssaoFolder.add(params, "resolution", 0.25, 1.0, 0.25).onChange((value) => {
  ssaoEffect.resolution.scale = value;
  depthDownsamplingPass.resolution.scale = value;
});

ssaoFolder.add(ssaoEffect, "samples", 1, 32, 1);
ssaoFolder.add(ssaoEffect, "rings", 1, 16, 1);
ssaoFolder.add(ssaoEffect, "radius", 1e-6, 1.0, 0.001);

ssaoFolder.add(params.distanceScaling, "enabled").onChange((value) => {
  ssaoEffect.distanceScaling = value;
});

ssaoFolder.add(params.distanceScaling, "min scale", 0.0, 1.0, 0.001).onChange((value) => {
  uniforms.minRadiusScale.value = value;
});

ssaoFolder.add(params.distance, "threshold", 0.0, 1.0, 0.0001).onChange((value) => {
  ssaoEffect.setDistanceCutoff(value, params.distance.falloff);
});

ssaoFolder.add(params.distance, "falloff", 0.0, 1.0, 0.0001).onChange((value) => {
  ssaoEffect.setDistanceCutoff(params.distance.threshold, value);
});

ssaoFolder.add(params.proximity, "threshold", 0.0, 0.01, 0.0001).onChange((value) => {
  ssaoEffect.setProximityCutoff(value, params.proximity.falloff);
});

ssaoFolder.add(params.proximity, "falloff", 0.0, 0.01, 0.0001).onChange((value) => {
  ssaoEffect.setProximityCutoff(params.proximity.threshold, value);
});

ssaoFolder.add(params, "bias", 0.0, 1.0, 0.001).onChange((value) => {
  uniforms.bias.value = value;
});

ssaoFolder.add(params, "fade", 0.0, 1.0, 0.001).onChange((value) => {
  uniforms.fade.value = value;
});

ssaoFolder.add(params, "lum influence", 0.0, 1.0, 0.001).onChange((value) => {
  ssaoEffect.uniforms.get("luminanceInfluence").value = value;
});

ssaoFolder.add(params, "intensity", 1.0, 4.0, 0.01).onChange((value) => {
  ssaoEffect.ssaoMaterial.intensity = value;
});

ssaoFolder.addColor(params, "color").onChange((value) => {
  ssaoEffect.color = value === 0x000000 ? null : color.setHex(value).convertSRGBToLinear();
});

ssaoFolder.add(params, "opacity", 0.0, 1.0, 0.001).onChange((value) => {
  blendMode.opacity.value = value;
});

ssaoFolder.add(params, "blend mode", BlendFunction).onChange((value) => {
  blendMode.setBlendFunction(Number(value));
});
//#endregion

const instanceTween = gsap.to(
  {},
  {
    duration: 1,
    paused: true,
    onUpdate: function () {
      for (let i = 0; i < 50; i++) {
        animateInstance(i, this.progress());
      }
    },
  }
);

function animateInstance(index, progress) {
  const matrix = new THREE.Matrix4();
  const spacingMultiplier = 2; // Adjust the spacing between coins
  const normalizedProgress = progress * (index + 1) * spacingMultiplier;
  const position = new THREE.Vector3(1, 50, 1).multiplyScalar(normalizedProgress);
  matrix.setPosition(position);

  const rotationAngle = index * 35 + 0; // Start with rotation 0 and then rotate

  const rotationMatrix = new THREE.Matrix4().makeRotationZ(THREE.MathUtils.degToRad(rotationAngle));
  const finalMatrix = matrix.premultiply(rotationMatrix);

  instanceMesh.setMatrixAt(index, finalMatrix);
  instanceMesh.instanceMatrix.needsUpdate = true;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
/**
 * clock tick
 */
let clock = new THREE.Clock();
const tick = () => {
  // stats
  stats.begin();
  resizeCanvasToDisplaySize();

  if (coinMeshh) {
    coinMeshh.rotation.y += 0.01;
    instanceMesh.rotation.y += 0.01;
  }

  let delta = clock.getDelta();
  if (mixer) mixer.update(delta);
  if (mixer2) mixer2.update(delta);

  // Render
  composer.render();

  // stats
  stats.end();

  window.requestAnimationFrame(tick);
};
tick();

//generat levels not connected
function createLevels() {
  var container = document.querySelector(".mainProgressContainerNotConnected .levels");

  for (var i = 0; i <= 13; i++) {
    var levelContainer = document.createElement("div");
    //levelContainer.className = "level" + i + " level";
    levelContainer.className = "level" + (i + 1) + " level";

    var levelProgress = document.createElement("div");
    levelProgress.className = "levelProgress";

    var levelInfo = document.createElement("div");
    levelInfo.className = "levelInfo";
    //levelInfo.textContent = "LEVEL " + i;
    if (i === 13) {
      levelInfo.textContent = "Total Earnings";
    } else {
      levelInfo.textContent = "LEVEL " + i;
    }

    var levelCompleted = document.createElement("div");
    //levelCompleted.className = "levelCompleted levelCompleted" + i;
    levelCompleted.className = "levelCompleted levelCompleted" + (i + 1);

    levelCompleted.textContent = "COMPLETE";

    var levelProgressBar = document.createElement("div");
    levelProgressBar.className = "levelProgressBar";

    var levelProgressBarGray = document.createElement("div");
    levelProgressBarGray.className = "levelProgressBarGray miniLevelProgressBar";

    var levelProgressBarActive = document.createElement("div");
    // levelProgressBarActive.className = "levelProgressBarActive miniLevelProgressBar levelProgressBar" + i;
    levelProgressBarActive.className = "levelProgressBarActive miniLevelProgressBar levelProgressBar" + (i + 1);

    var levelPrize = document.createElement("div");
    levelPrize.className = "levelPrize";
    levelPrize.textContent = "$" + numberWithCommas(10 * Math.pow(2, i)); // Add thousand separator

    levelProgressBar.appendChild(levelProgressBarGray);
    levelProgressBar.appendChild(levelProgressBarActive);

    levelProgress.appendChild(levelInfo);
    levelProgress.appendChild(levelCompleted);
    levelProgress.appendChild(levelProgressBar);

    levelContainer.appendChild(levelProgress);
    levelContainer.appendChild(levelPrize);

    container.appendChild(levelContainer);
  }
  const tokenSpaceDiv3 = document.createElement("div");
  tokenSpaceDiv3.className = "emptySpace";
  container.appendChild(tokenSpaceDiv3);
}

window.onload = createLevels;

//#region app states

var style = document.createElement("style");
document.head.appendChild(style);
function enableScrollTrigger() {
  toggleHammerEvents(false);
  window.scrollTo(0, -window.innerHeight);
  notConnectedTrigger.enable(true);
  gsap.set(".scrollbutton", { autoAlpha: 1 });
  gsap.to(".scrollDownIcon", { autoAlpha: 1, duration: 0.5 });

  style.innerHTML = `
  @media only screen and (orientation: landscape) {
    .mintBtn {
      left: unset;
      right: 2.5%;
    }
  }
`;
}
function disbleScrollTrigger() {
  window.scrollTo(0, -window.innerHeight);
  notConnectedTrigger.disable(true);
  toggleHammerEvents(true);
  gsap.set(".scrollbutton", { autoAlpha: 0 });
  // Draggable.create(".mintBtn", {
  //   bounds: "body",
  //   zIndexBoost: false,
  // });
  style.innerHTML = `
  @media only screen and (orientation: landscape) {
    .mintBtn {
      left: 2.5%;
      right: unset;
    }
  }
`;
}
const rotateLogoTimeline = gsap.timeline({ paused: true, repeat: 3 });
rotateLogoTimeline.to(".chainImage div img", { rotate: 360, ease: "none", duration: 0.5 });

async function handleAppStateChange(currentState) {
  console.log(`Current state: ${currentState}`);
  rotateLogoTimeline.restart();

  switch (currentState) {
    case "walletNotConnected":
      // State 1: Wallet not connected
      console.log("Wallet is not connected");

      handleWalletNotConnectedTimeline.invalidate();
      handleWalletNotConnectedTimeline.restart();
      handleOtherStatesTimeline.pause();
      inGameStateTimeline.pause();
      noTokensOnlyRefStateTimeline.pause();
      break;
    case "noTokens":
      // State 2: Wallet connected, user doesn't have any tokens
      console.log("Wallet is connected, but user does not have any tokens");

      handleWalletNotConnectedTimeline.pause();
      handleOtherStatesTimeline.invalidate();
      handleOtherStatesTimeline.restart();

      inGameStateTimeline.pause();
      noTokensOnlyRefStateTimeline.invalidate();
      noTokensOnlyRefStateTimeline.restart();

      break;
    case "referrerTokenNoInd":
      // State 3: Wallet connected, user has a Referrer token, but referralCount < 3, and doesn't has Independent token, user can't enter the game
      console.log("Wallet is connected, user has a Referrer token but needs additional referrals");
      // const referrerToken = await findTokenByType("Referrer", true);
      // const remainingReferrals = 3 - referrerToken.referralCount;
      // console.log("remainingReferrals: ", remainingReferrals);

      handleWalletNotConnectedTimeline.pause();
      handleOtherStatesTimeline.invalidate();
      handleOtherStatesTimeline.restart();

      inGameStateTimeline.pause();
      noTokensOnlyRefStateTimeline.invalidate();
      noTokensOnlyRefStateTimeline.restart();

      break;
    case "inGame":
      // State 4: Wallet connected, user has Independent token, can enter game
      console.log("Wallet is connected, user has Independent token, can enter game");

      handleWalletNotConnectedTimeline.pause();
      handleOtherStatesTimeline.invalidate();
      handleOtherStatesTimeline.restart();
      noTokensOnlyRefStateTimeline.pause();
      inGameStateTimeline.invalidate();
      inGameStateTimeline.restart();

      break;
    default:
      console.log(`Current state: ${currentState}`);
  }
}
window.addEventListener("assetsLoaded", () => {
  // When the assets are loaded, subscribe to state changes
  console.log("Assets loaded: Fire StateManager");
  StateManager.subscribe(handleAppStateChange);
});

subscribeModal(
  async () => {
    // Logic for when the modal opens.
    console.log("Modal opened.");
    const currentState = await StateManager.getAppState();

    if (currentState === "inGame") {
      console.log("Modal opened and the application state is inGame");
      // Add logic for when the modal opens and the app state is inGame
      //gsap.to ('.mainProgressContainerConnected', {y: "100%", duration:1})
    }
    if (currentState === "walletNotConnected") {
      console.log("Modal opened and the app state is walletNotConnected");
      // Add logic for when the modal opens and the app state is walletNotConnected
      //gsap.to ('.mainProgressContainerNotConnected', {y: "100%", duration:1})
    }
  },
  async () => {
    // Logic for when the modal closes.
    console.log("Modal closed.");
    const currentState = await StateManager.getAppState();

    if (currentState === "inGame") {
      console.log("Modal closed and the application state is inGame");
      // Add logic for when the modal closes and the app state is inGame
      //gsap.to ('.mainProgressContainerConnected', {y: "50%", duration:1})
    }
    if (currentState === "walletNotConnected") {
      console.log("Modal closed and the app state is walletNotConnected");
      // Add logic for when the modal closes and the app state is walletNotConnected
      //gsap.to ('.mainProgressContainerNotConnected', {y: "0%", duration:1})
    }
  }
);

//#endregion

//UP and DOWN arrows
const progressMarkers = [
  0, 0.06928181108508977, 0.1861176164454853, 0.2851938589643508, 0.35974499089253187, 0.46747332812906583, 0.6200884725474889,
  0.8896044756700499, 1,
];
let currentPositionIndex = 0; // Start at the first position
let clickCountUp = 0;
let clickCountDown = 0;
let clickTimeout;

function handleScroll(direction) {
  clearTimeout(clickTimeout); // Clear any existing timeout

  if (direction === "up") {
    clickCountUp++;
  } else if (direction === "down") {
    clickCountDown++;
  }

  clickTimeout = setTimeout(() => {
    let steps;

    if (direction === "up") {
      steps = clickCountUp;
      clickCountUp = 0; // Reset counter
    } else if (direction === "down") {
      steps = clickCountDown;
      clickCountDown = 0; // Reset counter
    }

    // Check the boundary for the down direction
    if (direction === "down" && currentPositionIndex + steps < progressMarkers.length) {
      currentPositionIndex += steps;
    } else if (direction === "down") {
      // If more steps than available, go to the end
      currentPositionIndex = progressMarkers.length - 1;
    }

    // Check the boundary for the up direction
    if (direction === "up" && currentPositionIndex - steps >= 0) {
      currentPositionIndex -= steps;
    } else if (direction === "up") {
      // If more steps than available, go to the start
      currentPositionIndex = 0;
    }

    const targetProgress = progressMarkers[currentPositionIndex];
    const targetScroll = notConnectedTrigger.start + targetProgress * (notConnectedTrigger.end - notConnectedTrigger.start);

    gsap.to(window, {
      scrollTo: targetScroll,
      duration: 1,
    });
  }, 250); // Wait 250ms after the last click
}

document.querySelector(".scrollDownIcon").addEventListener("click", () => handleScroll("down"));
document.querySelector(".scrollUpIcon").addEventListener("click", () => handleScroll("up"));

function scrollToTop() {
  gsap.to(window, {
    scrollTo: 0,
    duration: 1,
  });
}
document.querySelector(".goldPesaLogo img").addEventListener("click", scrollToTop);

export const disconnectTimeline = gsap.timeline({ paused: true, delay: 0.6 });
disconnectTimeline
  .to(camera.position, { x: 0.03, y: 2.8, z: 7.1, duration: 1 })
  .to(camera.rotation, { x: 0.3, y: 0, z: 0, duration: 1 }, "<")
  .to(camera, { fov: 85, duration: 1 }, "<");
