import { findTokenByType, getTotalReferrals } from "./tokens.js";

class StateManager {
  constructor() {
    if (StateManager._instance) {
      return StateManager._instance;
    }

    StateManager._instance = this;

    this.isWalletConnected = false;
    this.observers = [];
  }

  setWalletConnected(value) {
    this.isWalletConnected = value;
  }

  async getAppState() {
    if (!this.isWalletConnected) return "walletNotConnected";

    const hasAnyTokens = await findTokenByType();
    if (!hasAnyTokens) return "noTokens";

    const referralToken = await findTokenByType("Referrer");
    // const hasReferrerToken = Boolean(referralToken);
    const referralCount = getTotalReferrals();
    // console.log("referralCount in app state:", referralCount);
    // const referralCount = hasReferrerToken ? referralToken.referralCount : 0;
    const hasIndependentToken = await findTokenByType("Independent");
    if (referralToken && referralCount < 3 && !hasIndependentToken) {
      return "referrerTokenNoInd";
    } else {
      return "inGame";
    }
  }

  resetWalletConnection() {
    this.setWalletConnected(false);
  }

  async notifyStateChange() {
    // Get the current state
    const currentState = await this.getAppState();

    // Notify the observers of the state change
    this.observers.forEach((observer) => observer(currentState));
  }

  subscribe(observer) {
    if (typeof observer === "function") {
      this.observers.push(observer);
    } else {
      console.error("Observer must be a function");
    }
  }
}

const stateManager = new StateManager();
export default stateManager;
