import moment from "moment";
import { gsap } from "gsap";
import { getTotalPrize } from "./tokens.js";

let currentTransactionsIndex = 0;

let transactionsParams = {
  MINTED: {},
  ASCENSION: {},
  /* CASHOUT: {
      /////////////
      messageTitle: "Cashed Out",
      messageBody: "getTotalPrize(eventData.metadata.level)",
    }, */
  STAKE: {},
};

const transactionsContainer = document.querySelector(".transactionscardsContent");
const seeMoreButton = document.querySelector(".transactionloadMore");
const baseURL = "https://api-gpm.goldpesa.com";
const lastNRecords = 50;
let transactionsSortedEvents = [];
const transactionsSkeletonTl = gsap.timeline({
  paused: true,
});
transactionsSkeletonTl.to(".transactionsSkeleton", { autoAlpha: 1, duration: 0.5 });
export async function getTokenTransactions(token) {
  transactionsSkeletonTl.play();
  resetTokenTransactions();

  try {
    const tokenId = token.tokenId;
    const response = await fetch(`${baseURL}/token_history?id=${tokenId}&lastNRecords=${lastNRecords}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    console.log("transactions data :", data);
    if (!data.events || data.events.length === 0) {
      console.log("No transactions events data received");
      return;
    }
    transactionsSortedEvents = data.events.sort((a, b) => moment(b.date).diff(moment(a.date)));
    console.log("transactionsSortedEvents :", transactionsSortedEvents);

    await loadTransaction(currentTransactionsIndex, 5, token);
    gsap.delayedCall(0.5, () => transactionsSkeletonTl.reverse());
  } catch (error) {
    console.error("Error fetching token history:", error);
  }
}
export function resetTokenTransactions() {
  currentTransactionsIndex = 0;
  transactionsContainer.innerHTML = "";
  seeMoreButton.style.display = "none";
  gsap.to(".transactionMenuScreen", { scrollTo: { y: 0, autoKill: true }, duration: 1, ease: "power2" });
}
function convertDateFormat(inputDate) {
  const parsedDate = moment(inputDate);

  return parsedDate.format("YYYY-MM-DD HH:mm:ss");
}

async function createTransactionsDiv(eventData, token) {
  const params = transactionsParams[eventData.type];
  if (!params) return null;

  let transactionTitle;
  let transactionMetaData;
  let transactionLevel;
  let transactionDate = convertDateFormat(eventData.date);

  if (eventData.type === "MINTED") {
    transactionTitle = "Minted";
    transactionMetaData = "Referrer";
    transactionLevel = `${transactionMetaData === "Referrer" ? "Price: $10" : "Price: $60"}`;
  } else if (eventData.type === "ASCENSION") {
    transactionTitle = "Advanced";
    transactionTitle = `${eventData.metadata.level === 0 ? "Entry" : "Advanced"}`;
    transactionMetaData = "Amount: $" + (await getTotalPrize(eventData.metadata.level));
    transactionLevel = "Level: " + eventData.metadata.level;
  } else if (eventData.type === "STAKE") {
    transactionTitle = "Staked";
    transactionMetaData = "Amount: $" + (await getTotalPrize(eventData.metadata.level));
    transactionLevel = "Level: " + eventData.metadata.level;
  }

  const transactionDiv = document.createElement("div");
  transactionDiv.className = "notificationsCards transactionsCard";

  const headerDiv = document.createElement("div");
  headerDiv.className = "notificationsCardHeader";
  const footerDiv = document.createElement("div");
  footerDiv.className = "notificationsCardHeader";

  const headlineDiv = document.createElement("div");
  headlineDiv.className = "transactionsCardHeadline notificationTotalWinningCard redGradient";
  headlineDiv.textContent = transactionTitle;

  const timeDiv = document.createElement("div");
  timeDiv.className = "transactionText";
  timeDiv.textContent = transactionMetaData;

  const levelDiv = document.createElement("div");
  levelDiv.className = "transactionText notificationTotalWinningCard";
  levelDiv.textContent = transactionLevel;

  const bodyDiv = document.createElement("div");
  bodyDiv.className = "transactionCardTime notificationTotalWinningCard";
  bodyDiv.textContent = transactionDate;

  headerDiv.appendChild(headlineDiv);
  headerDiv.appendChild(timeDiv);
  footerDiv.appendChild(bodyDiv);
  footerDiv.appendChild(levelDiv);
  transactionDiv.appendChild(headerDiv);
  transactionDiv.appendChild(footerDiv);

  return transactionDiv;
}

async function loadTransaction(startIndex, count, token) {
  const toRender = transactionsSortedEvents.slice(startIndex, startIndex + count);

  for (let event of toRender) {
    const transaction = await createTransactionsDiv(event, token);
    if (transaction) {
      transactionsContainer.appendChild(transaction);
    }
  }

  if (transactionsSortedEvents.length > startIndex + count) {
    seeMoreButton.style.display = "block";
    seeMoreButton.onclick = async () => {
      currentTransactionsIndex += count;
      await loadTransaction(currentTransactionsIndex, count, token);
    };
  } else {
    seeMoreButton.style.display = "none";
  }
}
