import moment from "moment";
import { gsap } from "gsap";

const announcementNotiContainer = document.querySelector(".announcementNotificationscardsContent");
const announcementSeeMoreButton = document.querySelector(".announcementNotiloadMore");

const baseURL = "https://api-gpm.goldpesa.com";
let AnnouncementSortedEvents = [];

export async function fetchAnnouncementNoti() {
  resetAnnouncementNoti();
  try {
    const response = await fetch(`${baseURL}/announcements`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    console.log("Announcement notifications data :", data);
    if (!Array.isArray(data) || data.length === 0) {
      console.log("No notifications events data received");
      gsap.to(".notiSkeletonCont", { autoAlpha: 0, duration: 0.5 });
      return;
    }

    AnnouncementSortedEvents = data.sort((a, b) => moment(b.date).diff(moment(a.date)));
    console.log("AnnouncementSortedEvents :", AnnouncementSortedEvents);

    loadAnouncementNotifications(currentIndex, 5);
    gsap.to(".notiSkeletonCont", { autoAlpha: 0, duration: 0.5 });
  } catch (error) {
    console.error("Error fetching wallet history:", error);
  }
}
export function resetAnnouncementNoti() {
  currentIndex = 0;
  announcementNotiContainer.innerHTML = "";
  announcementSeeMoreButton.style.display = "none";
  const notificationsIconCount = document.querySelectorAll(".notificationsIconCount");
  notificationsIconCount.forEach((el) => {
    el.style.display = "none";
  });
}

function relativeTime(date) {
  const timeFromNow = moment(date).fromNow();

  if (timeFromNow === "now ago") {
    return "now";
  }

  return timeFromNow;
}

function updateRelativeTime(element, date) {
  setInterval(() => {
    element.textContent = relativeTime(date);
  }, 30000);
}

function createAnnouncementNotiDiv(eventData) {
  const title = eventData.title;
  const body = eventData.message;

  const notificationDiv = document.createElement("div");
  notificationDiv.className = "notificationsCards";

  const headerDiv = document.createElement("div");
  headerDiv.className = "notificationsCardHeader";

  const headlineDiv = document.createElement("div");
  headlineDiv.className = "notificationsCardHeadline notificationTotalWinningCard";
  headlineDiv.textContent = title;

  const timeDiv = document.createElement("div");
  timeDiv.className = "notificationsCardTime";
  timeDiv.textContent = relativeTime(eventData.date);
  updateRelativeTime(timeDiv, eventData.date);

  const bodyDiv = document.createElement("div");
  bodyDiv.className = "notificationsText notificationTotalWinningCard";
  bodyDiv.textContent = body;

  headerDiv.appendChild(headlineDiv);
  headerDiv.appendChild(timeDiv);
  notificationDiv.appendChild(headerDiv);
  notificationDiv.appendChild(bodyDiv);

  return notificationDiv;
}

let currentIndex = 0;

function loadAnouncementNotifications(startIndex, count) {
  const toRender = AnnouncementSortedEvents.slice(startIndex, startIndex + count);

  toRender.forEach((event) => {
    const notification = createAnnouncementNotiDiv(event);
    if (notification) {
      announcementNotiContainer.appendChild(notification);
    }
  });

  if (AnnouncementSortedEvents.length > startIndex + count) {
    announcementSeeMoreButton.style.display = "block";
    announcementSeeMoreButton.onclick = () => {
      currentIndex += count; // Increment by the count value
      loadAnouncementNotifications(currentIndex, count);
    };
  } else {
    announcementSeeMoreButton.style.display = "none";
  }
}
