import moment from "moment";
import { gsap } from "gsap";
import { getTokens, setTokens, getProfitableTokens, setLevels } from "./tokens.js";
import { getStakedTokens } from "./stakedTokens.js";
import { getTokenTransactions, resetTokenTransactions } from "./tokensTransaction.js";
import { fetchWalletHistory } from "./notifications.js";
import { fetchAnnouncementNoti } from "./announcementNotifications.js";
import Hammer from "hammerjs";

import StateManager from "./appStates.js";
import { readContract, writeContract, prepareWriteContract, waitForTransaction } from "@wagmi/core";
import GPMines from "./contracts/GPMines.json";
import { parseUnits, formatEther } from "viem";
import GPMAux from "./contracts/GPMAux.json";
import { GPM, GPMA } from "./contracts/ContractAddresses.js";

let allScreens = [];

class MenuScreen {
  constructor(screenSelector, openTriggerSelector, backTriggerSelector, previousScreenSelector, useContainerHeight = false) {
    this.screen = document.querySelector(screenSelector);
    this.openTrigger = document.querySelector(openTriggerSelector);
    this.backTrigger = document.querySelector(backTriggerSelector);
    this.previousScreen = previousScreenSelector ? document.querySelector(previousScreenSelector) : null;
    this.useContainerHeight = useContainerHeight;
    this.timeline = this.createTimeline();
    this.addEventListeners();
    window.addEventListener("resize", () => this.resizeHandler());
    allScreens.push(this.screen);
  }

  createTimeline() {
    const tl = gsap.timeline({ paused: true });
    tl.set(this.screen, { autoAlpha: 0, height: "0px" })
      .to(this.previousScreen || ".mainMenuScreen", { autoAlpha: 0, duration: 0.25 }, ">")
      .to(this.previousScreen || ".mainMenuScreen", { height: "0px", duration: 0.25 }, ">-0.1")
      .to(this.screen, { height: () => (this.useContainerHeight ? this.getContainerHeight() : "100vh"), duration: 0.25 }, "<0")
      .to(this.screen, { autoAlpha: 1, duration: 0.25 }, ">-0.1");
    return tl;
  }
  reverseTimeline() {
    this.timeline.reverse();
  }
  restart() {
    this.timeline.restart();
  }
  addEventListeners() {
    if (this.openTrigger) {
      this.openTrigger.addEventListener("click", () => this.timeline.restart());
    }
    if (this.backTrigger) {
      this.backTrigger.addEventListener("click", () => this.timeline.reverse());
    }
  }
  getContainerHeight() {
    //console.log("getContainerHeight called!");
    return document.querySelector(".menu-container").offsetHeight + "px";
  }
  updateOpenScreenHeight() {
    if (this.useContainerHeight && gsap.getProperty(this.screen, "opacity") !== 0) {
      gsap.set(this.screen, { height: this.getContainerHeight() });
    }
  }
  resizeHandler() {
    if (this.useContainerHeight) {
      this.updateOpenScreenHeight();
    }
  }
}

class ResetTimeline {
  constructor() {
    this.timeline = this.createTimeline();
  }

  createTimeline() {
    const tl = gsap.timeline({ paused: true });
    tl.set(allScreens, { autoAlpha: 0, height: 0 })
      .to(".mainMenuScreen", { height: "auto", duration: 0.25 }, ">")
      .to(".mainMenuScreen", { autoAlpha: 1, duration: 0.25 }, ">-0.1");
    return tl;
  }

  restart() {
    this.timeline.restart();
  }
}

let mainContainerHeight = document.querySelector(".menu-container").offsetHeight;
//console.log("mainContainerHeight :", mainContainerHeight);
// Initializing screens
const earningsMenuScreen = new MenuScreen(".earningsMenuScreen", ".earningsLabel", ".menueBackFromEarnings svg", null, true);
const transactionMenuScreen = new MenuScreen(".transactionMenuScreen", ".transactionLabel", ".menueBackFromTransaction svg", null, true);
const transactionTokensScreen = new MenuScreen(
  ".transactionTokensScreen",
  ".transactionMenuSelectbtn",
  ".menueBackToTransaction svg",
  ".transactionMenuScreen"
);
const stakeMenuScreen = new MenuScreen(".stakeMenuScreen", ".stakeLabel", ".menueBackFromStake svg", null, true);
const stakeTokensScreen = new MenuScreen(".stakeTokensScreen", ".stakeMenuSelectbtn", ".menueBackToStake svg", ".stakeMenuScreen");
const cashoutMenuScreen = new MenuScreen(".cashoutMenuScreen", ".cashoutLabel", ".menueBackFromCashout svg", null, true);
const cashoutTokensScreen = new MenuScreen(
  ".cashoutTokensScreen",
  ".cashoutMenuSelectbtn",
  ".menueBackToCashout svg",
  ".cashoutMenuScreen"
);
const rulesMenuScreen = new MenuScreen(".rulesScreen", ".rulesLabel", ".menueBackFromRules svg");

const resetTimeline = new ResetTimeline();

const menuContTl = gsap.timeline({
  paused: true,
  onReverseComplete: () => resetTimeline.restart(),
});

menuContTl
  .set(".mainMenuScreen", { ease: "power2.out", height: "100%" })
  .set(".menu-container", { ease: "power2.out", y: "100%" })
  .to(".menu-container", { duration: 0.25, ease: "power2.out", y: 0 }, ">")
  .to(".sortingContBtn svg", { duration: 0.25, ease: "power2.out", rotation: 180 }, "<")
  .set(".mainMenuOverlay", { ease: "power2.out", y: 0 }, "<")
  .to(".mainMenuOverlay", { duration: 0.25, ease: "power2.out", autoAlpha: 1 }, ">");

let stakeConfirmBtn = document.querySelector(".stakeConfirmBtn");
let cashoutConfirmBtn = document.querySelector(".cashoutConfirmBtn");
let stakeConfirmFinal = document.querySelector(".stakeConfirmFinal");
let stakeBtnClickable = false;
let cashoutBtnClickable = false;
let cashoutBtnFinalClickable = false;
const errorDiv = document.querySelector(".errorDiv .mine-small-regular");
const successDiv = document.querySelector(".successDiv .mine-small-regular");

//const GPM = "0x1BabDb68c9f2909537396ABCC8CEC66385900e4f";
//const GPMA = "0x8E23B0D4b0Bb176A0f019dc1786DC1E34c45ee47";

function setStakeBtnClickable(state) {
  stakeBtnClickable = state;

  if (stakeBtnClickable) {
    stakeConfirmBtn.classList.add("stakeConfirmBtnActive");
  } else {
    stakeConfirmBtn.classList.remove("stakeConfirmBtnActive");
  }
}
function setCashoutBtnClickable(state) {
  cashoutBtnClickable = state;

  if (cashoutBtnClickable) {
    cashoutConfirmBtn.classList.add("cashoutConfirmBtnActive");
  } else {
    cashoutConfirmBtn.classList.remove("cashoutConfirmBtnActive");
  }
}
function setCashoutBtnFinalClickable(state) {
  cashoutBtnFinalClickable = state;

  if (cashoutBtnFinalClickable) {
    stakeConfirmFinal.classList.add("stakeConfirmFinalActive");
  } else {
    stakeConfirmFinal.classList.remove("stakeConfirmFinalActive");
  }
}

export default class MenuManager {
  static instance = null;
  constructor() {
    if (MenuManager.instance) {
      return MenuManager.instance;
    }
    this.btnsContainer = document.querySelector(".stakeDurationsBtns");
    this.btns = this.btnsContainer.children;

    this.aprElement = document.querySelector(".stakeEstAPR span");
    this.cashoutTotalBalance = document.querySelector(".cashoutTotalBalance");
    this.cashoutBtnReceiveAmount = document.querySelector(".cashoutBtnReceiveAmount span");
    this.cashoutBtnNetworkFee = document.querySelector(".cashoutBtnNetworkFee span");
    this.displayDiv = document.getElementById("token-properties");
    this.displayDivRest = document.getElementById("token-properties-rest");
    this.displayCashoutDiv = document.getElementById("token-cashout-properties");
    this.menuHeaderBalance = document.querySelector(".menuHeaderBalance span");
    this.notificationTotalWinningCardTitle = document.querySelector(".notificationTotalWinningCard .notiTotalWinningTitle");
    this.notificationTotalWinningCardBody = document.querySelector(".notificationTotalWinningCard .notiTotalWinningBody");
    this.stakeMenuSelectbtn = document.querySelector(".stakeMenuSelectbtn");
    this.cashoutMenuSelectbtn = document.querySelector(".cashoutMenuSelectbtn");
    this.transactionMenuSelectbtn = document.querySelector(".transactionMenuSelectbtn");
    this.stakeMenuSelectbtnText = document.querySelector(".stakeMenuSelectbtn .SelectBtnLabel .menuLabelText");
    this.cashoutMenuSelectbtnText = document.querySelector(".cashoutMenuSelectbtn .SelectBtnLabel .menuLabelText");
    this.transactionMenuSelectbtnText = document.querySelector(".transactionMenuSelectbtn .SelectBtnLabel .menuLabelText");
    this.tokensCount;
    this.totalCredits;
    this.totalCreditsWithComma;

    this.selectedToken = null;
    this.selectedCashoutToken = null;
    this.selectedDuration = 360;
    this.aprValues = [0.0919, 0.129, 0.149, 0.189];
    //this.selectedApr = this.aprValues[3];
    this.selectedApr = 0; // Initialize to zero
    /* this.fetchAprFromApi().then((apy) => {
      this.selectedApr = apy / 100;
    }); */
    this.temporalTier = 5;
    this.tokenCumulativeWinnings;
    this.tokenCumulativeWinningsInGPO;
    this.tenPercentOfGPOWinnings;
    this.netWinningsInGPO;
    this.tenPercentOfUSDCWinnings;
    this.netWinningsInUSDC;

    this.subscriptionDateElement = document.querySelector(".stakeSummary-SubscriptionDate .stakeSummary-ValueDate");
    this.valueDateElement = document.querySelector(".stakeSummary-ValueDate-title .stakeSummary-ValueDate");
    this.endDateElement = document.querySelector(".stakeSummary-EndDate .stakeSummary-ValueDate");
    this.totalRewardsElement = document.querySelector(".stakeSummary-TotalRewards .stakeSummary-ValueDate");

    this.now = new Date();
    this.nextDay = new Date(this.now);
    this.nextDay.setDate(this.now.getDate() + 1);
    this.nextDay.setHours(0, 0, 0, 0);
    this.endDate;
    this.duration = 360;
    this.rulesBtns = document.querySelectorAll(".rulesBtn");
    this.rulesContent = document.querySelector(".rulesContent");

    this.rulesText = {
      rule1: `GoldPesa Mines is a DeFi game which is 100% decentralized and governed by smart contracts deployed on the Polygon blockchain.  
        
        The GoldPesa Mines smart contracts are immutable and can never be changed or upgraded. Upon deployment, the GoldPesa dev team relinquished all rights to the mine. Therefore, all funds are secured in the custody of GP Mines smart contracts and its rules and logic are forever governed by its code.  
        
        In theory, GP Mines can produce earnings for its miners for hundreds of years. 
  
        For every entry into the GoldPesa Mine, you can earn up to $81,920 USD.`,
      rule2: `To enter the GoldPesa Mine: 

      Buy a $10 Gold Miner NFT and get 3 friends to join using your referral link. 
    
      Remember:  
      a) Your Gold Miner NFT can NOT enter the mine without referring 3 friends. 
      b) You can buy as many Gold Miner NFTs as you want, but each one needs 3 friends to enter. 
      
      For instance, if 3 friends use your link to each buy 5 NFTs, you get credit for 15 referrals. This lets you enter with 5 NFTs right away, each with a chance to earn up to $81,920.`,
      rule3: `1) Buy a Gold Miner NFT for $10 and get 3 friends to join using your referral link. 

      2) Relax and watch your Gold Miner NFT automatically advance further down the GoldPesa Mine, earning money at each of the 12 levels. 
      
      3) When three new miners from anywhere around the world enter a new level, a random draw decides who gets to advance further down the mine.  
      
      4) Unchosen miners stay at their current level until three more miners arrive, which gives them another chance to move further down the mine. 
      
      5) The prize money increases as you progress through the levels. 
      
      6) Your Gold Miner NFT has endless opportunities to go deeper into the mine and potentially win the grand prize of $81,920 USD. 
      
      7) Miners can withdraw their earnings anytime but cashing out means leaving the mine and forfeiting the chance to earn more. 
      
      8) The GoldPesa Mine aims to produce many winners. The more players join, the more frequent the winnings, propelling numerous miners further down the mine while earning along the way.`,
      rule4: `Every Gold Miner NFT has the option to stake their winnings at any given time while remaining in the GoldPesa Mine and holding its position.  

      Staking is available for 30, 90, 180 and 360 days. The more you lock your earnings, the more you will earn.`,
    };
  }

  async setupMainMenu() {
    this.setupRuleButtons();
    this.now = new Date();
    this.nextDay = new Date(this.now);
    this.nextDay.setDate(this.now.getDate() + 1);
    this.nextDay.setHours(0, 0, 0, 0);

    this.subscriptionDateElement.textContent = moment(this.now).format("YYYY-MM-DD HH:mm");
    this.valueDateElement.textContent = moment(this.nextDay).format("YYYY-MM-DD HH:mm");
    this.setupDurationButtons();

    const tokens = await getTokens();
    const profitableTokens = await getProfitableTokens();
    tokens.sort((a, b) => Number(b.cumulativeWinnings) - Number(a.cumulativeWinnings));
    profitableTokens.sort((a, b) => Number(b.cumulativeWinnings) - Number(a.cumulativeWinnings));
    this.tokensCount = profitableTokens.length;
    this.totalCredits = tokens.reduce((total, token) => total + Number(token.cumulativeWinnings), Number(0));

    this.totalCreditsWithComma = this.numberWithCommas(this.totalCredits);
    this.displayDiv.innerHTML = `
        Available NFts: <span>${this.tokensCount} Tokens</span> <br>
        Available Balance: <span>${this.totalCreditsWithComma} USD</span> <br>
    `;
    this.cashoutTotalBalance.innerHTML = `
      <div>Available NFts<span>${this.tokensCount} Tokens</span></div>
      <div>Available Balance<span>${this.totalCreditsWithComma} USD</span></div>
    `;
    const stakedTokens = await getStakedTokens();
    const totalLpAmount = stakedTokens.reduce((acc, token) => acc + Number(token.lpAmount), 0);
    const totalCumulativeEarnings = stakedTokens.reduce((acc, token) => acc + token.cumulativeEarnings, 0);
    const totalStake = totalLpAmount + totalCumulativeEarnings;
    this.menuHeaderBalance.innerHTML = this.numberWithCommas((this.totalCredits + totalStake).toFixed(2));

    if (profitableTokens.length > 0) {
      this.generateTokens(profitableTokens, "token-selection", "stake");
      this.generateTokens(profitableTokens, "token-cashout-selection", "cashout");
    }
    if (tokens.length > 0) {
      this.generateTokens(tokens, "token-transaction-selection", "transaction");
    }
  }

  generateTokens(tokens, containerId, type) {
    const container = document.getElementById(containerId);
    container.innerHTML = "";
    tokens.forEach((token, index) => {
      const tokenElement = document.createElement("div");
      tokenElement.classList.add("tokenSelect");
      tokenElement.innerHTML = `
      <div class="stakeSelection-Token">
          <div class="stakeSelection-TokenInfo">
              <div class="levelInfo">
                  <div class="levelNoInfo">
                      <div class="tokenIndecator"></div>
                      <div class="tokenId">#${token.tokenId}</div>
                  </div>
              </div>
          </div>
          <div class="stakeSelection-Winnings">
              <div class="levelPrizeAmount">${token.cumulativeWinnings}</div>
              <div class="levelPrizetext">USD</div>
          </div>
      </div>
    `;
      tokenElement.addEventListener("click", async () => {
        if (type === "stake") {
          this.updateStakeToken(token);
          this.selectedToken = token;

          console.log("CALLED!! stake token selection clicked");
        } else if (type === "cashout") {
          await this.updateCashoutToken(token);
          console.log("CALLED!! cashout token selection clicked");
        } else if (type === "transaction") {
          this.updateTransactionToken(token);
          console.log("CALLED!! transaction token selection clicked");
        }
      });
      container.appendChild(tokenElement);
    });
  }

  resetTokensSelection() {
    this.selectedToken = null;
    this.displayDivRest.innerHTML = "";
    this.stakeMenuSelectbtnText.innerHTML = "Select a Token";
    gsap.set(this.stakeMenuSelectbtn, { color: "var(--sec-text-color)" });
    gsap.set(".SelectGreenIndecator", { display: "none" });
    setStakeBtnClickable(false);
    setCashoutBtnClickable(false);

    this.totalRewardsElement.textContent = "--";

    for (let i = 0; i < this.btns.length; i++) {
      let newButton = this.btns[i].cloneNode(true);
      this.btnsContainer.replaceChild(newButton, this.btns[i]);
    }
    this.btns = this.btnsContainer.children;

    this.displayCashoutDiv.innerHTML = "≈ 0.00 GPO";
    this.cashoutBtnReceiveAmount.textContent = "0.00";
    this.cashoutBtnNetworkFee.textContent = "0.00";
    this.cashoutMenuSelectbtnText.innerHTML = "Select a Token";
    gsap.set(this.cashoutMenuSelectbtn, { color: "var(--sec-text-color)" });
    gsap.set(".cashoutSelectGreenIndecator", { display: "none" });
    gsap.set(".cashoutCurrencyOptitions", { display: "none" });

    this.transactionMenuSelectbtnText.innerHTML = "Select a Token";
    gsap.set(this.transactionMenuSelectbtn, { color: "var(--sec-text-color)" });
    gsap.set(".transactionSelectGreenIndecator", { display: "none" });
  }
  updateStakeToken(token) {
    this.selectedToken = token;
    this.displayDivRest.innerHTML = `
                Token ID: <span>${this.selectedToken.tokenId}</span> <br>
                Current Level: <span>${this.selectedToken.currentLevel}</span> <br>
                Cumulative Winnings: <span>${this.numberWithCommas(this.selectedToken.cumulativeWinnings)} USD</span>
            `;

    this.stakeMenuSelectbtnText.innerHTML = `#${this.selectedToken.tokenId} - ${this.numberWithCommas(
      this.selectedToken.cumulativeWinnings
    )}$`;
    gsap.set(this.stakeMenuSelectbtn, { color: "white" });
    gsap.set(".SelectGreenIndecator", { display: "block" });
    setStakeBtnClickable(true);
    this.calculateAndDisplayTotalRewards(this.selectedToken);
    stakeTokensScreen.reverseTimeline();

    stakeConfirmBtn.onclick = async () => {
      return;
      if (stakeBtnClickable) {
        setStakeBtnClickable(false);
        stakeConfirmBtn.textContent = `Loading...`;
        console.log("clicked!! stakeConfirmBtn");
        console.log("stakeBtnClickable :", stakeBtnClickable);
        console.log("this.selectedToken.tokenId :", this.selectedToken.tokenId);
        let deadline = Math.floor(new Date().getTime() / 1000 + 15 * 60);
        let token = BigInt(this.selectedToken.tokenId);
        let temporalTier = BigInt(this.temporalTier);
        console.log("temporalTier :", this.temporalTier);
        let config;
        try {
          config = await prepareWriteContract({
            ...GPMAux,
            address: GPMA,
            functionName: "stake",
            args: [token, this.temporalTier, deadline],
          }).catch((error) => {
            console.error("Error preparing contract for staking:", error);
            stakeConfirmBtn.textContent = `Stake`;
            setStakeBtnClickable(true);
            errorDiv.textContent = `Staking failed!`;
            errorDivTimeline.restart();
          });
        } catch (error) {
          console.error("Error preparing contract for staking:", error);
          stakeConfirmBtn.textContent = `Stake`;
          setStakeBtnClickable(true);
          errorDiv.textContent = `Staking failed!`;
          errorDivTimeline.restart();
          return;
        }
        if (config !== undefined) {
          let hash;
          try {
            const result = await writeContract(config);
            hash = result.hash;
          } catch (error) {
            console.error("Error writing contract:", error);
            stakeConfirmBtn.textContent = `Stake`;
            setStakeBtnClickable(true);
            errorDiv.textContent = `Staking failed!`;
            errorDivTimeline.restart();
          }
          if (hash) {
            console.log("hash :", hash);
            setStakeBtnClickable(false);
            stakeConfirmBtn.textContent = `Loading...`;
            const data = await waitForTransaction({
              hash,
            });
            if (!data) {
              console.log("data :", data);
              console.log("Waiting for transaction...");
              setStakeBtnClickable(false);
              stakeConfirmBtn.textContent = `Loading...`;
            } else if (data.status === "success") {
              console.log("data :", data);
              console.log("staking succeeded!");
              stakeConfirmBtn.textContent = `Stake`;
              setStakeBtnClickable(true);
              await setTokens();
              await StateManager.notifyStateChange();
              successDiv.textContent = `Token successfully staked!`;
              successDivTimeline.restart();
              await closeAndRestMenu();
            } else {
              console.log("data :", data);
              console.log("Transaction failed");
              stakeConfirmBtn.textContent = `Stake`;
              setStakeBtnClickable(true);
              errorDiv.textContent = `Staking failed!`;
              errorDivTimeline.restart();
            }
          }
        }
      }
    };

    this.now = new Date();
    this.nextDay = new Date(this.now);
    this.nextDay.setDate(this.now.getDate() + 1);
    this.nextDay.setHours(0, 0, 0, 0);

    this.subscriptionDateElement.textContent = moment(this.now).format("YYYY-MM-DD HH:mm");
    this.valueDateElement.textContent = moment(this.nextDay).format("YYYY-MM-DD HH:mm");
    this.endDate = new Date(this.nextDay);
    this.endDate.setDate(this.nextDay.getDate() + this.duration);
    this.endDateElement.textContent = moment(this.endDate).format("YYYY-MM-DD HH:mm");
  }

  async updateCashoutToken(token) {
    cashoutTokensScreen.reverseTimeline();

    this.tokenCumulativeWinnings = token.cumulativeWinnings;
    this.tokenCumulativeWinningsInGPO = await getQuote(this.tokenCumulativeWinnings);
    this.netWinningsInGPO = this.numberWithCommas(this.tokenCumulativeWinningsInGPO);
    this.netWinningsInUSDC = this.numberWithCommas(this.tokenCumulativeWinnings);
    this.displayCashoutDiv.innerHTML = "≈ " + this.numberWithCommas(this.tokenCumulativeWinningsInGPO) + " GPO";
    this.cashoutBtnReceiveAmount.innerHTML = this.netWinningsInGPO + " GPO";
    let currency = "GPO";
    console.log("currency :", currency);
    let cashoutOptionsGPO = document.querySelector(".cashoutOptionsGPO");
    let cashoutOptionsUSDC = document.querySelector(".cashoutOptionsUSDC");
    cashoutOptionsGPO.classList.add("mintOptionsBtnActive");
    cashoutOptionsUSDC.classList.remove("mintOptionsBtnActive");

    const cashoutOptionsBtns = [cashoutOptionsGPO, cashoutOptionsUSDC];
    cashoutOptionsBtns.forEach((div) => {
      div.onclick = () => {
        const currentDiv = div;
        cashoutOptionsBtns.forEach((d) => d.classList.remove("mintOptionsBtnActive"));
        currentDiv.classList.add("mintOptionsBtnActive");
        if (currentDiv === cashoutOptionsGPO) {
          currency = "GPO";
          this.cashoutBtnReceiveAmount.innerHTML = this.netWinningsInGPO + " GPO";
        } else if (currentDiv === cashoutOptionsUSDC) {
          currency = "USDC";
          this.cashoutBtnReceiveAmount.innerHTML = this.netWinningsInUSDC + " USDC";
        }
        console.log("currency:", currency);
      };
    });

    this.cashoutMenuSelectbtnText.innerHTML = `#${token.tokenId} - ${this.numberWithCommas(token.cumulativeWinnings)}$`;
    gsap.set(this.cashoutMenuSelectbtn, { color: "white" });
    gsap.set(".cashoutSelectGreenIndecator", { display: "block" });
    gsap.set(".cashoutCurrencyOptitions", { display: "block" });

    setCashoutBtnClickable(true);
    const stakeInputField = document.getElementById("StakeConfirmationInput");
    const stakeConfirmationAfterText = document.querySelector(".stakeConfirmationAfterText");
    let tokenApproved = true;
    cashoutConfirmBtn.onclick = async () => {
      if (cashoutBtnClickable) {
        stakeConfirmationContainerTimeline.play();
        let getApproved = GPMA;

        if (getApproved !== GPMA) {
          stakeConfirmFinal.textContent = `Approve`;
          stakeConfirmationAfterText.textContent = `Approve the token first`;
          tokenApproved = false;
        } else {
          stakeConfirmFinal.textContent = `Cash Out`;
          stakeConfirmationAfterText.textContent = `Token approved`;
          tokenApproved = true;
        }

        const stakeConfirmationText = document.querySelector(".stakeConfirmationText span");

        stakeConfirmationText.textContent = token.tokenId;

        stakeInputField.oninput = function () {
          if (this.value === "CONFIRM") {
            setCashoutBtnFinalClickable(true);
            console.log("User initial cash out confirmed");
          } else {
            setCashoutBtnFinalClickable(false);
            console.log("User didn't confirm cash out");
          }
        };
        document.querySelector(".stakeConfirmCancel").onclick = () => {
          stakeConfirmationContainerTimeline.reverse();
          setCashoutBtnFinalClickable(false);
          stakeInputField.value = "";
        };
      }
    };
    stakeConfirmFinal.onclick = async () => {
      if (cashoutBtnFinalClickable) {
        setCashoutBtnFinalClickable(false);
        stakeConfirmFinal.textContent = `Loading...`;
        console.log("clicked!! cashoutConfirmFinal");
        console.log("cashoutBtnFinalClickable :", cashoutBtnFinalClickable);
        console.log("this.selectedToken.tokenId :", token.tokenId);
        //let deadline = 0;
        let deadline = Math.floor(new Date().getTime() / 1000 + 15 * 60);
        let tokenId = BigInt(token.tokenId);

        if (!tokenApproved) {
          try {
            const config = await prepareWriteContract({
              ...GPMines,
              address: GPM,
              functionName: "approve",
              args: [GPMA, tokenId],
            });
            const { hash } = await writeContract(config);
            console.log(hash);
            setCashoutBtnFinalClickable(true);
            tokenApproved = true;
            stakeConfirmFinal.textContent = `Cash Out`;
            stakeConfirmationAfterText.textContent = `Token approved`;
          } catch (error) {
            console.error("Error writing contract:", error);
            stakeConfirmFinal.textContent = `Cash Out`;
            setCashoutBtnFinalClickable(true);
            errorDiv.textContent = `Rejected approval!`;
            errorDivTimeline.restart();
            return;
          }
        } else {
          let config;
          try {
            if (currency === "GPO") {
              console.log("cashing out now with GPO...");
              config = await prepareWriteContract({
                ...GPMAux,
                address: GPMA,
                functionName: "buyGPO",
                args: [tokenId],
              }).catch((error) => {
                console.error("Error preparing contract for cashing out:", error);
                stakeConfirmFinal.textContent = `Cash Out`;
                setCashoutBtnFinalClickable(true);
                errorDiv.textContent = `cashing out failed!`;
                errorDivTimeline.restart();
                //return;
              });
            } else if (currency === "USDC") {
              console.log("cashing out now with USDC...");
              config = await prepareWriteContract({
                ...GPMAux,
                address: GPMA,
                functionName: "cashOutPrize",
                args: [tokenId, deadline],
              }).catch((error) => {
                console.error("Error preparing contract for cashing out:", error);
                stakeConfirmFinal.textContent = `Cash Out`;
                setCashoutBtnFinalClickable(true);
                errorDiv.textContent = `cashing out failed!`;
                errorDivTimeline.restart();
              });
            }
          } catch (error) {
            console.error("Error preparing contract for cashing out:", error);
            stakeConfirmFinal.textContent = `Cash Out`;
            setCashoutBtnFinalClickable(true);
            errorDiv.textContent = `cashing out failed!`;
            errorDivTimeline.restart();
            return;
          }

          if (config !== undefined) {
            let hash;
            try {
              const result = await writeContract(config);
              hash = result.hash;
            } catch (error) {
              console.error("Error writing contract:", error);
            }
            if (hash) {
              console.log("hash :", hash);
              setCashoutBtnFinalClickable(false);
              stakeConfirmFinal.textContent = `Loading...`;
              const data = await waitForTransaction({
                hash,
              });
              if (!data) {
                console.log("data :", data);
                console.log("Waiting for transaction...");
                setCashoutBtnFinalClickable(false);
                stakeConfirmFinal.textContent = `Loading...`;
              } else if (data.status === "success") {
                console.log("data :", data);
                console.log("Cashing out succeeded!");
                stakeConfirmFinal.textContent = `Done!`;
                await setTokens();
                await StateManager.notifyStateChange();
                stakeConfirmationContainerTimeline.reverse();
                setCashoutBtnFinalClickable(false);
                stakeInputField.value = "";
                await closeAndRestMenu();

                successDiv.textContent = `Token successfully cashed out!`;
                successDivTimeline.restart();
                await setLevels();
              } else {
                console.log("data :", data);
                console.log("Transaction failed");
                stakeConfirmFinal.textContent = `Cash Out`;
                setCashoutBtnFinalClickable(true);
                errorDiv.textContent = `Staking failed!`;
                errorDivTimeline.restart();
              }
            }
          }
        }
      }
    };
  }
  updateTransactionToken(token) {
    this.transactionMenuSelectbtnText.innerHTML = `#${token.tokenId} - ${this.numberWithCommas(token.cumulativeWinnings)}$`;
    gsap.set(this.transactionMenuSelectbtn, { color: "white" });
    gsap.set(".transactionSelectGreenIndecator", { display: "block" });
    getTokenTransactions(token);
    transactionTokensScreen.reverseTimeline();
  }
  async fetchAprFromApi() {
    try {
      const res = await fetch("https://api-gpm.goldpesa.com/staking");
      if (!res.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await res.json();
      console.log("data apy:", data.apy);
      this.selectedApr = data.apy; // Update the instance variable
      console.log("this.selectedApr :", this.selectedApr);
    } catch (err) {
      console.error("Failed to fetch APY:", err);
      this.selectedApr = 0; // Fallback to 0
    }
  }
  async setupDurationButtons() {
    for (let i = 0; i < this.btns.length; i++) {
      let newButton = this.btns[i].cloneNode(true);
      this.btnsContainer.replaceChild(newButton, this.btns[i]);
      newButton.addEventListener("click", async () => {
        await this.fetchAprFromApi();

        this.duration = [30, 90, 180, 360][i];
        this.aprElement.textContent = (this.selectedApr * 100).toFixed(2);
        this.temporalTier = BigInt(i + 2);
        console.log("this.temporalTier :", this.temporalTier);

        this.endDate = new Date(this.nextDay);
        this.endDate.setDate(this.nextDay.getDate() + this.duration);

        if (this.selectedToken) {
          this.updateStakeToken(this.selectedToken);
        }
        // this.endDate.setDate(this.nextDay.getDate() + this.duration);
        this.endDateElement.textContent = moment(this.endDate).format("YYYY-MM-DD HH:mm");
        // this.selectedDuration = [30, 90, 180, 360][i];
        this.selectedDuration = this.duration;

        this.calculateAndDisplayTotalRewards(this.selectedToken);
        console.log("Total rewards", this.totalRewardsElement.textContent);

        for (let j = 0; j < this.btns.length; j++) {
          this.btns[j].classList.remove("stakeDurationsBtnActive");
        }
        this.btns[i].classList.add("stakeDurationsBtnActive");
      });
    }
    this.btns[3].click();
  }

  calculateAndDisplayTotalRewards(token) {
    if (!token) {
      console.log("No token selected. Nothing to calculate.");
      return;
    }
    console.log("token.cumulativeWinnings:", token.cumulativeWinnings);
    console.log("this.selectedDuration:", this.selectedDuration);

    // const apr = this.selectedApr / 100;
    const apr = this.selectedApr;
    // const r = apr / 365;
    const r = apr / 360;
    const t = this.selectedDuration;
    const P = token.cumulativeWinnings;
    const totalRewards = P * r * t;
    this.totalRewardsElement.textContent = totalRewards.toFixed(2) + " USD";
    // console.log("Total rewards", this.totalRewardsElement.textContent);
  }
  setupRuleButtons() {
    this.rulesBtns.forEach(
      function (btn) {
        if (btn.handler) {
          btn.removeEventListener("click", btn.handler);
        }
        let handler = function () {
          this.rulesBtns.forEach((button) => {
            button.classList.remove("ruleBtnActive");
          });
          btn.classList.add("ruleBtnActive");
          let ruleClass = Array.from(btn.classList).find((cls) => this.rulesText[cls]);
          this.rulesContent.innerText = this.rulesText[ruleClass] || "";
          console.log("CALLED!! rules");
        }.bind(this);
        btn.addEventListener("click", handler);
        btn.handler = handler;
      }.bind(this)
    );
  }

  numberWithCommas(x) {
    x = Number(x).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

const menuManager = new MenuManager();

document.querySelector(".connectedTokenHeader").addEventListener("click", function (event) {
  event.stopPropagation();
  menuContTl.restart();
});

document.querySelector(".disconnectLabel").addEventListener("click", () => {
  menuContTl.reverse();
  menuManager.resetTokensSelection();
});
const stakeConfirmationContainerTimeline = gsap.timeline({ paused: true });
stakeConfirmationContainerTimeline
  .to(".stakeConfirmation-container", { duration: 0.25, ease: "power2.out", y: 0 })
  .to(".stakeConfirmationOverlayFull", { duration: 0.25, ease: "power2.out", autoAlpha: 1 }, "<");

const successDivTimeline = gsap.timeline({ paused: true });
successDivTimeline.to(".successDiv", { autoAlpha: 1, duration: 0.5 }).to(".successDiv", { autoAlpha: 0, duration: 0.5 }, ">2");

const errorDivTimeline = gsap.timeline({ paused: true });
errorDivTimeline.to(".errorDiv", { autoAlpha: 1, duration: 0.5 }).to(".errorDiv", { autoAlpha: 0, duration: 0.5 }, ">2");

let overlay = document.querySelector(".NotificationsOverlay");
let notifications = document.querySelector(".notifications");
let notiCardsContainer = document.querySelector(".notiCardsContainer");
let mainContainerWidth = document.querySelector(".menu-container").offsetWidth;

let hammer = new Hammer(document.querySelector(".menu-container"), { touchAction: "auto" });
hammer.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 20 });
hammer.get("swipe").set({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 20, velocity: 0.5 });

let isNotificationsOpen = false;
let isSwipeEventTriggered = false;
let duration = 0.2;
const closeThreshold = 80;
const panThreshold = 1500;

function areAnyScreensOpen() {
  let stakeTokensScreenHeight = document.querySelector(".stakeTokensScreen").offsetHeight;
  let cashoutTokensScreenHeight = document.querySelector(".cashoutTokensScreen").offsetHeight;
  let transactionMenuScreenHeight = document.querySelector(".transactionMenuScreen").offsetHeight;
  let transactionTokensScreenHeight = document.querySelector(".transactionTokensScreen").offsetHeight;
  let earningsMenuScreenHeight = document.querySelector(".earningsMenuScreen").offsetHeight;

  return (
    stakeTokensScreenHeight > 0 ||
    cashoutTokensScreenHeight > 0 ||
    transactionMenuScreenHeight > 0 ||
    transactionTokensScreenHeight > 0 ||
    earningsMenuScreenHeight > 0
  );
}
hammer.on("panstart", function (ev) {
  if (areAnyScreensOpen()) return;
  gsap.killTweensOf(notifications);
  gsap.killTweensOf(overlay);
  isSwipeEventTriggered = false;

  // console.log("pan started :", ev.deltaX);
});

hammer.on("panmove", function (ev) {
  if (areAnyScreensOpen()) return;
  if (!isNotificationsOpen && ev.deltaX > 0) {
    return;
  }
  if (isNotificationsOpen && ev.deltaX < 0) {
    return;
  }
  if (isNotificationsOpen && Math.abs(ev.deltaX) < panThreshold) {
    return;
  }
  let x = isNotificationsOpen ? Math.max(ev.deltaX, -mainContainerWidth) : Math.min(ev.deltaX, 0) + mainContainerWidth;

  let progress = isNotificationsOpen ? Math.abs(ev.deltaX) / closeThreshold : Math.abs(x - mainContainerWidth) / mainContainerWidth;
  // console.log("progress :", progress);
  // console.log("ev.deltaX :", ev.deltaX);

  gsap.to(notifications, { x: x, duration: 0 });
  gsap.to(overlay, { backdropFilter: `blur(${15 * progress}px)`, duration: 0 });
  let scaleValue = 1 - 0.05 * progress;
  gsap.to(".menuScreen", { scale: scaleValue, duration: 0 });
});
hammer.on("swipe", async function (ev) {
  if (areAnyScreensOpen()) return;
  let velocity = Math.abs(ev.velocityX);
  // console.log("velocity :", velocity);

  if (ev.direction === 2 && !isNotificationsOpen) {
    openNotifications();
  } else if (ev.direction === 4 && isNotificationsOpen) {
    closeNotifications();
  }
  isSwipeEventTriggered = true;
  // console.log("Swipe event captured & isNotificationsOpen :", isNotificationsOpen);
});

hammer.on("panend", async function (ev) {
  if (areAnyScreensOpen()) return;
  if (isSwipeEventTriggered) {
    return;
  }
  let progress = isNotificationsOpen ? Math.abs(ev.deltaX) / closeThreshold : Math.abs(ev.deltaX) / mainContainerWidth;
  //console.log("progress :", progress);

  if (progress >= 0.5 && !isNotificationsOpen) {
    openNotifications();
    //console.log("pan event captured & isNotificationsOpen :", isNotificationsOpen);
  } else if (progress < 0.5 && !isNotificationsOpen) {
    closeNotifications();
  }
});

async function openNotifications() {
  gsap.to(notifications, { x: 0, duration: duration });
  gsap.to(overlay, { backdropFilter: "blur(15px)", duration: duration });
  gsap.to(".menuScreen", { scale: 0.95, duration: duration });
  //console.log("duration :", duration);
  notiCardsContainer.style.overflowY = "auto";
  isNotificationsOpen = true;
  await fetchWalletHistory();
  //console.log("isNotificationsOpen from :", isNotificationsOpen);
}

function closeNotifications() {
  gsap.to(notifications, { x: "100%", duration: duration });
  gsap.to(overlay, { backdropFilter: "blur(0px)", duration: duration });
  gsap.to(".menuScreen", { scale: 1, duration: duration });
  notiCardsContainer.style.overflowY = "hidden";
  isNotificationsOpen = false;
  systemNotiToAnnouncTL.reverse();
  gsap.set(".notiCardsContainer", { scrollTo: 0, duration: 1 });
}
document.querySelector(".menuHeader").addEventListener("click", async () => {
  await openNotifications();
});
document.querySelector(".menueBackFromNoti svg").addEventListener("click", closeNotifications);

const stakedAssetsToGameTl = gsap.timeline({
  paused: true,
});
stakedAssetsToGameTl
  .to(".earningsMenuContent", { ease: "power2.out", x: "-100%", duration: 0.25 })
  .to(".GameAnalysisContent", { duration: 0.25, ease: "power2.out", x: 0 }, "<")
  .to(".earningsBtn1", { duration: 0.25, ease: "power2.out", color: "#5f6287" }, "<")
  .to(".earningsBtn2", { duration: 0.25, ease: "power2.out", color: "white" }, "<")
  .to(".notBtnUnderLine2", { duration: 0.25, ease: "power2.out", background: "#ffcc0f" }, "<")
  .to(".notBtnUnderLine1", { duration: 0.25, ease: "power2.out", background: "transparent" }, "<");

const systemNotiToAnnouncTL = gsap.timeline({
  paused: true,
});
systemNotiToAnnouncTL
  .to(".notificationsContent", { ease: "power2.out", x: "-100%", duration: 0.25 })
  .to(".announcementNotificationsContent", { duration: 0.25, ease: "power2.out", x: 0 }, "<")
  .to(".notBtn1", { duration: 0.25, ease: "power2.out", color: "#5f6287" }, "<")
  .to(".notBtn2", { duration: 0.25, ease: "power2.out", color: "white" }, "<")
  .to(".notiBtnActive2", { duration: 0.25, ease: "power2.out", background: "#ffcc0f" }, "<")
  .to(".notiBtnActive1", { duration: 0.25, ease: "power2.out", background: "transparent" }, "<");

//earning menu screen buttons
document.querySelector(".notBtn2").addEventListener("click", async () => {
  gsap.to(".notiSkeletonCont", { autoAlpha: 1, duration: 0.5 });
  systemNotiToAnnouncTL.play();
  gsap.set(".notiCardsContainer", { scrollTo: 0, duration: 1 });
  await fetchAnnouncementNoti();
});
document.querySelector(".notBtn1").addEventListener("click", () => {
  systemNotiToAnnouncTL.reverse();
  gsap.set(".notiCardsContainer", { scrollTo: 0, duration: 1 });
});
document.querySelector(".earningsBtn2").addEventListener("click", () => {
  stakedAssetsToGameTl.play();
});
document.querySelector(".earningsBtn1").addEventListener("click", () => {
  stakedAssetsToGameTl.reverse();
});

document.querySelector(".mainMenuOverlay").addEventListener("click", async () => {
  await closeAndRestMenu();
});
async function closeAndRestMenu() {
  closeNotifications();
  menuContTl.reverse();
  menuManager.resetTokensSelection();
  await menuManager.setupMainMenu();
  resetTokenTransactions();
  //await resetWalletHistory();
  stakedAssetsToGameTl.reverse();
}
document.querySelector(".quickStake").addEventListener("click", () => {
  menuContTl.restart();
  stakeMenuScreen.restart();
});
document.querySelector(".quickEarnings").addEventListener("click", () => {
  menuContTl.restart();
  earningsMenuScreen.restart();
  stakedAssetsToGameTl.reverse();
});
document.querySelector(".quickAnalysis").addEventListener("click", () => {
  menuContTl.restart();
  transactionMenuScreen.restart();
});
document.querySelector(".quickNotifications").addEventListener("click", async () => {
  menuContTl.restart();
  await openNotifications();
});

const TOKEN_SALE_CONTRACT = "0xF6f769D4a36A19aD3a0a9a89cC647eA72057b15a";

const getQuote = async (amount) => {
  const swapParams = {
    USDC: {
      currencyAddress: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      poolFee: "100",
      method: "PERMIT",
      decimals: 6,
      domain: {
        name: "USD Coin (PoS)",
        version: "1",
        salt: "0x0000000000000000000000000000000000000000000000000000000000000089",
        verifyingContract: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      },
    },
    USDT: {
      currencyAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      poolFee: "100",
      method: "APPROVE",
      decimals: 6,
    },
    MATIC: {
      currencyAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      poolFee: "500",
      method: "NATIVE",
      decimals: 18,
      realAddress: "0x0000000000000000000000000000000000000000",
    },
  };
  console.log("Get GPO rate for amount :", amount);
  let currency = "USDC";
  let { currencyAddress, poolFee } = swapParams[currency];
  let amountIn = parseUnits(String(amount), 6);

  const res = await readContract({
    ...GPMAux,
    address: GPMA,
    functionName: "getMaxGPOForUSDC",
    args: [amountIn],
    chainId: 137,
  });
  console.log("res: ", res);

  let FormatedRes = formatEther(res);

  console.log("FormatedRes :", FormatedRes);

  return FormatedRes;
};

export function tokenTransactionFromTokensCont(token) {
  menuContTl.restart();
  transactionMenuScreen.restart();
  menuManager.updateTransactionToken(token);
}

export async function tokenStakeFromTokensCont(token) {
  menuManager.resetTokensSelection();
  await menuManager.setupMainMenu();
  resetTokenTransactions();
  menuContTl.restart();
  stakeMenuScreen.restart();
  menuManager.updateStakeToken(token);
  menuManager.selectedToken = token;
  // menuManager.calculateAndDisplayTotalRewards(token);
}
export function earningsMenuFromTokensCont() {
  menuContTl.restart();
  earningsMenuScreen.restart();
  stakedAssetsToGameTl.reverse();
}

const container = document.querySelector(".mainProgressContainerConnected");
const trigger = document.querySelector(".mainProgressContainerConnected .credit");
const triggerWindowDash = document.querySelector(".mainProgressContainerConnected .credit .creditWindowDash");
const mintBtn = document.querySelector(".mintBtn");
const levelsEmptyspaceConnected = document.querySelector(".mainProgressContainerConnected .levels .emptySpace");

let hammerLevel = new Hammer(trigger, { touchAction: "auto" });
hammerLevel.get("pan").set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 0 });
hammerLevel.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 50, velocity: 0.4 });
hammerLevel.off("panstart panmove panend swipe");
export const toggleHammerEvents = (enable) => {
  if (enable) {
    hammerLevel.on("panstart", panStartHandler);
    hammerLevel.on("panmove", panMoveHandler);
    hammerLevel.on("panend", panEndHandler);
    hammerLevel.on("swipe", swipeHandler);
  } else {
    hammerLevel.off("panstart panmove panend swipe");
  }
};

const CONTAINER_STATES = {
  INITIAL: 61,
  BOTTOM: 87.5,
  TOP: 0,
};
const MIDWAY_TOP_INITIAL = (CONTAINER_STATES.TOP + CONTAINER_STATES.INITIAL) / 2;
const MIDWAY_INITIAL_BOTTOM = (CONTAINER_STATES.INITIAL + CONTAINER_STATES.BOTTOM) / 2;

let currentY = CONTAINER_STATES.INITIAL;
let isSwiping = false;

const killAllTweens = () => {
  const elementsToKill = [
    container,
    mintBtn,
    triggerWindowDash,
    ".levelsOverlay",
    ".headerAndTokens",
    ".mainProgressContainerConnected .levels .emptySpace",
    "body",
    trigger,
  ];
  elementsToKill.forEach((el) => gsap.killTweensOf(el));
};
const moveMintBtn = (newY) => {
  if (newY >= CONTAINER_STATES.INITIAL && newY <= CONTAINER_STATES.BOTTOM) {
    const mintY = (newY - CONTAINER_STATES.INITIAL) * (151 / (CONTAINER_STATES.BOTTOM - CONTAINER_STATES.INITIAL));
    gsap.to(mintBtn, { duration: 0.5, y: `${mintY}%` });
  }
};
export const setContainerState = (targetState, animDuration = 0.2) => {
  let targetY = CONTAINER_STATES[targetState];
  switch (targetState) {
    case "INITIAL":
      gsap.to(container, { duration: animDuration, y: `${targetY}%` });
      gsap.to(".mainProgressContainerConnected .levels .emptySpace", { duration: animDuration, height: "53vh" });
      //gsap.to(".mainProgressContainerConnected .mainProgressBar", { duration: animDuration, autoAlpha: 1 });
      gsap.to(".mainProgressContainerConnected .mainProgressBar", { duration: animDuration, height: "28%" });
      //gsap.to(".mainProgressContainerConnected .mainProgressBar .mainProgressBarActive", { duration: 0, height: 0 });
      gsap.to(".headerAndTokens", { duration: animDuration, scale: 1 });
      gsap.to(".levelsOverlay", { backdropFilter: "blur(0px)", autoAlpha: 0, duration: animDuration });
      moveMintBtn(targetY);
      break;
    case "BOTTOM":
      gsap.to(container, { duration: animDuration, y: `${targetY}%` });
      gsap.to(".mainProgressContainerConnected .levels .emptySpace", { duration: animDuration, height: "53vh" });
      gsap.to(".mainProgressContainerConnected .mainProgressBar", { duration: animDuration, autoAlpha: 1 });
      gsap.to(".headerAndTokens", { duration: animDuration, scale: 1 });
      gsap.to(".levelsOverlay", { backdropFilter: "blur(0px)", autoAlpha: 0, duration: animDuration });
      moveMintBtn(targetY);
      break;
    case "TOP":
      gsap.to(container, { duration: animDuration, y: `${targetY}%` });
      gsap.to(".mainProgressContainerConnected .levels .emptySpace", { duration: animDuration, height: "10vh" });
      //gsap.to(".mainProgressContainerConnected .mainProgressBar .mainProgressBarActive", { duration: 0, height: 0 });
      //gsap.to(".mainProgressContainerConnected .mainProgressBar", { duration: animDuration, autoAlpha: 0 });
      gsap.to(".mainProgressContainerConnected .mainProgressBar", { duration: animDuration, height: "92%" });
      gsap.to(".headerAndTokens", { duration: animDuration, scale: 0.95 });
      gsap.to(".levelsOverlay", { backdropFilter: "blur(5px)", autoAlpha: 1, duration: animDuration });
      break;
  }
  currentY = targetY;
};
const panStartHandler = (e) => {
  killAllTweens();
  isSwiping = false;

  gsap.to(triggerWindowDash, { duration: 0.3, scale: 1.3, background: "white" });
  gsap.set("body", { cursor: "grabbing" });
  gsap.set(trigger, { cursor: "grabbing" });
};
const panMoveHandler = (e) => {
  if (isSwiping) return;

  let deltaY = e.deltaY;
  let newY = currentY + (deltaY / window.innerHeight) * 100;

  if (newY < CONTAINER_STATES.TOP) newY = CONTAINER_STATES.TOP;
  if (newY > CONTAINER_STATES.BOTTOM) newY = CONTAINER_STATES.BOTTOM;

  gsap.to(container, { duration: 0, y: `${newY}%` });
  moveMintBtn(newY);
  if (newY >= CONTAINER_STATES.TOP && newY <= CONTAINER_STATES.INITIAL) {
    const progress = newY / CONTAINER_STATES.INITIAL;
    const scaleValue = 1 - 0.05 * (1 - progress);
    const blurValue = 5 * (1 - progress);
    const alphaValue = progress;
    const heightValue = 28 + (92 - 28) * (1 - progress);
    const EmptySpaceHeightValue = 53 + (10 - 53) * (1 - progress);
    gsap.set(".levelsOverlay", { autoAlpha: 1 });
    gsap.to(".headerAndTokens", { duration: 0, scale: scaleValue });
    gsap.to(".levelsOverlay", { backdropFilter: `blur(${blurValue}px)`, duration: 0 });
    gsap.to(".mainProgressContainerConnected .mainProgressBar", { duration: 0, height: `${heightValue}%` });
    gsap.to(".mainProgressContainerConnected .levels .emptySpace", { duration: 0.2, height: `${EmptySpaceHeightValue}vh` });
  }
};
const panEndHandler = (e) => {
  if (isSwiping) {
    isSwiping = false;
    return;
  }
  let deltaY = e.deltaY;
  let velocityY = e.velocityY;
  let adjustment = (velocityY > 0 ? 1 : -1) * Math.abs(velocityY * 10);
  let finalY = currentY + (deltaY / window.innerHeight) * 100 + adjustment;

  if (currentY === CONTAINER_STATES.INITIAL) {
    if (finalY > MIDWAY_TOP_INITIAL && finalY < MIDWAY_INITIAL_BOTTOM) {
      setContainerState("INITIAL");
    } else if (finalY <= MIDWAY_TOP_INITIAL) {
      setContainerState("TOP");
    } else if (finalY >= MIDWAY_INITIAL_BOTTOM) {
      setContainerState("BOTTOM");
    }
  } else if (currentY === CONTAINER_STATES.BOTTOM) {
    if (finalY < MIDWAY_INITIAL_BOTTOM) {
      setContainerState("INITIAL");
    } else {
      setContainerState("BOTTOM");
    }
  } else if (currentY === CONTAINER_STATES.TOP) {
    if (finalY < MIDWAY_TOP_INITIAL) {
      setContainerState("TOP");
    } else {
      setContainerState("INITIAL");
    }
  }
  gsap.to(triggerWindowDash, { duration: 0.3, scale: 1, background: "#616161" });
  gsap.set("body", { cursor: "unset" });
  gsap.set(trigger, { cursor: "grab" });
  console.log("PANEND: currentY", currentY, "finalY", finalY, "isSwiping", isSwiping);
};
const swipeHandler = (e) => {
  if (Math.abs(e.velocityY) > 0.4) {
    console.log("swipe detected at velocityY :", e.velocityY);
    isSwiping = true;
    if (e.velocityY >= 0) {
      if (currentY === CONTAINER_STATES.INITIAL) {
        setContainerState("BOTTOM");
      } else if (currentY === CONTAINER_STATES.TOP) {
        setContainerState("INITIAL");
      }
    } else {
      if (currentY === CONTAINER_STATES.INITIAL) {
        setContainerState("TOP");
      } else if (currentY === CONTAINER_STATES.BOTTOM) {
        setContainerState("INITIAL");
      }
    }
    gsap.to(triggerWindowDash, { duration: 0.3, scale: 1, background: "#616161" });
    gsap.set("body", { cursor: "unset" });
    gsap.set(trigger, { cursor: "grab" });
  }
};
document.querySelector(".levelsOverlay").addEventListener("click", () => {
  setContainerState("INITIAL");
});
trigger.addEventListener("mousedown", function () {
  gsap.set(trigger, { cursor: "grabbing" });
});
trigger.addEventListener("mouseup", function () {
  gsap.set(trigger, { cursor: "grab" });
});

document.querySelector(".headerAndTokens").addEventListener("click", async function (event) {
  const currentState = await StateManager.getAppState();

  if (event.target === event.currentTarget /* && currentState === "inGame" */) {
    if (currentY === CONTAINER_STATES.INITIAL) {
      setContainerState("BOTTOM");
    } else if (currentY === CONTAINER_STATES.BOTTOM) {
      setContainerState("INITIAL");
    }
  }
});

const toggleLevelsDoupleTapState = () => {
  if (currentY === CONTAINER_STATES.TOP) {
    setContainerState("INITIAL");
  } else {
    setContainerState("TOP");
  }
};
document.querySelector(".mainProgressContainerConnected .credit").addEventListener("dblclick", function (event) {
  toggleLevelsDoupleTapState();
});
let lastTap = 0;
document.querySelector(".mainProgressContainerConnected .credit").addEventListener("touchend", function (event) {
  const currentTime = new Date().getTime();
  const tapLength = currentTime - lastTap;

  if (tapLength < 500 && tapLength > 0) {
    toggleLevelsDoupleTapState();
  }

  lastTap = currentTime;
});
