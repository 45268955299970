import gsap from "gsap";
import moment from "moment";

import StateManager from "./appStates.js";
import MenuManager from "./menu.js";
import { setContainerState } from "./menu.js";
import { setStakedTokens, getStakedTokens } from "./stakedTokens.js";
import { tokenTransactionFromTokensCont, tokenStakeFromTokensCont, earningsMenuFromTokensCont } from "./menu.js";
import { readContract, watchReadContract } from "@wagmi/core";
import GPMines from "./contracts/GPMines.json";
import GPMAux from "./contracts/GPMAux.json";
import { GPM, GPMA } from "./contracts/ContractAddresses.js";

const menuManager = new MenuManager();

let tokens = [];
let levels = [];
let owner = null;
let currentSortOrder = "highest";

export function setCurrentUser(walletAddress) {
  owner = walletAddress;
}
export function getCurrentUser() {
  //console.log("owner :", owner);
  return owner;
}

export async function getTotalPrize(level) {
  if (level < 0) {
    return 0;
  }
  return 10 * Math.pow(2, level);
}

/* export async function getTotalGamePayout() {
  let totalGamePayout;
  try {
    totalGamePayout = await readContract({
      ...GPMines,
      address: GPM,
      functionName: "totalGamePayout",
    });

    if (!totalGamePayout) {
      console.error("Invalid or missing totalGamePayout value.");
      return;
    }

    const creditSpan = document.querySelector(".mainProgressContainerConnected .credit .creditText .creditAmount");
    creditSpan.textContent = "$" + numberWithCommas(totalGamePayout);
    return totalGamePayout;
  } catch (error) {
    console.error("Error preparing contract:", error);
  }
} */
export async function getTotalGamePayout() {
  let totalGamePayout;
  try {
    const config = {
      ...GPMines,
      address: GPM,
      functionName: "totalGamePayout",
      listenToBlock: true,
      chainId: 137,
    };
    totalGamePayout = await readContract(config);

    if (!totalGamePayout) {
      console.error("Invalid or missing totalGamePayout value.");
      return;
    }

    const creditSpan = document.querySelector(".mainProgressContainerConnected .credit .creditText .creditAmount");
    creditSpan.textContent = "$" + numberWithCommas(totalGamePayout);

    const unwatch = watchReadContract(config, async (newData) => {
      // console.log("now watching totalGamePayout");
      if (newData !== totalGamePayout) {
        console.log("New Watch totalGamePayout :", newData);

        const timeline = gsap.timeline();
        timeline
          .to(".mainProgressContainerConnected .credit .creditText ", { color: "#02fe02", duration: 0.5 })
          .to(creditSpan, {
            textContent: "$" + numberWithCommas(newData),
            snap: { textContent: 1 },
            duration: 1,
            ease: "power2.out",
            onUpdate: () => {
              creditSpan.textContent = "$" + numberWithCommas(newData);
            },
          })
          .to(".mainProgressContainerConnected .credit .creditText ", { color: "white", duration: 0.5 });

        totalGamePayout = newData;
        // unwatch();
        return;
      }
    });

    return totalGamePayout;
  } catch (error) {
    console.error("Error preparing contract:", error);
  }
}
export class Token {
  constructor(tokenId, currentLevel, lastStakeLevel, cumulativeWinnings, tokenType) {
    this.tokenId = tokenId;
    this.currentLevel = currentLevel;
    this.lastStakeLevel = lastStakeLevel;
    this.cumulativeWinnings = cumulativeWinnings;
    this.tokenType = tokenType;
  }
}

export class Level {
  constructor(levelNo, countPlayers, winnerCount, levelPrize) {
    this.levelNo = levelNo;
    this.countPlayers = countPlayers;
    this.winnerCount = winnerCount;
    this.levelPrize = levelPrize;
  }
}

export const getTokens = async () => {
  return [...tokens];
};

/* export async function setTokens() {
  owner = getCurrentUser();
  // Step 1: Get the total NFTs the user is holdings
  const totalNFTs = await readContract({
    ...GPMines,
    address: GPM,
    functionName: "balanceOf",
    args: [owner],
  });
  console.log("totalNFTs :", totalNFTs);

  let newTokens = [];

  // Step 2: Get token ID for each index
  for (let i = 0; i < totalNFTs; i++) {
    const tokenID = await readContract({
      ...GPMines,
      address: GPM,
      functionName: "tokenOfOwnerByIndex",
      args: [owner, i],
    });
    console.log(`Token ID: ${tokenID}`);
    // Step 3: Get MiningNFT struct (metadata)
    const metadata = await readContract({
      ...GPMines,
      address: GPM,
      functionName: "metadata",
      args: [tokenID],
    });
    
    console.log("metadata :", metadata);
    // Get the winnings for the current and the last staked levels
    //const winningsCurrentLevel = await getTotalPrize(metadata[2]);
    // console.log("winningsCurrentLevel :", winningsCurrentLevel);
    //const winningsLastStakeLevel = await getTotalPrize(metadata[3]);
    // console.log("winningsLastStakeLevel :", winningsLastStakeLevel);
    const winningsCurrentLevel = await getLevelPrize(metadata[2]);
    console.log("winningsCurrentLevel :", winningsCurrentLevel);
    const winningsLastStakeLevel = await getLevelPrize(metadata[3]);
    console.log("winningsLastStakeLevel :", winningsLastStakeLevel);

    // Calculate cumulative winnings
    const cumulativeWinnings = Number(winningsCurrentLevel) - Number(winningsLastStakeLevel);

    // Determine the token type
    let tokenType;
    let referralLink;
    if (Number(metadata[2]) === -2) { //if currentLevel is -2
      tokenType = "Referrer";
      referralLink = `${window.location.origin}/#${metadata[0]}`; // Constructing the referral link based on token ID
      const watchConfig = {
        ...GPMines,
        address: GPM,
        functionName: "metadata",
        args: [tokenID],
      };
      let currentState = await StateManager.getAppState();
      console.log("Watching referrer Token ID " + tokenID);
      const unwatch = watchReadContract(watchConfig, async (newMetadata) => {
        console.log("newMetadata :", newMetadata);
        // Check if the newMetadata is different from the old metadata
        // This is a simple comparison; you might need to adjust it based on the structure of metadata

        if (JSON.stringify(newMetadata) !== JSON.stringify(metadata) && currentState !== "walletNotConnected") {
          unwatch(); // Unsubscribe from changes
          //setTimeout(setTokens, 1000); // Recall the setTokens function after 1 second
          await setTokens();
          await StateManager.notifyStateChange();
          return;
        }
      });
    } else if (Number(metadata[2]) >= -1) { //if currentLevel equals or greater than -1
      tokenType = "Independent";
      referralLink = null; // Setting referralLink to null for Independent token type
    } else {
      // Handle case where currentLevel is not -2, -1 or positive
      tokenType = "Unknown";
      referralLink = null; // Optionally set referralLink to null for Unknown token type
    }

    // Create a token object using the Token class
    const token = new Token(
      metadata[0], // id
      Number(metadata[2]), // currentLevel
      Number(metadata[3]), // lastStakeLevel
      cumulativeWinnings,
      Number(metadata[4]), // referralCount
      referralLink,
      tokenType
    );

    // Push the token into newTokens array
    newTokens.push(token);
  }

  // Update the tokens
  tokens = [...newTokens];
  console.log("tokens :", tokens);
  await initRender();
} */

let debounceTimer = null;
let debounceInProgress = false;
export async function setTokens() {
  if (debounceInProgress) {
    return;
  }
  debounceInProgress = true;

  const owner = getCurrentUser();
  const MAX_INT8 = Math.pow(2, 8);
  const hexToUInt = (hex) => parseInt(hex, 16);
  const hexToInt = (hex) => {
    let num = hexToUInt(hex);
    if (num > MAX_INT8 / 2 - 1) num -= MAX_INT8;
    return num;
  };
  const parseTokensMetadata = (data) => {
    if (!data.startsWith("0x")) return null;
    let tokens = [];
    data = data.slice(2);
    let noTokens = data.length / 68;
    for (var i = 0; i < noTokens; i++) {
      let cur = data.slice(0, 68);
      let token = {
        id: BigInt(`0x${cur.slice(0, 64)}`),
        currentLevel: hexToInt(cur.slice(64, 66)),
        lastStakeLevel: hexToInt(cur.slice(66, 68)),
        // referralCount: hexToUInt(cur.slice(68, 70)),
      };
      tokens.push(token);
      data = data.slice(68);
    }
    return tokens;
  };
  let account = owner;
  const config = {
    ...GPMines,
    address: GPM,
    functionName: "tokensMetadata",
    args: [account],
    listenToBlock: true,
    chainId: 137,
  };
  let packedTokens;
  const gpmGetAllTokens = async () => {
    packedTokens = await readContract(config);

    return parseTokensMetadata(packedTokens);
  };
  const parsedTokens = await gpmGetAllTokens();

  let newTokens = [];
  for (let tokenData of parsedTokens) {
    const winningsCurrentLevel = await getTotalPrize(tokenData.currentLevel);
    const winningsLastStakeLevel = await getTotalPrize(tokenData.lastStakeLevel);

    const cumulativeWinnings = Number(winningsCurrentLevel) - Number(winningsLastStakeLevel);

    let tokenType;
    //  let referralLink;
    if (tokenData.currentLevel === -2) {
      tokenType = "Referrer";
      // referralLink = `${window.location.origin}/#${tokenData.id}`;
    } else if (tokenData.currentLevel >= -1) {
      tokenType = "Independent";
      // referralLink = null;
    } else {
      tokenType = "Unknown";
      // referralLink = null;
    }

    const token = new Token(
      tokenData.id,
      tokenData.currentLevel,
      tokenData.lastStakeLevel,
      cumulativeWinnings,
      // tokenData.referralCount,
      // referralLink,
      tokenType
    );

    newTokens.push(token);
  }

  tokens = [...newTokens];
  console.log("tokens :", tokens);
  await setStakedTokens();
  await getTotalReferralsBC();
  await initRender();

  const unwatch = watchReadContract(config, async (newData) => {
    // console.log("now watching packedTokens");
    if (newData !== packedTokens) {
      console.log("New Watch MetaData :", newData);
      const notificationsIconCount = document.querySelectorAll(".notificationsIconCount");
      notificationsIconCount.forEach((el) => {
        el.style.display = "block";
      });
      unwatch();
      await setTokens();
      await StateManager.notifyStateChange();
      await setLevels();
      return;
    }
  });

  debounceTimer = setTimeout(() => {
    debounceInProgress = false;
  }, 1000);
}

/* export async function findTokenByType(tokenType = null, returnToken = false) {
  try {
    const tokens = await getTokens();

    let tokenFound = false;

    if (tokenType) {
      tokenFound = tokens.some((token) => token.tokenType === tokenType);
    } else {
      tokenFound = tokens.length > 0;
    }

    if (returnToken && tokenFound) {
      return tokens.find((token) => token.tokenType === tokenType);
    }

    return tokenFound;
  } catch (error) {
    console.error("An error occurred in findTokenByType:", error);
  }
} */
export async function findTokenByType(tokenType = null, returnToken = false) {
  try {
    const tokens = await getTokens();

    if (tokenType) {
      const matchingTokens = tokens.filter((token) => token.tokenType === tokenType);
      if (returnToken) {
        return matchingTokens;
      }
      return matchingTokens.length > 0;
    } else {
      if (returnToken) {
        return tokens;
      }
      return tokens.length > 0;
    }
  } catch (error) {
    console.error("An error occurred in findTokenByType:", error);
    return [];
  }
}

export const getProfitableTokens = async () => {
  const allTokens = await getTokens();
  return allTokens.filter((token) => token.cumulativeWinnings > 0);
};
/* export const getTokenDataById = async (tokenId, dataProperty) => {
  const allTokens = await getTokens();

  const token = allTokens.find((token) => token.tokenId === tokenId);

  if (!token) {
    console.error(`Token with ID ${tokenId} not found.`);
    return null;
  }

  if (!(dataProperty in token)) {
    console.error(`Property ${dataProperty} doesn't exist in the Token.`);
    return null;
  }

  return token[dataProperty];
}; */

export const getLevels = async () => {
  return [...levels];
};
export const getLevelPrize = async (levelNo) => {
  const levels = await getLevels();
  const level = levels.find((l) => l.levelNo === levelNo);
  return level ? level.levelPrize : 0;
};

export async function setLevels() {
  gsap.to(".levelSkeletonCont", { autoAlpha: 1, duration: 0.5 });
  const maxLevel = await readContract({
    ...GPMines,
    address: GPM,
    functionName: "maxLevel",
  });

  const countPlayersArray = await readContract({
    ...GPMines,
    address: GPM,
    functionName: "getCountPlayers",
  });

  let newLevels = [];

  for (let levelNo = 0; levelNo <= maxLevel; levelNo++) {
    const levelPrize = await getTotalPrize(levelNo);

    const winnerCount = await readContract({
      ...GPMines,
      address: GPM,
      functionName: "winnerCount",
      args: [levelNo],
    });
    const countPlayers = countPlayersArray[levelNo];

    const level = new Level(levelNo, countPlayers, winnerCount, levelPrize);

    newLevels.push(level);
  }

  levels = [...newLevels];
  console.log("levels :", levels);
  await updateLevels();
  gsap.to(".levelSkeletonCont", { autoAlpha: 0, duration: 1, delay: 0.5 });
}

function numberWithCommas(x) {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export async function updateNFTCount() {
  const tokens = await getTokens();
  const nftCountHeader = document.querySelector(".nftCountHeader");
  nftCountHeader.textContent = tokens.length + " NFTs";
}
export async function updateCredits() {
  const tokens = await getTokens();
  const totalCredits = tokens.reduce((total, token) => total + Number(token.cumulativeWinnings), Number(0));

  const stakedTokens = await getStakedTokens();
  const totalLpAmount = stakedTokens.reduce((acc, token) => acc + Number(token.lpAmount), 0);
  const totalCumulativeEarnings = stakedTokens.reduce((acc, token) => acc + token.cumulativeEarnings, 0);
  const totalCurrentValue = totalLpAmount + totalCumulativeEarnings;

  //total winnings modal
  const totalWinningsValue = totalCredits + totalCurrentValue;
  const totalWinningsHeaderModal = document.querySelector(".totalWinningsHeaderModal .tokensTotalAmount");
  totalWinningsHeaderModal.textContent = `$${numberWithCommas(totalWinningsValue.toFixed(2))}`;

  const totalNFTsTextModal = document.querySelector(".totalNFTsModal .tokensTotalAmount");
  totalNFTsTextModal.textContent = `$${numberWithCommas(totalCredits.toFixed(2))}`;

  const totalStakedAssetsTextModal = document.querySelector(".totalStakedAssetsModal .tokensTotalAmount");
  totalStakedAssetsTextModal.textContent = `$${numberWithCommas(totalCurrentValue.toFixed(2))}`;

  const totalStakedPrincipleModal = document.querySelector(".totalStakedPrincipleModal .tokensTotalAmount");
  totalStakedPrincipleModal.textContent = `$${numberWithCommas(totalLpAmount.toFixed(2))}`;

  const totalStakedEarningsModal = document.querySelector(".totalStakedEarningsModal .tokensTotalAmount");
  totalStakedEarningsModal.textContent = `$${numberWithCommas(totalCumulativeEarnings.toFixed(2))}`;

  // Calculate percentages
  const percentageNFTs = (totalCredits / totalWinningsValue) * 100;
  const percentageStakedAssets = (totalCurrentValue / totalWinningsValue) * 100;
  const percentageStakedPrinciple = (totalLpAmount / totalCurrentValue) * 100;
  const percentageStakedEarings = (totalCumulativeEarnings / totalCurrentValue) * 100;

  const totalNFTsTextPercentageModal = document.querySelector(".totalNFTsModal .tokensTotalAmountPercentage");
  totalNFTsTextPercentageModal.textContent = `%${percentageNFTs.toFixed(2)}`;
  const totalStakedAssetsPercentageModal = document.querySelector(".totalStakedAssetsModal .tokensTotalAmountPercentage");
  totalStakedAssetsPercentageModal.textContent = `%${percentageStakedAssets.toFixed(2)}`;

  const totalStakedPrinciplePercentageModal = document.querySelector(".totalStakedPrincipleModal .tokensTotalAmountPercentage");
  totalStakedPrinciplePercentageModal.textContent = `%${percentageStakedPrinciple.toFixed(2)}`;
  const totalStakedEarningsPercentageModal = document.querySelector(".totalStakedEarningsModal .tokensTotalAmountPercentage");
  totalStakedEarningsPercentageModal.textContent = `%${percentageStakedEarings.toFixed(2)}`;

  //total winnings header
  const totalWinnings = document.querySelector(".totalWinnings .tokensTotalAmount");
  totalWinnings.textContent = `$${numberWithCommas(totalWinningsValue.toFixed(2))}`;
}

//Generate levels HTML
const levelsContainer = document.querySelector(".mainProgressContainerConnected .levels");
const progressBar = document.querySelector(".mainProgressContainerConnected .mainProgressBar .mainProgressBarActive");
levelsContainer.addEventListener("scroll", function () {
  // Calculate 90% of the maximum scroll height minus visible height
  const maxScroll = (levelsContainer.scrollHeight - levelsContainer.clientHeight) * 1.3;

  const scrollPosition = levelsContainer.scrollTop;

  let scrollPercentage = (scrollPosition / maxScroll) * 100;

  // Cap the scroll percentage at 100%
  if (scrollPercentage > 100) scrollPercentage = 100;

  // Calculate the final height, 75% of the max instead of 100%
  const finalHeightPercentage = 75 * (scrollPercentage / 100);

  //progressBar.style.height = `${scrollPercentage}%`;
  const initialColor = { r: 255, g: 255, b: 255 };
  const targetColor = { r: 52, g: 235, b: 88 };
  const newColor = {
    r: initialColor.r + (targetColor.r - initialColor.r) * (scrollPercentage / 100),
    g: initialColor.g + (targetColor.g - initialColor.g) * (scrollPercentage / 100),
    b: initialColor.b + (targetColor.b - initialColor.b) * (scrollPercentage / 100),
  };
  gsap.to(progressBar, {
    duration: 0.5,
    height: `${finalHeightPercentage}%`,
    ease: "power2.out",
  });
});
export async function updateLevels() {
  levelsContainer.innerHTML = "";
  const levels = await getLevels();
  const tokens = await getTokens();

  levels.forEach((level) => {
    const levelDiv = document.createElement("div");
    levelDiv.className = `level${level.levelNo} level`;

    const levelProgressDiv = document.createElement("div");
    levelProgressDiv.className = "levelProgress";
    levelDiv.appendChild(levelProgressDiv);

    const levelInfoDiv = document.createElement("div");
    levelInfoDiv.className = "levelInfo";
    levelProgressDiv.appendChild(levelInfoDiv);

    const levelNoInfoDiv = document.createElement("div");
    levelNoInfoDiv.className = "levelNoInfo";
    levelInfoDiv.appendChild(levelNoInfoDiv);

    const levelIndicatorDiv = document.createElement("div");
    levelIndicatorDiv.className = "Levelindecator";
    levelNoInfoDiv.appendChild(levelIndicatorDiv);

    const levelNoDiv = document.createElement("div");
    levelNoDiv.className = "LevelNo";
    levelNoDiv.textContent = "LEVEL " + level.levelNo;
    levelNoInfoDiv.appendChild(levelNoDiv);

    const countPlayersInfoDiv = document.createElement("div");
    countPlayersInfoDiv.className = "CountPlayersInfo";
    levelInfoDiv.appendChild(countPlayersInfoDiv);

    const countPlayersDiv = document.createElement("div");
    countPlayersDiv.className = "CountPlayers";
    countPlayersDiv.textContent = numberWithCommas(level.countPlayers);
    countPlayersInfoDiv.appendChild(countPlayersDiv);

    const countPlayersTextDiv = document.createElement("div");
    countPlayersTextDiv.className = "CountPlayersText";
    countPlayersTextDiv.textContent = "Players";
    countPlayersInfoDiv.appendChild(countPlayersTextDiv);

    const levelPrizeDiv = document.createElement("div");
    levelPrizeDiv.className = "levelPrize";
    levelPrizeDiv.textContent = "$" + numberWithCommas(level.levelPrize);
    levelDiv.appendChild(levelPrizeDiv);

    if (tokens.some((token) => token.currentLevel === level.levelNo)) {
      levelIndicatorDiv.style.backgroundColor = "#02fe02";
      levelIndicatorDiv.style.boxShadow = "0 1px 4px 1px #26b562,inset 0 0 0 1px #ffffff1a";
    }

    levelsContainer.appendChild(levelDiv);
  });
  const levelSpaceDiv = document.createElement("div");
  levelSpaceDiv.className = "emptySpace";
  levelsContainer.appendChild(levelSpaceDiv);
}

const tokensContainer = document.querySelector(".tokens");
let fragment;
export async function updateTokens(sortOrder = "oldest") {
  fragment = document.createDocumentFragment();
  /* const referralToken = await findTokenByType("Referrer", true);
  if (referralToken) {
    renderToken(referralToken);
  } */
  const referralTokens = await findTokenByType("Referrer", true);
  referralTokens.sort((a, b) => Number(b.tokenId) - Number(a.tokenId));

  let isFirstReferrer = true;
  if (referralTokens.length > 0) {
    referralTokens.forEach((token) => {
      renderToken(token, isFirstReferrer);
      isFirstReferrer = false;
    });
  }
  const tokens = await getTokens();
  let otherTokens = tokens.filter((token) => token.tokenType !== "Referrer");
  switch (sortOrder) {
    case "newest":
      otherTokens.sort((a, b) => Number(b.tokenId) - Number(a.tokenId));
      break;
    case "highest":
      otherTokens.sort((a, b) => Number(b.currentLevel) - Number(a.currentLevel));
      break;
    case "oldest":
    default:
      otherTokens.sort((a, b) => Number(a.tokenId) - Number(b.tokenId));
      break;
  }
  otherTokens.forEach((token) => {
    renderToken(token);
  });
  tokensContainer.innerHTML = "";
  const tokenSpaceDiv = document.createElement("div");
  tokenSpaceDiv.className = " emptySpace emptySpaceSmaller";
  tokensContainer.appendChild(tokenSpaceDiv);
  tokensContainer.appendChild(fragment);
  const tokenSpaceDiv2 = document.createElement("div");
  tokenSpaceDiv2.className = "emptySpace";
  tokensContainer.appendChild(tokenSpaceDiv2);
}

function renderToken(token, isFirstReferrer) {
  // Create token div
  const tokenDiv = document.createElement("div");
  tokenDiv.className = "token";

  const levelProgressDiv = document.createElement("div");
  levelProgressDiv.className = "levelProgress";
  tokenDiv.appendChild(levelProgressDiv);

  const levelInfoDiv = document.createElement("div");
  levelInfoDiv.className = "levelInfo";
  levelProgressDiv.appendChild(levelInfoDiv);

  const levelNoInfoDiv = document.createElement("div");
  levelNoInfoDiv.className = "levelNoInfo";
  levelInfoDiv.appendChild(levelNoInfoDiv);

  const tokenIndicatorDiv = document.createElement("div");
  tokenIndicatorDiv.className = "tokenIndecator";
  levelNoInfoDiv.appendChild(tokenIndicatorDiv);

  const tokenIdDiv = document.createElement("div");
  tokenIdDiv.className = "tokenId";
  tokenIdDiv.textContent = "#" + token.tokenId;
  levelNoInfoDiv.appendChild(tokenIdDiv);

  if (token.lastStakeLevel > 0) {
    tokenIdDiv.textContent = "#" + token.tokenId + "🔥";

    const lastStakedInfoDiv = document.createElement("div");
    lastStakedInfoDiv.className = "CountPlayersInfo countstakeinfo";
    levelInfoDiv.appendChild(lastStakedInfoDiv);

    const lastStakedDiv = document.createElement("div");
    lastStakedDiv.className = "CountPlayers";
    lastStakedInfoDiv.appendChild(lastStakedDiv);

    const lastStakedTextDiv = document.createElement("div");
    lastStakedTextDiv.className = "CountPlayersText";
    lastStakedInfoDiv.appendChild(lastStakedTextDiv);
    lastStakedDiv.textContent = token.lastStakeLevel;
    lastStakedTextDiv.textContent = "staked";
    lastStakedInfoDiv.onclick = () => {
      earningsMenuFromTokensCont();
    };
  }

  const countPlayersInfoDiv = document.createElement("div");
  countPlayersInfoDiv.className = "CountPlayersInfo";
  levelInfoDiv.appendChild(countPlayersInfoDiv);

  const countPlayersDiv = document.createElement("div");
  countPlayersDiv.className = "CountPlayers";
  countPlayersInfoDiv.appendChild(countPlayersDiv);

  const countPlayersTextDiv = document.createElement("div");
  countPlayersTextDiv.className = "CountPlayersText";
  countPlayersInfoDiv.appendChild(countPlayersTextDiv);

  const levelPrizeDiv = document.createElement("div");
  levelPrizeDiv.className = "levelPrize";
  tokenDiv.appendChild(levelPrizeDiv);

  const levelPrizeAmountDiv = document.createElement("div");
  levelPrizeAmountDiv.className = "levelPrizeAmount";
  levelPrizeDiv.appendChild(levelPrizeAmountDiv);

  const levelPrizetextDiv = document.createElement("div");
  levelPrizetextDiv.className = "levelPrizetext";
  levelPrizeDiv.appendChild(levelPrizetextDiv);

  if (token.tokenType === "Referrer") {
    // levelPrizetextDiv.className = "levelPrizetext copyLinkText";
    levelPrizeDiv.className = "levelPrize levelPrizeNone";

    if (isFirstReferrer) {
      const totalReferrals = getTotalReferrals();
      const remainingReferrals = 3 - totalReferrals;
      countPlayersDiv.textContent = remainingReferrals;
    } else {
      // For the rest of the referrer tokens, set remainingReferrals to 3
      countPlayersDiv.textContent = 3;
    }
    countPlayersTextDiv.textContent = "Remaining \n Referrals";
    // levelPrizetextDiv.textContent = "Share \n Link";
    // const title = "Stake your claim";
    // const text = "Ready for the rush of the GoldPesa Mine? join the adventure";
    // const url = token.referralLink;
    // console.log("token.referralLink :", token.referralLink);

    /* levelPrizetextDiv.addEventListener("click", () => {
      if (navigator.share !== undefined) {
        navigator
          .share({
            title,
            text,
            url,
          })
          .then(() => {
            console.log("Shared!");
            copyLinkTimeline.restart();
          })
          .catch((err) => console.error(err));
      } else {
        navigator.clipboard.writeText(token.referralLink);
        copyLinkTimeline.restart();
      }
    }); */
    countPlayersInfoDiv.onclick = async () => {
      await initReferralCountCont();
    };

    tokenIndicatorDiv.style.backgroundColor = "#d20000";
    tokenIndicatorDiv.style.boxShadow = "0 1px 4px 1px #d20000,inset 0 0 0 1px #ffffff1a";
  } else {
    countPlayersDiv.textContent = token.currentLevel;
    countPlayersTextDiv.textContent = "level";
    levelPrizeAmountDiv.textContent = numberWithCommas(token.cumulativeWinnings);
    levelPrizetextDiv.textContent = "USD";
    levelPrizeDiv.onclick = () => {
      if (token.cumulativeWinnings > 0) {
        tokenStakeFromTokensCont(token);
      }
    };
    countPlayersInfoDiv.onclick = () => {
      setContainerState("INITIAL");
      let levelClassName = token.currentLevel * 72;
      const targetElement = ".level" + token.currentLevel;

      gsap.to(targetElement, { scale: 1.02, background: "rgba(4, 6, 13, 1)", duration: 0.2 });
      gsap.to(".mainProgressContainerConnected .levels", { scrollTo: levelClassName, duration: 0.2 });
      gsap.to(targetElement, { scale: 1, background: "rgba(4, 6, 13, 0.64)", delay: 0.4, duration: 0.2 });
    };
  }
  levelNoInfoDiv.onclick = () => {
    tokenTransactionFromTokensCont(token);
  };

  fragment.appendChild(tokenDiv);
}

const copyLinkTimeline = gsap.timeline({ paused: true });
copyLinkTimeline.to(".linkCopied", { autoAlpha: 1, duration: 0.5 }).to(".linkCopied", { autoAlpha: 0, duration: 0.5 }, ">2");

let tokenSortingText = document.querySelector(".tokenSortingText");

//sorting container timeline
let sortingContTl = gsap.timeline({ paused: true });
sortingContTl
  .to(".sorting-container", { duration: 0.25, ease: "power2.out", y: 0 })
  .to(".overlayFull", { duration: 0.25, ease: "power2.out", autoAlpha: 1 }, "<")
  .to(".tokenSortingArrow svg", { duration: 0.25, ease: "power2.out", rotation: 180 }, "<");

document.querySelector(".tokenSorting").addEventListener("click", function (event) {
  event.stopPropagation();
  sortingContTl.play();

  window.addEventListener("click", handleWindowClick);
});
function handleWindowClick(e) {
  if (!document.querySelector(".sorting-container").contains(e.target)) {
    sortingContTl.reverse();

    window.removeEventListener("click", handleWindowClick);
  }
}
document.getElementById("sort-oldest").addEventListener("change", () => {
  currentSortOrder = "oldest";
  updateTokens(currentSortOrder);
  sortingContTl.reverse();
  tokenSortingText.innerHTML = `Oldest`;
  window.removeEventListener("click", handleWindowClick);
});

document.getElementById("sort-newest").addEventListener("change", () => {
  currentSortOrder = "newest";
  updateTokens(currentSortOrder);
  sortingContTl.reverse();
  tokenSortingText.innerHTML = `Most Recent`;
  window.removeEventListener("click", handleWindowClick);
});

document.getElementById("sort-highest").addEventListener("change", () => {
  currentSortOrder = "highest";
  updateTokens(currentSortOrder);
  sortingContTl.reverse();
  tokenSortingText.innerHTML = `Highest Earnings`;
  window.removeEventListener("click", handleWindowClick);
});

window.addEventListener("DOMContentLoaded", (event) => {
  const radios = document.querySelectorAll('.sort-option input[type="radio"]');

  function updateBgColor() {
    radios.forEach((radio) => {
      const sortOption = radio.closest(".sort-option");
      if (radio.checked) {
        sortOption.style.background = "rgb(20 22 31)";
        sortOption.style.borderRadius = "10px";
      } else {
        sortOption.style.background = "";
        sortOption.style.borderRadius = "";
      }
    });
  }

  updateBgColor();

  radios.forEach((radio) => {
    radio.addEventListener("change", updateBgColor);
  });
});

export async function initRender() {
  //await initDb();
  await updateTokens(currentSortOrder);
  await updateCredits();
  await updateNFTCount();
  await getTotalGamePayout();
  await menuManager.setupMainMenu();
  await initializeCounter();
}

let totalWinningsContTl = gsap.timeline({ paused: true });
totalWinningsContTl
  .to(".totalWinnings-container", { duration: 0.25, ease: "power2.out", y: 0 })
  .to(".totalWinningsOverlayFull", { duration: 0.25, ease: "power2.out", autoAlpha: 1 }, "<");

document.querySelector(".totalWinningsOverlayFull").addEventListener("click", function (event) {
  totalWinningsContTl.reverse();
});
document.querySelector(".totalWinningsHeader").addEventListener("click", function (event) {
  totalWinningsContTl.play();
});

// total referrals
let totalReferrals = null;
export function setTotalReferrals(total) {
  totalReferrals = total;
}
export function getTotalReferrals() {
  // console.log("totalReferrals :", totalReferrals);
  return totalReferrals;
}
async function getTotalReferralsBC() {
  let totalReferrals;
  const owner = getCurrentUser();
  // console.log("owner :", owner);

  try {
    const config = {
      ...GPMines,
      address: GPM,
      functionName: "nbReferrees",
      args: [owner],
      listenToBlock: true,
      chainId: 137,
    };
    totalReferrals = await readContract(config);
    console.log("totalReferrals :", totalReferrals);
    let totalReferralsToNum = Number(totalReferrals);
    const tokensTotalReferrals = document.querySelector(".totalReferralsHeader .tokensTotalReferrals");
    tokensTotalReferrals.textContent = totalReferralsToNum;

    setTotalReferrals(totalReferralsToNum);
    await initReferralCountCont();
    await StateManager.notifyStateChange();
    console.log("totalReferralssssss :", totalReferralsToNum);
    const unwatch = watchReadContract(config, async (newData) => {
      // console.log("now watching totalReferrals");
      if (newData !== totalReferrals) {
        console.log("New Watch totalReferrals :", newData);
        const notificationsIconCount = document.querySelectorAll(".notificationsIconCount");
        notificationsIconCount.forEach((el) => {
          el.style.display = "block";
        });
        unwatch();

        await getTotalReferralsBC();
        // await StateManager.notifyStateChange();
        await setTokens();
        await setLevels();
        return;
      }
    });
    return totalReferralsToNum;
  } catch (error) {
    console.error("Error preparing contract:", error);
  }
}

function animateReferralCount(referralCount) {
  const animationProps = {
    scale: 1.5,
    repeat: 2,
    yoyo: true,
    background: "#02fe02",
    boxShadow: "0 1px 4px 0px #26b562,inset 0 0 0 1px #ffffff1a",
    duration: 0.5,
  };
  const nextProps = {
    scale: 1.5,
    background: "#ffcc0f",
    boxShadow: "0 1px 4px 1px #ffcc0f,inset 0 0 0 1px #ffffff1a",
    repeat: -1,
    yoyo: true,
    duration: 1,
  };
  const resetProps = { scale: 1, background: "#ffffff", boxShadow: "0 1px 4px 1px #ffffff00,inset 0 0 0 1px #ffffff1a", duration: 1 }; // reset to initial values

  gsap.set(".ExploreNav2 .step3-2 .ExploreNav-diamond2 .innerDiamond", resetProps);
  gsap.killTweensOf(".ExploreNav2 .step3-2 .ExploreNav-diamond2 .innerDiamond");
  gsap.to(
    ".ExploreNav2 .step3-2 .ExploreNav-diamond2 .innerDiamond",
    referralCount >= 1 ? animationProps : referralCount === 0 ? nextProps : resetProps
  );

  gsap.set(".ExploreNav2 .step2 .ExploreNav-diamond2 .innerDiamond", resetProps);
  gsap.killTweensOf(".ExploreNav2 .step2 .ExploreNav-diamond2 .innerDiamond");
  gsap.to(
    ".ExploreNav2 .step2 .ExploreNav-diamond2 .innerDiamond",
    referralCount >= 2 ? animationProps : referralCount === 1 ? nextProps : resetProps
  );

  gsap.set(".ExploreNav2 .step1 .ExploreNav-diamond2 .innerDiamond", resetProps);
  gsap.killTweensOf(".ExploreNav2 .step1 .ExploreNav-diamond2 .innerDiamond");
  gsap.to(
    ".ExploreNav2 .step1 .ExploreNav-diamond2 .innerDiamond",
    referralCount >= 3 ? animationProps : referralCount === 2 ? nextProps : resetProps
  );
}

export async function initReferralCountCont() {
  // const referrerToken = await findTokenByType("Referrer", true);
  //console.log("referrerToken :", referrerToken);
  // if (referrerToken) {
  let totalReferrals = getTotalReferrals();
  animateReferralCount(totalReferrals);
  referralCountContTimeline.play();
  // } else {
  //   referralCountContTimeline.reverse();
  // }
}

const referralCountContTimeline = gsap.timeline({ paused: true });
referralCountContTimeline
  .to(".StickyOverlay2", { x: 0, duration: 0.5 })
  .to(".tokens", { paddingTop: "13%", "--maskImageTrans": "10%", duration: 0.5 }, "<");

//////// counter //TODO remove after launch
async function getCounterEndDate() {
  let counterEndDate;
  // const owner = getCurrentUser();
  // console.log("owner :", owner);

  try {
    const config = {
      ...GPMAux,
      address: GPMA,
      functionName: "launchDate",
      // args: [owner],
      listenToBlock: true,
      chainId: 137,
    };
    counterEndDate = await readContract(config);
    console.log("counterEndDate :", counterEndDate);
    // let totalReferralsToNum = Number(totalReferrals);
    // const tokensTotalReferrals = document.querySelector(".totalReferralsHeader .tokensTotalReferrals");
    // tokensTotalReferrals.textContent = totalReferralsToNum;

    // setTotalReferrals(totalReferralsToNum);
    // await initReferralCountCont();
    // await StateManager.notifyStateChange();
    // console.log("totalReferralssssss :", totalReferralsToNum);
    /* const unwatch = watchReadContract(config, async (newData) => {
      // console.log("now watching totalReferrals");
      if (newData !== totalReferrals) {
        console.log("New Watch totalReferrals :", newData);
        const notificationsIconCount = document.querySelectorAll(".notificationsIconCount");
        notificationsIconCount.forEach((el) => {
          el.style.display = "block";
        });
        unwatch();

        await getTotalReferralsBC();
        // await StateManager.notifyStateChange();
        await setTokens();
        await setLevels();
        return;
      }
    }); */
    return counterEndDate;
  } catch (error) {
    console.error("Error preparing contract:", error);
  }
}
async function initializeCounter() {
  try {
    // Hide mint button initially
    document.querySelector(".mintBtn").style.display = "none";
    const counterEndDate = await getCounterEndDate();
    // Convert the BigInt value to a number
    const counterEndDateNumber = Number(counterEndDate);

    // Calculate the future date using the converted value
    const futureDate = moment.unix(counterEndDateNumber);
    // let futureDate = moment().add(1, "minutes");

    console.log("futureDate :", futureDate);

    // Set initial date and end time display
    document.querySelector(".date-display").textContent = futureDate.format("ddd DD MMM");
    document.querySelector(".end-time-display").textContent = futureDate.format("h:mm A");

    const halfCirclePath = document.querySelector(".half-circle-path");
    const timeDisplay = document.querySelector(".counterDown-display");

    // half circle path animation
    const pathLength = halfCirclePath.getTotalLength();
    halfCirclePath.style.strokeDasharray = 2 * pathLength;
    halfCirclePath.style.strokeDashoffset = pathLength;

    function updateTimeDisplay(timeLeft) {
      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      const seconds = timeLeft % 60;

      timeDisplay.textContent = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }

    function updateDisplays() {
      const now = moment();
      const duration = moment.duration(futureDate.diff(now));

      const secondsLeft = Math.floor(duration.asSeconds()); // Round down to whole seconds
      updateTimeDisplay(secondsLeft);
      updateDaysLeft();

      if (secondsLeft <= 0) {
        endLaunchCountdown();
      }
    }

    function updateDaysLeft() {
      const today = moment();
      const daysLeft = futureDate.diff(today, "days");
      document.querySelector(".days-left-display").textContent = `${daysLeft} Days Left`;
    }

    function endLaunchCountdown() {
      document.querySelector(".mintBtn").style.display = "flex";
      timeDisplay.textContent = "Game On!";
      timeDisplay.style.background = `linear-gradient(90deg, rgb(123 97 0), rgb(249, 203, 40))`;
      document.querySelector(".date-display").style.display = "none";
      document.querySelector(".end-time-display").style.display = "none";
      document.querySelector(".static-circle").style.display = "none";
      document.querySelector(".half-circle-path").style.display = "none";
      document.querySelector(".days-left-display").style.display = "none";
      gsap.killTweensOf(halfCirclePath);
    }

    updateDisplays();
    const interval = setInterval(updateDisplays, 1000);

    const durationInSeconds = futureDate.diff(moment(), "seconds");
    gsap.to(halfCirclePath, {
      strokeDashoffset: 2 * pathLength,
      duration: durationInSeconds,
      ease: "linear",
    });
  } catch (error) {
    console.error("Error initializing counter:", error);
  }
}
